import React, { useEffect, useState, } from "react";
import NavbarWrapper from "../../../widgets/navbarWrapper";
import AlertClinicModal from "./alertClinicModal";
import useData from "../../redux/customHook/useData";
import Spinner from "../loading/spinner";
import { useNavigate } from "react-router-dom";
import EditClinic from "./editClinic";
import DeleteClinic from "./deleteClinic";
import { toggleState } from "../../specialMethod/toggleState";
import ClinicCard from "./clinicCard";

const Clinic = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    alertMessage: false,
    loadingSpinner: true,
    getClinicDetails: [],
    editClinic: false,
    selectedClinicDetails: null,
    clinicDelete: false,
    clinicDeleteId: "",
  });

  const { data } = useData();

  useEffect(() => {
    if (data) {
      setState((prev) => ({
        ...prev,
        loadingSpinner: false,
        getClinicDetails: data.clinicDtos.filter(
          (clinic) => clinic.clinicStatus === "Active"
        ),
      }));
    }
  }, [data]);

  const updatedClinics = (newClinic) =>
    setState((prev) => ({
      ...prev,
      getClinicDetails: [...prev.getClinicDetails, newClinic],
    }));

  const handleEditClinic = (clinic) => {
    setState((prev) => ({
      ...prev,
      editClinic: true,
      selectedClinicDetails: clinic,
    }));
  };

  const handleDeleteClinic = (clinicId) => {
    setState((prev) => ({
      ...prev,
      clinicDelete: true,
      clinicDeleteId: clinicId,
    }));
  };

  const handleCheckAppointments = (clinicId) => navigate(`/appointment/${clinicId}`);

  return (
    <>
      <NavbarWrapper showNavbar={true} />
      <div className="bg-white py-4">
        <div className="max-w-screen-xl md:mt-20 mt-0 px-4 mx-auto flex justify-between items-center">
          <p className="text-2xl font-bold">Location</p>
          <button
            className="bg-orange-500 text-white px-5 py-2 rounded-lg mt-100"
            onClick={() => toggleState("alertMessage", true, setState)}
          >
            Add Clinic
          </button>
        </div>
        <div className="container px-4 flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {state.getClinicDetails.length === 0 ? (
              <div className="text-center">No Clinic Found!</div>
            ) : (
              state.getClinicDetails.map((clinic, index) => (
                <ClinicCard
                  key={index}
                  clinic={clinic}
                  onEdit={handleEditClinic}
                  onDelete={handleDeleteClinic}
                  onCheckAppointments={handleCheckAppointments}
                />
              ))
            )}
          </div>
        </div>
      </div>

      {state.loadingSpinner && <Spinner />}
      {state.alertMessage && (
        <AlertClinicModal
          value={() => toggleState("alertMessage", false, setState)}
          updatedClinics={updatedClinics}
          data={data}
        />
      )}
      {state.editClinic && (
        <EditClinic
          clinic={state.selectedClinicDetails}
          reset={() => toggleState("editClinic", false, setState)}
          updatedClinic={updatedClinics}
        />
      )}
      {state.clinicDelete && (
        <DeleteClinic
          clinicId={state.clinicDeleteId}
          reset={() => toggleState("clinicDelete", false, setState)}
        />
      )}
    </>
  );
};

export default Clinic;
