import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import useData from '../../redux/customHook/useData';
import { handleAllClinicReport, handleGetReport } from './account_function/report_api';

const CustomReport = ({ reset }) => {
    const { data } = useData();
    const [selectClinic, setSelectClinic] = useState([]);
    const [customRange, setCustomRange] = useState(false);
    
    useEffect(() => {
        if (data) {
            setSelectClinic(data || []);
        }
    }, [data]);

    const formik = useFormik({
        initialValues: {
            clinicId: "",
            Present: "",
            startDate: "",
            endDate: ""
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object({
            clinicId: Yup.string().required("Clinic is required"),
            Present: Yup.string().required("Range is required"),
        }),
        onSubmit: (values) => handleReport(values),
    });

    useEffect(() => {
        setCustomRange(formik.values.Present === 'custom');
    }, [formik.values.Present]);

    const handleReport = useCallback((values) => {
        let allIds = [];
        if (values.clinicId === 'all') {
            allIds = (data?.clinicDtos || []).map(clinic => ({
                id: clinic.id,
                location: clinic.location,
            }));
            handleAllClinicReport(values, allIds, selectClinic);
        } else {
            handleGetReport(values, selectClinic);
        }
        reset();
    }, [data, selectClinic, reset]);

    const handleClinicChange = useCallback((e) => {
        const clinicId = e.target.value;
        const clinicLocation = e.target.selectedOptions[0]?.getAttribute("data-location");
        formik.setValues(prevValues => ({
            ...prevValues,
            clinicId,
            clinicLocation,
        }));
    }, [formik]);

    // Precompute the list of clinics to render
    const clinicOptions = useMemo(() => {
        return (data?.clinicDtos || []).map(clinic => (
            <option key={clinic.id} value={clinic.id} data-location={clinic.location}>
                {clinic.location}
            </option>
        ));
    }, [data]);

    return (
        <div
            id="login-popup"
            tabIndex="-1"
            className="bg-black/50 fixed top-0 right-0 left-0 z-50 h-full flex items-center justify-center"
        >
            <div className="relative p-4 w-full max-w-xl">
                <div className="relative bg-white rounded-lg shadow">
                    <button
                        type="button"
                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5"
                        onClick={reset}
                    >
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="#c6c7c7"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                    <div className="py-6 px-6 lg:px-8">
                        <h3 className="mb-4 text-xl font-medium text-gray-900">
                            Create Custom Report
                        </h3>
                        <form onSubmit={formik.handleSubmit} className="space-y-6">
                            {/* Clinic Selection */}
                            <div>
                                <label
                                    htmlFor="clinics"
                                    className="block mb-2 text-sm font-medium text-gray-900"
                                >
                                    Select Clinic
                                </label>
                                <select
                                    name="clinicId"
                                    id="clinics"
                                    className="border text-sm rounded-lg block w-full p-2.5"
                                    onChange={handleClinicChange}
                                    value={formik.values.clinicId}
                                >
                                    <option value="">Select</option>
                                    <option value="all">All Clinics</option>
                                    {clinicOptions}
                                </select>
                                {formik.errors.clinicId && (
                                    <p className="text-red-700 mt-2 text-xs">
                                        {formik.errors.clinicId}
                                    </p>
                                )}
                            </div>
                            {/* Report Range Selection */}
                            <div>
                                <label
                                    htmlFor="Present"
                                    className="block mb-2 text-sm font-medium text-gray-900"
                                >
                                    Select Report Range
                                </label>
                                <select
                                    name="Present"
                                    id="Present"
                                    className="border text-sm rounded-lg block w-full p-2.5"
                                    onChange={formik.handleChange}
                                    value={formik.values.Present}
                                >
                                    <option value="">Select</option>
                                    <option value="month">This Month</option>
                                    <option value="year">This Year</option>
                                    <option value="custom">Custom Range</option>
                                </select>
                                {formik.errors.Present && (
                                    <p className="text-red-700 mt-2 text-xs">
                                        {formik.errors.Present}
                                    </p>
                                )}
                            </div>
                            {/* Date Range Inputs */}
                            {customRange && (
                                <div className="grid grid-cols-2 gap-6">
                                    {[
                                        { name: "startDate", label: "Start Date", value: formik.values.startDate },
                                        { name: "endDate", label: "End Date", value: formik.values.endDate }
                                    ].map(({ name, label, value }) => (
                                        <div key={name} className="col-span-2 sm:col-span-1">
                                            <label
                                                htmlFor={name}
                                                className="block mb-2 text-sm font-medium text-gray-900"
                                            >
                                                {label}
                                            </label>
                                            <input
                                                type="date"
                                                name={name}
                                                id={name}
                                                className="border text-sm rounded-lg block w-full p-2.5"
                                                onChange={formik.handleChange}
                                                value={value}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            {/* Submit Button */}
                            <button
                                type="submit"
                                className="w-full text-white bg-red-500 hover:bg-red-600 rounded-lg text-sm px-5 py-2.5"
                            >
                                Generate Report
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomReport;
