import React from "react";
import "../../App.css";
const Header = () => {
  return (
    <>
      <ul class="items-center w-3/4 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-200 dark:border-gray-600 dark:text-white lg:ml-48 mt-10">
        <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
          <div class="flex items-center ps-3">
            <div class="circle">1</div>
            <label
              for="vue-checkbox-list"
              class="w-full py-3 ms-2 text-md font-semibold text-gray-900 dark:text-gray-800"
            >
              Consent Collection
            </label>
          </div>
        </li>
        <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
          <div class="flex items-center ps-3">
            <div class="circle">2</div>
            <label
              for="vue-checkbox-list"
              class="w-full py-3 ms-2 text-md font-semibold text-gray-900 dark:text-gray-800"
            >
              Aadhaar Authentication
            </label>
          </div>
        </li>
        <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
          <div class="flex items-center ps-3">
            <div class="circle">3</div>
            <label
              for="vue-checkbox-list"
              class="w-full py-3 ms-2 text-md font-semibold text-gray-900 dark:text-gray-800"
            >
              Communication Details
            </label>
          </div>
        </li>
        <li class="w-full dark:border-gray-600">
          <div class="flex items-center ps-3">
            <div class="circle">4</div>
            <label
              for="vue-checkbox-list"
              class="w-full py-3 ms-2 text-md font-semibold text-gray-900 dark:text-gray-800"
            >
              ABHA Address Creation
            </label>
          </div>
        </li>
      </ul>
    </>
  );
};

export default Header;
