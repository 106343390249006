import React, { useState, useRef, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import Spinner from "../loading/spinner";
import ImageCropper from "./imageCropper";
import { formFields, getAllDays } from "./clinic_function/addClinic_function";
import { toggleState } from "../../specialMethod/toggleState";
import { useAddClinic, useOnCropCancel, useOnCropDone } from "./clinic_function/addClinic_function";

const AddClinic = ({ closeAlertClinicBanner, value, updatedClinics }) => {
  const [state, setState] = useState({
    loadingSpinner: false,
    image: "",
    currentPage: false,
  });

  const { onCropDone } = useOnCropDone(state.image, toggleState, setState);
  const { onCropCancel } = useOnCropCancel(toggleState, setState);
  const { addClinic } = useAddClinic(setState, toggleState, state.image, value, closeAlertClinicBanner, updatedClinics);

  const inputRef = useRef();

  // Memoized handler for image selection
  const handleImageChange = useCallback((event) => {
    if (event.target.files?.length) {
      const reader = new FileReader();
      reader.onload = () => toggleState("image", reader.result, setState);
      reader.readAsDataURL(event.target.files[0]);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      clinicName: "",
      location: "",
      startTime: "",
      endTime: "",
      incharge: "",
      clinicContact: "",
      clinicNewFees: "",
      clinicOldFees: "",
      days: [],
      dropdownOpen: false,
    },
    validationSchema: Yup.object({
      clinicName: Yup.string().required("Enter clinic name"),
      location: Yup.string().required("Enter location"),
      startTime: Yup.string().required("Enter start time"),
      endTime: Yup.string().required("Enter end time"),
      incharge: Yup.string().required("Enter incharge name"),
      clinicContact: Yup.string().matches(/^\d{10}$/, "Must be 10 digits").required("Enter mobile number"),
      clinicNewFees: Yup.number().required("Enter clinic fees"),
      clinicOldFees: Yup.number().required("Enter clinic fees"),
      days: Yup.array().min(1, "Select at least one day"),
    }),
    onSubmit: (values) => addClinic(values),
  });

  // Toggle dropdown
  const handleToggleDropDown = useCallback(() => {
    formik.setFieldValue("dropdownOpen", !formik.values.dropdownOpen);
  }, [formik]);

  // Handle day selection
  const handleDaySelection = useCallback((day) => {
    const updatedDays = formik.values.days.includes(day)
      ? formik.values.days.filter((d) => d !== day)
      : [...formik.values.days, day];
    formik.setFieldValue("days", updatedDays);
  }, [formik]);

  return (
    <>
      <div
        id="login-popup"
        className="bg-black/50 fixed inset-0 flex items-center justify-center z-50"
      >
        <div className="h-[80%] md:h-[92%] overflow-y-auto">
          <div className="w-full max-w-md">
            <div className="bg-white rounded-lg shadow p-5 relative">
              {/* Close Button */}
              <button
                type="button"
                className="absolute top-3 right-3 text-gray-400 hover:text-gray-900"
                onClick={value}
              >
                <span aria-hidden="true">&times;</span>
              </button>

              {/* Title */}
              <div className="text-center">
                <h2 className="text-2xl font-semibold">Clinic Details</h2>
              </div>

              {/* Form */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <div className="grid grid-cols-2 gap-6">
                  {/* Image Upload */}
                  <div className="col-span-2 sm:col-span-1">
                    <label className="block text-sm font-medium text-gray-700">
                      Prescription Photo
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputRef}
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <button
                      type="button"
                      className="text-blue-600"
                      onClick={() => inputRef.current.click()}
                    >
                      {state.image ? "Image Selected" : "Choose Image"}
                    </button>
                  </div>

                  {/* Dynamically Rendered Fields */}
                  {formFields.map(({ name, label, type, placeholder }) => (
                    <div key={name} className="col-span-2 sm:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">{label}</label>
                      <input
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:border-blue-500"
                      />
                      {formik.touched[name] && formik.errors[name] && (
                        <p className="text-red-500 text-sm">{formik.errors[name]}</p>
                      )}
                    </div>
                  ))}

                  {/* Days Selection */}
                  <div className="col-span-2 sm:col-span-1">
                    <label className="block text-sm font-medium text-gray-700">Select Days</label>
                    <button
                      type="button"
                      className="text-gray-700 bg-white border border-gray-400 rounded-lg px-6 py-2"
                      onClick={handleToggleDropDown}
                    >
                      {formik.values.days.length} Days
                    </button>
                    {formik.values.dropdownOpen && (
                      <ul className="mt-2 p-3 border rounded bg-white">
                        {getAllDays.map((day, idx) => (
                          <li key={idx}>
                            <label className="flex items-center space-x-2">
                              <input
                                type="checkbox"
                                checked={formik.values.days.includes(day)}
                                onChange={() => handleDaySelection(day)}
                              />
                              <span>{day}</span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="w-full bg-orange-500 hover:bg-orange-600 text-white rounded-lg py-2 mt-4"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {state.currentPage && (
        <ImageCropper
          image={state.image}
          onCropDone={onCropDone}
          onCropCancel={onCropCancel}
        />
      )}
      {state.loadingSpinner && <Spinner />}
      <ToastContainer />
    </>
  );
};

export default AddClinic;
