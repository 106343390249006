import { useDispatch } from "react-redux";
import { deleteSchedule } from "../../../redux/features/data/dataSlice";
import { toast } from "react-toastify";
import apiServices from "../../../../Service/apiService";
import { DELETE_SCHEDULE_API } from "../dashboard_api.js/api";

export const useHandleDoctorSchedule = (event, reset, toggleState, setState) => {
    const dispatch = useDispatch();
    const token = sessionStorage.getItem("token");
    const handleDoctorSchedule = async () => {
        toggleState("loadingSpinner", true, setState);
        try {
            const response = await apiServices.delete(
                "authentication",
                DELETE_SCHEDULE_API,
                {
                    Authorization: " Bearer " + token,
                },
                {
                    doctorInterfaceId: event.id,
                },
                {
                    purpose: event.purpose,
                    clinicName: event.clinicName,
                    startTime: event.startTime,
                    endTime: event.endTime,
                    stDate: event.stDate,
                    endDate: event.endDate,
                }
            );
            if (response.statusCode === 200) {
                dispatch(deleteSchedule(response.data));
                toggleState("loadingSpinner", false, setState);
                reset();
            }
        } catch (error) {
            if (error.response?.data?.statusCode === 404) {
                toast.error("Something Went Wrong", {
                    position: "top-center",
                });
            }
        } finally {
            toggleState("loadingSpinner", false, setState);
        }
    };

    return { handleDoctorSchedule };
};
