// clinicIncomeThunk.js
import {
  fetchClinicIncomesStart,
  fetchClinicIncomesSuccess,
  fetchClinicIncomesFailure,
  fetchAnotherDataFailure,
  fetchAnotherDataSuccess,
  fetchAnotherDataStart,
} from "./clinicIncomeSlice";
import { fetchClinicIncome } from "../../api/fetchClinicIncome";

// Modify the existing thunk action creator to accept clinic data as an argument
export const fetchClinicIncomesData =
  (startDate, endDate) => async (dispatch, getState) => {
    const { clinicFetched } = getState().clinicIncome;
    if (!clinicFetched) {
      //check if data is not already fetched
      dispatch(fetchClinicIncomesStart());
    }
    try {
      const data = await fetchClinicIncome(startDate, endDate);
  
      dispatch(fetchClinicIncomesSuccess(data));
    } catch (error) {
      dispatch(fetchClinicIncomesFailure(error.message));
    }
  };

export const fetchAnotherClinicIncomesData =
  (startDate, endDate) => async (dispatch, getState) => {
    const { anotherFetched } = getState().clinicIncome;
    if (!anotherFetched) {
      //check if data is not already fetched
      dispatch(fetchAnotherDataStart());
    }
    try {
      const data = await fetchClinicIncome(startDate, endDate);
      dispatch(fetchAnotherDataSuccess(data));
    } catch (error) {
      dispatch(fetchAnotherDataFailure(error.message));
    }
  };
