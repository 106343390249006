import apiServices from "../../../Service/apiService";

export const FETCH_PRESCRIPTION_PHOTO_REQUEST = "FETCH_PRESCRIPTION_PHOTO_REQUEST";
export const FETCH_PRESCRIPTION_PHOTO_SUCCESS = "FETCH_PRESCRIPTION_PHOTO_SUCCESS";
export const FETCH_PRESCRIPTION_PHOTO_FAILURE = "FETCH_PRESCRIPTION_PHOTO_FAILURE";

export const FetchPrescriptionPhoto = (clinicId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PRESCRIPTION_PHOTO_REQUEST });
    try {
      const response = await apiServices.get(
        "authentication",
        "/clinicImage/getImage",
        null,
        { clinicId }
      );
      if (response && response.data) {
        dispatch({
          type: FETCH_PRESCRIPTION_PHOTO_SUCCESS,
          payload: { clinicId, imageURL: response.data },
        });
      } else {
        console.error("Action - Invalid or empty response received from the API for clinicId:", clinicId);
        dispatch({
          type: FETCH_PRESCRIPTION_PHOTO_FAILURE,
          payload: "Invalid response",
        });
      }
    } catch (error) {
      console.error("Action - Error fetching prescription photo for clinicId:", clinicId, error);
      dispatch({ type: FETCH_PRESCRIPTION_PHOTO_FAILURE, payload: error.message });
    }
  };
};
