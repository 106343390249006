import React, { useState } from "react";
import Header from "../header";
// import "../Index.css"
const AadharAuth = () => {
  // State to store Aadhar number digits
  const [aadharDigits, setAadharDigits] = useState(["", "", ""]);

  return (
    <>
      <Header />
      <div className="mx-auto max-w-6xl border-2 border-gray-200 p-4 mt-2">
        <h2 className="text-sm font-semibold text-gray-900 dark:text-blue-800 mx-4 mb-3 mt-1 ">
          Please ensure that mobile number is linked with Aadhaar as it will be
          required for OTP authentication.
          <br />
          If you do not have a mobile number linked, visit the nearest{" "}
          <span className="text-red-500">ABDM participating facility</span> and
          seek assistance.
        </h2>

        <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-800 mx-4 mb-2 items-center">
          Aadhaar number *
        </h2>

        <div className="flex item-center justify-center mb-4">
          {aadharDigits.map((digit, index) => (
            <div key={index} className=" flex flex-col items-center">
              <input
                type="text"
                id={`aadhar-digit-${index}`}
                value={digit}
                maxLength={4}
                className="w-20 h-10 border border-gray-600 rounded-md px-5 text-black ml-4"
                placeholder="****"
              />
            </div>
          ))}
        </div>

        <div
          className="mx-auto max-w-6xl border-2 border-gray-200 p-2 mt-1"
          style={{ maxHeight: "400px" }}
        >
          <h2 className="text-md  font-medium text-gray-900 dark:text-gray-900 ">
            Terms and Conditions
          </h2>
          <p className="tex-sm font-normal">
            I, hereby declare that I am voluntarily sharing my Aadhaar number
            and demographic information issued by UIDAI, with National Health
            Authority (NHA) for the sole purpose of creation of ABHA number. I
            understand that my ABHA number can be used and shared for purposes
            as may be notified by ABDM from time to time including provision of
            healthcare services. Further, I am aware that my personal
            identifiable information (Name, Address, Age, Date of Birth, Gender
            and Photograph) may be made available to the entities working in the
            National Digital Health Ecosystem (NDHE) which inter alia includes
            stakeholders and entities such as healthcare professionals (e.g.
            doctors), facilities (e.g. hospitals, laboratories) and data
            fiduciaries (e.g. health programmes), which are registered with or
            linked to the Ayushman Bharat Digital Mission (ABDM), and various
            processes there under. I authorize NHA to use my Aadhaar number for
            performing Aadhaar based authentication with UIDAI as per the
            provisions of the Aadhaar (Targeted Delivery of Financial and other
            Subsidies, Benefits and Services) Act, 2016 for the aforesaid
            purpose. I understand that UIDAI will share my e-KYC details, or
            response of “Yes” with NHA upon successful authentication. I have
            been duly informed about the option of using other IDs apart from
            Aadhaar; however, I consciously choose to use Aadhaar number for the
            purpose of availing benefits across the NDHE. I am aware that my
            personal identifiable information excluding Aadhaar number / VID
            number can be used and shared for purposes as mentioned above. I
            reserve the right to revoke the given consent at any point of time
            as per provisions of Aadhaar Act and Regulations.
          </p>
        </div>
        <div className="mx-auto max-w-6xl border-2 border-gray-200 p-4 mt-2 flex ">
          <input type="checkbox" id="agree-checkbox" className="mr-2 w-4" />
          <label
            htmlFor="agree-checkbox"
            className="text-gray-900 dark:text-gray-800 font-bold"
          >
            I agree
          </label>
        </div>
        <div class="flex justify-end  mt-3">
          <button class="px-6 py-2 bg-gray-200  text-black text-sm font-medium rounded-md">
            Cancel
          </button>

          <button
            class="px-6 py-2 ml-2 text-white text-sm font-medium rounded-md"
            style={{ backgroundColor: "rgb(214, 96, 37)" }}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default AadharAuth;
