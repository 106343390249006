import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClinicPatientCounts,
  fetchMonthPatientCounts,
} from "../features/patientVisit/patientThunk";
import {} from "../features/patientVisit/visitSalector";
import {
  selectpatientVisit,
  selectpatientVisitError,
  selectpatientVisitloading,
  selectmonthVisit,
  selectmonthLoading,
  selectmonthError,
} from "../features/patientVisit/patientSlice";
import { formatDateToDDMMYY } from "../../specialMethod/getBetweenDate";

const usePatientCount = () => {
  const dispatch = useDispatch();
  const patientVisit = useSelector(selectpatientVisit);
  const loading = useSelector(selectpatientVisitloading);
  const error = useSelector(selectpatientVisitError);
  const monthVisit = useSelector(selectmonthVisit);
  const monthPatientloading = useSelector(selectmonthLoading);
  const monthPatienterror = useSelector(selectmonthError);
  const currentDate = new Date();
  const date = formatDateToDDMMYY(currentDate);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const pastDate = new Date(year, month - 1, 1);
  const formattedPastDate = formatDateToDDMMYY(pastDate);
  useEffect(() => {
    // Fetch patient count data only if it hasn't been fetched yet

    dispatch(fetchClinicPatientCounts(date, date));

    dispatch(fetchMonthPatientCounts(formattedPastDate, date));
  }, [dispatch, patientVisit, monthVisit]); // Include 'date' as a dependency
  
  return {
    patientVisit,
    loading,
    error,
    monthVisit,
    monthPatientloading,
    monthPatienterror,
  };
};

export default usePatientCount;
