import { toast } from "react-toastify";
import apiServices from "../../../../Service/apiService";
import { addClinicSucess } from "../../../redux/features/data/dataSlice";
import { useDispatch } from "react-redux";
import { ADD_CLINIC_API, IMAGE_UPLOAD_API } from "../clinic_api/api";

const token = sessionStorage.getItem("token");

export const useAddClinic = (setState, toggleState, image, value, closeAlertClinicBanner, updatedClinics) => {
    const dispatch = useDispatch();
    const addClinic = async (values) => {
        try {
            toggleState("loadingSpinner", true, setState);
            const response = await apiServices.post(
                "authentication",
                ADD_CLINIC_API,
                {
                    Authorization: " Bearer " + token,
                },
                null,
                {
                    clinicName: values.clinicName,
                    location: values.location,
                    startTime: values.startTime,
                    endTime: values.endTime,
                    incharge: values.incharge,
                    clinicContact: values.clinicContact,
                    clinicNewFees: values.clinicNewFees,
                    days: values.days,
                }
            );
            if (response.statusCode === 201) {
                dispatch(addClinicSucess(response.data)); // Dispatch addClinicSuccess with the newly added clinic data
                updatedClinics(response.data);
                if (image) {
                    handleImageUpload(response.data.id, image, toggleState, setState, value, closeAlertClinicBanner);
                }
                toggleState("loadingSpinner", false, setState);
                value();
                closeAlertClinicBanner();
            }
        } catch (error) {
            console.error("error", error);
            if (
                error.response &&
                error.response.data &&
                error.response.data.statusCode === 500
            ) {
                toast.error("Something Went Wrong", {
                    position: "top-center",
                });
            }
        }
    };
    return { addClinic }
}

export const handleImageUpload = async (ClinicId, image, toggleState, setState, value, closeAlertClinicBanner) => {
    try {
        const base64String = image;
        // Extract image type from base64 string
        const typeMatch = base64String.match(/^data:(image\/\w+);base64,/);
        if (!typeMatch) {
            throw new Error("Invalid base64 string");
        }
        const imageType = typeMatch[1].split("/")[1];

        // Convert base64 to Blob
        const byteCharacters = atob(base64String.split(",")[1]);
        const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteArray], { type: `image/${imageType}` });

        // Create a file from the Blob
        const filename = `image.${imageType}`;
        const file = new File([blob], filename, { type: `image/${imageType}` });
        // Prepare image data for upload
        const imageData = new FormData();
        imageData.append("file", file);

        const response = await apiServices.put(
            "authentication",
            IMAGE_UPLOAD_API,
            {
                Authorization: " Bearer " + token,
            },
            {
                clinicId: ClinicId,
            },
            imageData
        );
        if (response) {
            value();
            closeAlertClinicBanner();
            toggleState("loadingSpinner", false, setState);
            return response;
        }
    } catch (error) {
        console.error("Error is coming in api", error);
    }
};

export const useOnCropDone = (image, toggleState, setState) => {
    const onCropDone = (imgCroppedArea) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;
        const context = canvasEle.getContext("2d");
        let imageObj1 = new Image();
        imageObj1.src = image;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
            );
            const dataURL = canvasEle.toDataURL("image/jpeg");
            toggleState("currentPage", false, setState);
            toggleState("image", dataURL, setState);
        };
    };

    return { onCropDone };
};

export const useOnCropCancel = (toggleState, setState) => {
    const onCropCancel = () => {
        toggleState("currentPage", false, setState);
        toggleState("image", "", setState);
    };

    return { onCropCancel }
}

export const getAllDays = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
];

export const formFields = [
    {
        name: "clinicName",
        type: "text",
        placeholder: "Clinic Name",
        label: "Clinic Name",
    },
    {
        name: "location",
        type: "text",
        placeholder: "Address",
        label: "Address",
    },
    {
        name: "startTime",
        type: "time",
        placeholder: "Start Time",
        label: "Start Time",
    },
    {
        name: "endTime",
        type: "time",
        placeholder: "End Time",
        label: "End Time",
    },
    {
        name: "incharge",
        type: "text",
        placeholder: "Incharge Name",
        label: "Incharge",
    },
    {
        name: "clinicContact",
        type: "number",
        placeholder: "Mobile Number",
        label: "Mobile Number",
    },
    {
        name: "clinicNewFees",
        type: "number",
        placeholder: "₹",
        label: "Clinic New Patient Fee",
    },
    {
        name: "clinicOldFees",
        type: "number",
        placeholder: "₹",
        label: "Clinic Old Patient Fee",
    },
];