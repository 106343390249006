import React, { useEffect, useState } from 'react'

const UseTagInput = (options, selectedItems, setSelectedItems) => {
    const [inputValue, setInputValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
  
    const handleInputChange = (e) => {
      const value = e.target.value;
      setInputValue(value);
  
      // Filter suggestions
      if (value) {
        const filteredSuggestions = options.filter((option) =>
          option.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(filteredSuggestions);
      } else {
        setSuggestions([]);
      }
    };
  
    const addItem = (item) => {
      if (!selectedItems.includes(item)) {
        setSelectedItems((prevItems) => {
          const updatedItems = [...prevItems, item]; // Use the previous state to ensure accumulation
          console.log("Updated items after add:", updatedItems); // Log the accumulated array
          return updatedItems;
        });
      }
      setInputValue(""); // Clear the input field
      setSuggestions([]); // Clear suggestions
    };
    
    
useEffect(() => {
  console.log("Selected items updated:", selectedItems);
}, [selectedItems]);

  
    const removeItem = (item) => {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    };
  
    const handleKeyDown = (e) => {
      if (e.key === "Enter" && inputValue.trim()) {
        addItem(inputValue.trim());
      }
    };
  
    return {
      inputValue,
      suggestions,
      selectedItems,
      handleInputChange,
      handleKeyDown,
      addItem,
      removeItem,
    };
  };
  


export default UseTagInput