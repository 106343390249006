import Axios from "axios";

export const FETCH_PROFILE_PHOTO_REQUEST = "FETCH_PROFILE_PHOTO_REQUEST";
export const FETCH_PROFILE_PHOTO_SUCCESS = "FETCH_PROFILE_PHOTO_SUCCESS";
export const FETCH_PROFILE_PHOTO_FAILURE = "FETCH_PROFILE_PHOTO_FAILURE";

export const fetchProfilePhoto = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PROFILE_PHOTO_REQUEST });

    try {
      // Retrieve token from session storage
      const token = sessionStorage.getItem("token");

      if (!token) {
        throw new Error("Token not found in session storage");
      }

      const baseUrl = process.env.REACT_APP_REGISTRATION_BASE_URL;
      const response = await Axios(
        `${baseUrl}/image/getImage`,
        {
          responseType: "blob",
          headers: { Authorization: "Bearer " + token },
        }
      );

      if (response && response.data) {
        const imageURL = URL.createObjectURL(response.data);
        dispatch({
          type: FETCH_PROFILE_PHOTO_SUCCESS,
          payload: imageURL,
        });
      } else {
        console.error("Invalid or empty response received from the API");
        dispatch({
          type: FETCH_PROFILE_PHOTO_FAILURE,
          payload: "Invalid response",
        });
      }
    } catch (error) {
      console.error("Error fetching profile photo:", error);
      dispatch({ type: FETCH_PROFILE_PHOTO_FAILURE, payload: error.message });
    }
  };
};
