import { createSlice } from "@reduxjs/toolkit";
const getAllAppointment = createSlice({
  name: "appointment",
  initialState: {
    appointment: null,
    loading: false,
    error: null,
  },
  reducers: {
    fetchAppointmentStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchAppointmentSuccess(state, action) {
      state.loading = false;
      state.appointment = action.payload;
    },
    fetchAppointmentFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addAppointmentSucess(state, action) {
      state.appointment.data.push(action.payload); // Assuming payload contains data of the newly added appointment
    },
    updateAppointmentSuccess(state, action) {
      // find the index of the clinic with the matching id
     
      const appointmentIndex = state.appointment.data.findIndex(
        (appointment) => appointment.id === action.payload.id
      );
      // // if appointment exists update its details

      if (appointmentIndex !== -1) {
        //  Create a new array with the updated appointment
        state.appointment.data = [
          ...state.appointment.data.slice(0, appointmentIndex),
          action.payload, //updated appointment
          ...state.appointment.data.slice(appointmentIndex + 1), //element after the updated clinic
        ];
      }
    },
    deleteAppointment(state, action) {
      // Find the index of the schedule with the matching id
      const appointmentIndex = state.appointment.data.findIndex(
        (appointment) => appointment.id === action.payload
      );

      // If schedule exists, delete its details
      if (appointmentIndex !== -1) {
        // Create a new array without the deleted schedule
        state.appointment.data = [
          ...state.appointment.data.slice(0, appointmentIndex),
          ...state.appointment.data.slice(appointmentIndex + 1),
        ];
      }
    },
    updatePaymentStatus(state, action) {
      const appointmentIndex = state.appointment.data.findIndex(
        (appointment) => appointment.id === action.payload.id
      );
      if (appointmentIndex !== -1) {
        state.appointment.data[appointmentIndex].paymentStatus = "PAID";
      }
    },
    updateUnPaidPaymentStatus(state, action) {
      const appointmentIndex = state.appointment.data.findIndex(
        (appointment) => appointment.id === action.payload.id
      );
      if (appointmentIndex !== -1) {
        state.appointment.data[appointmentIndex].paymentStatus = "UNPAID";
      }
    },
    upatePatientVisited(state, action) {
      const appointmentIndex = state.appointment.data.findIndex(
        (appointment) => appointment.id === action.payload.id
      );
      if (appointmentIndex !== -1) {
        state.appointment.data[appointmentIndex].appointmentvisitStatus =
          "VISITED";
      }
    },
    updatePatientNonVisited(state, action) {
      const appointmentIndex = state.appointment.data.findIndex(
        (appointment) => appointment.id === action.payload.id
      );
      if (appointmentIndex !== -1) {
        state.appointment.data[appointmentIndex].appointmentvisitStatus =
          "NOT_VISITED";
      }
    },
  },
});
export const {
  fetchAppointmentStart,
  fetchAppointmentSuccess,
  fetchAppointmentFailure,
  addAppointmentSucess,
  updateAppointmentSuccess,
  deleteAppointment,
  updatePaymentStatus,
  updateUnPaidPaymentStatus,
  upatePatientVisited,
  updatePatientNonVisited,
} = getAllAppointment.actions;

export default getAllAppointment.reducer;
