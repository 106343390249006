import React from 'react'

const StatsCard = ({ title, value }) => (
    <div className="relative max-w-sm border border-gray-300 rounded-xl overflow-hidden">
      <div className="absolute top-0 left-0 h-full bg-red-400" style={{ width: '15%' }}></div>
      <div className="relative z-10 py-8 px-4">
        <h2 className="pl-8 title-font font-medium sm:text-4xl text-3xl text-red-400">{value}</h2>
        <p className="pl-8 leading-relaxed sm:text-xl">{title}</p>
      </div>
    </div>
  );

export default StatsCard;