import apiServices from "../../../../Service/apiService";
import { WEEKLY_GRAPH_API, YEARLY_GRAPH_API } from "../account_api/api";

const token = sessionStorage.getItem("token");
let clinicId = 0;
let clinicLocation = "";
let allResults = [];
const currentDate = new Date();
const year = currentDate.getFullYear();

export const usehandleDayClinicModal = (clinic,toggleState,setState) => {
    const handleDayClinicModal = async () => {       
        for (let i = 0; i < clinic.length; i++) {
          clinicId = clinic[i].id;
          clinicLocation = clinic[i].location;
          try {
            const response = await apiServices.get(
              "authentication",
              WEEKLY_GRAPH_API,
              {
                Authorization: "Bearer " + token,
              },
              {
                clinicId: clinicId,
              },
              null
            );
    
            if (response.statusCode === 200) {
              allResults.push({ clinicId, clinicLocation, ...response.data });
            }
          } catch (error) {
            if (error.response.data.statusCode === 404) {
              console.log(error);
            }
          }
        }
        toggleState("result", allResults, setState);
      };
    
    return { handleDayClinicModal };
  };

  
export const usehandleClinicModal = (clinic,toggleState,setState) => {
    const handleClinicModal = async () => {
        for (let i = 0; i < clinic.length; i++) {
          clinicId = clinic[i].id;
          clinicLocation = clinic[i].location;
          try {
            const response = await apiServices.get(
              "authentication",
              YEARLY_GRAPH_API,
              {
                Authorization: "Bearer " + token,
              },
              {
                year: year,
                clinicId: clinicId,
              },
              null
            );
    
            if (response.statusCode === 200) {
              allResults.push({ clinicId, clinicLocation, ...response.data });
            }
          } catch (error) {
            if (error.response.data.statusCode === 404) {
              console.log(error);
            }
          }
        }
        toggleState("result", allResults, setState);
      };
    
    return { handleClinicModal };
  };