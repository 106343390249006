import React, { useEffect, useState } from "react";
import "../../../App.css";
import apiServices from "../../../Service/apiService";
import useData from "../../redux/customHook/useData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const BookShowAppointment = ({ drName ,patientData, reset, clinics }) => {
  const handlePrint = () => {
    window.print();
  };
  const {data} = useData
  const [clinicName, setClinicName] = useState(null)
  const [fees, setFees] = useState(null)
  
  useEffect(() => {
    if (clinics) {
      const kankarbagClinics = clinics.filter(
        clinic => clinic.location.toLowerCase() === patientData.clinicLocation.toLowerCase()
      );
      if (kankarbagClinics.length > 0) {
        setFees(kankarbagClinics[0].clinicNewFees);
        setClinicName(kankarbagClinics[0].clinicName);
      }
    }
  }, [clinics]);

console.log(patientData)

const handleWhatsappNotification = async ()=>{
  const clinica =  clinics.filter(clinic => clinic.location == patientData.clinicLocation);
    try {
      const response = await apiServices.post(
        "authentication",
        "/whatsapp/appointment_confirmation",
        null,
        null,
        {
          patientName: patientData.name,
          patientContact: patientData.contact,
          drName: drName,
          date: patientData.appointmentDate,
          time: patientData.appointmentTime,
          clinicAddress: patientData.clinicLocation,
          compounderName: clinica[0].incharge,
          clinicContact: clinica[0].clinicContact,
        }
      );
      if (response) {
        toast.info("Message Send Successfully", {
          position: "top-center",
        });
        console.log("success whatsap",response)
        return response.data;
      }
    } catch (error) {
      console.log(error);
    } 
}
  
  return (
    <>
    
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex print-container"
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
              onClick={reset}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  cliprule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>
            <div className="p-5">
              {/* <h3 className="text-2xl mb-0.5 font-medium"></h3> */}
              <p className="mb-4 text-sm font-normal text-gray-800"></p>

              <div className="text-center">
                <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                  Booking Confirmed
                </p>
              </div>

              {/* form */}
              <form
                onSubmit={() => {
                  handlePrint();
                }}
              >
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-number"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Name
                    </label>
                    <input readOnly
                      type="text"
                      name="card-number"
                      value={patientData.name}
                      id="card-number"
                      placeholder="Full Name"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="expiration-date"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      ABHA Number
                    </label>
                    <input readOnly
                      type="text"
                      name="expiration-date"
                      value={patientData.abhaNumber}
                      id="expiration-date"
                      placeholder="ABHA NUMBER"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="cvv"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Age
                    </label>
                    <input readOnly
                      type="text"
                      value={patientData.age}
                      name="cvv"
                      id="cvv"
                      placeholder="DOB"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-holder"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Phone Number
                    </label>
                    <input readOnly
                      type="text"
                      value={patientData.contact}
                      name="card-holder"
                      id="card-holder"
                      placeholder="Mobile Number"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
               
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-number"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Gender
                    </label>
                    <input readOnly
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                      id="service"
                      name="service"
                      value={patientData.gender}
                    />
                  </div>
                  
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="expiration-date"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Clinic New Patient Fees
                    </label>
                    <input readOnly
                      type="text"
                      name="expiration-date"
                      value={fees}
                      id="expiration-date"
                      placeholder="00"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="cvv"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Clinic Name
                    </label>
                    <input readOnly
                      type="text"
                      value={clinicName}
                      name="cvv"
                      id="cvv"
                      placeholder="000"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-holder"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Clinic Location
                    </label>
                    <input readOnly
                      type="text"
                      value={patientData.clinicLocation}
                      name="card-holder"
                      id="card-holder"
                      placeholder="Full Name"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                  </div>
                 
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="cvv"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Schedule TIme
                    </label>
                    <input readOnly
                      type="time"
                      value={patientData.appointmentTime}
                      name="cvv"
                      id="cvv"
                      placeholder="000"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="expiration-date"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Schedule Date
                    </label>
                    <input readOnly
                      type="text"
                      name="expiration-date"
                      value={patientData.appointmentDate}
                      id="expiration-date"
                      placeholder="MM / YY"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                  </div>
                </div>
                <div className="mt-8 mx-auto">
                  <div className="flex flex-wrap -m-2">
                    <div className="p-2 w-1/2">
                      <div className="relative">
                        <button
                          type="submit"
                        
                          className="w-full bg-blue-400  rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 text-[14px] outline-none text-white py-1 px-16 leading-8 transition-colors duration-200 ease-in-out"
                        >
                          Print
                        </button>
                      </div>
                    </div>
                    <div className="p-2 w-1/2">
                    <div className="relative">
  <button
    type="button"
    onClick={handleWhatsappNotification}
    className="w-full bg-gray-100 border-green-300 hover:border-green-800 hover:cursor-pointer flex items-center justify-center gap-2 rounded border  focus:border-indigo-500 focus:ring-2 text-[14px] outline-none text-green-500 py-1 px-2 leading-8 transition-colors duration-200 ease-in-out"
  >
    <img
      src="https://cdn-icons-png.flaticon.com/512/4423/4423697.png"
      alt="WhatsApp"
      className="w-5 h-5"
    />
    Send Message
  </button>
</div>

                    </div>
                  </div>
                </div>
                {/* <div className="mt-8">

                  <button
                    type="submit"
                    className="w-full bg-green-500 hover:bg-blue-600 text-white font-medium py-3 rounded-lg focus:outline-none"
                  >
                    Print
                  </button>
                </div> */}
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};
export default BookShowAppointment;
