import React, { useState, useCallback, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useProfilePhoto from "../component/redux/customHook/useProfileImage";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { profilePhotoURL } = useProfilePhoto();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleToggleDropdown = useCallback(() => {
    setShowDropdown(!showDropdown);
    setShowCloseIcon(false);
    if (window.innerWidth <= 768) {
      document.body.style.backgroundColor = "rgb(236, 231, 241)";
    }
  }, [showDropdown]);

  const handleLogout = useCallback(() => {
    sessionStorage.removeItem("token");
    window.location.reload();
    navigate("/");
  }, [navigate]);

  const handleProfilePage = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const handleLinkClick = useCallback(
    (path) => {
      setActiveLink(path);
      navigate(path);
    },
    [navigate]
  );

  const navLinks = useMemo(
    () => [
      { path: "/dashboard", label: "Dashboard", color: "bg-black", textColor: "text-gray-900" },
      { path: "/clinic", label: "Clinic", color: "bg-orange-500", textColor: "text-orange-500" },
      { path: "/appointment", label: "Appointment", color: "bg-teal-700", textColor: "text-teal-500" },
      { path: "/account", label: "Account", color: "bg-red-500", textColor: "text-red-500" },
      { path: "/help", label: "Help", color: "bg-gray-500", textColor: "text-gray-900" },
    ],
    []
  );

  return (
    <div className="relative md:fixed z-50 w-full bg-gray-200 h-[69px]">
      <div className="md:flex flex-wrap items-center justify-between max-w-screen-xl px-4 pb-6 mx-auto">
      <a
  href="http://abdm.doc-aid.in/"
  target="_blank"
  rel="noopener noreferrer"
  className="flex items-center"
  onClick={() => handleLinkClick("/dashboard")}
>
  <img
    src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/doc-aid.png"
    className="h-6 w-18 mr-3 md:mt-2 lg:mb-3 mt-5 sm:h-11"
    alt="Landwind Logo"
  />
</a>

        <div className="flex items-center lg:order-2">
          <div className="text-xl absolute mr-4 right-16 cursor-pointer">
            <div className="relative">
              <button
                onClick={handleToggleDropdown}
                type="button"
                className="inline-flex items-center justify-center w-full h-full rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              >
                <img
                  className="md:h-16 md:mt-5 lg:mt-0 xl:mb-2 md:w-16 h-8 rounded-[1000px]"
                  src={profilePhotoURL}
                  alt="Profile"
                />
              </button>
              {showDropdown && (
                <div className="absolute top-full right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu" aria-orientation="vertical">
                    <button
                      onClick={handleProfilePage}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                      role="menuitem"
                    >
                      Profile
                    </button>
                    <button
                      onClick={handleLogout}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                      role="menuitem"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => setOpen(!open)}
            className="text-xl absolute right-8 top-3 cursor-pointer lg:hidden"
          >
            <button
              onClick={() => {
                setShowNavbar(!showNavbar);
                setShowCloseIcon(!showCloseIcon);
              }}
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
              <span className="sr-only">Open main menu</span>
              {!showCloseIcon ? (
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              )}
            </button>
          </div>
        </div>
        <div className="items-center justify-between w-full lg:flex lg:w-auto lg:order-1">
          <ul
            className={`lg:flex lg:items-center lg:pb-0 pb-6 absolute lg:static bg-white lg:z-auto z-[-1] left-0 w-full lg:w-auto lg:pl-0 pl-0 transition-all duration-500 ease-in ${
              open ? "top-20" : "top-[-490px]"
            }`}
          >
            {navLinks.map((link) => (
              <li key={link.path}>
                <Link
                  to={link.path}
                  className={`flex items-center font-medium text-sm px-5 py-4 mb-2 hover:text-gray-800 focus:outline-none ${
                    activeLink === link.path ? `bg-gray-200 ${link.textColor}` : `${link.color} text-white`
                  }`}
                  onClick={() => handleLinkClick(link.path)}
                >
                  {activeLink === link.path ? (
                    link.path === '/account' ? (
                      <img
                        src={`https://d2sv8898xch8nu.cloudfront.net/MediaFiles/${link.label.toLowerCase()}Acitve.svg`}
                        className="h-6 mr-3 sm:h-9 fill-current text-slate-800"
                        alt={`${link.label} Active`}
                      />
                    ) : (
                      <img
                        src={`https://d2sv8898xch8nu.cloudfront.net/MediaFiles/${link.label.toLowerCase()}Active.svg`}
                        className="h-6 mr-3 sm:h-9 fill-current text-slate-800"
                        alt={`${link.label} Active`}
                      />
                    )
                  ) : (
                    <img
                      src={`https://d2sv8898xch8nu.cloudfront.net/MediaFiles/${link.label.toLowerCase()}White.svg`}
                      className="h-6 mr-3 sm:h-9 fill-current text-slate-800"
                      alt={`${link.label}`}
                    />
                  )}
                  <span className="self-center text-12 font-semibold whitespace-nowrap  hover:text-gray-800 montserrat">
                    {link.label}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
