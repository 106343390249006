import React,{useState} from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { useFormik } from "formik";
import * as Yup from "yup";
import apiServices from "../../Service/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Spinner from "../page/loading/spinner";


const ForgetPassword = () => {
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [showPassword, setSetShowPassword] = useState(false);


  const navigate = useNavigate();
  const validateFormik = useFormik({
    initialValues: {
      email: "",
      newPassword: "",
      confirmPassword: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("please enter email"),
      newPassword: Yup.string()
        .min(7, "Password must be atleast 7 characters long")
        .max(20, "Password cannot more than 20 charcter long ")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        )
        .required("please enter the password")
        .test("Password cannot contain your name ", function (value) {
          const nameFromEmail = this.parent.email.split("@")[0].toLowerCase();
          return !value.toLowerCase().includes(nameFromEmail);
        }),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match") // Reference 'password' field
        .required("Confirm password is required"),
    }),
    onSubmit: (values) => {
      handleForgetPassword(values);
    },
  });
  const handleForgetPassword = async (values) => {
    try {
      setLoadingSpinner(true);
      const response = await apiServices.put(
        "authentication",
        "/home/forgotPassword",
        {
          "Content-Type": "application/json",
        },
        {
          email: values.email,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        },
        null
      );
      if (response.statusCode === 200) {
        navigate("/login")
      } else if (response.statusCode === 411) {
        setTimeout(() => {
          toast.error("Something Went Wrong", {
            position: "top-center",
          });
          navigate("/forgetPassword");
        }, 2000);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
      });
    }finally {
      setLoadingSpinner(false);
    }
  };
  const handleShowPassword = () => {
    setSetShowPassword((prevShowPassword) => !prevShowPassword); // Change 'setSetShowPassword' to 'setShowPassword'
  };
  return (
    <>
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center justify-between">
        <div className="max-w-screen-xl m-0 sm:m-10 md:shadow sm:rounded-lg flex justify-center flex-1 ">
          <div className="lg:flex-1 md:bg-white text-center flex flex-col justify-center items-center">
            {/* Doc-Aid Logo */}
            <div className="w-full flex justify-center items-center">
              <div className="lg:w-3/4 mb-24 md:mb-0">
                <img alt="img" className="w-[350px] xl:w-full lg:w-auto mb-24 md:mb-0" src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/doc-aid.png" />
              </div>
            </div>
            {/* for mobile screen User Interface */}
            <div
              className="absolute bottom-0  lg:hidden w-full" 
              style={{
                backgroundColor: "rgb(237, 231, 242)",
                borderTopLeftRadius: "40px",
                borderTopRightRadius: "40px",
              }}
            >
              <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-16 flex justify-center items-center">
                {/* input box */}
                <div className="w-full mt-4rem">
                  <div className="my-1 border-b text-center">
                    <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2"></div>
                  </div>
                  <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validateFormik.handleSubmit();
                  }}
                >
                  <div className="mx-auto max-w-xs">
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                      type="text"
                      name="email"
                      value={validateFormik.values.email}
                      onChange={validateFormik.handleChange}
                      placeholder="Email"
                    />
                    {validateFormik.touched.email &&
                    validateFormik.errors.email ? (
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.email}
                      </p>
                    ) : null}
                     <div className="relative">
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                      type={showPassword ? "text" : "password"}
                      name="newPassword"
                      value={validateFormik.values.newPassword}
                      onChange={validateFormik.handleChange}
                      placeholder="Password"
                    />
                    <div className="absolute inset-y-0 right-0 top-6 flex items-center pr-3 ">
                          <button type="button" onClick={handleShowPassword}>
                            {showPassword ? (
                              <BiShow className="h-6 w-8" />
                            ) : (
                              <BiHide className="h-6 w-8" />
                            )}
                          </button>
                        </div></div>
                    {validateFormik.touched.newPassword &&
                    validateFormik.errors.newPassword ? (
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.newPassword}
                      </p>
                    ) : null}
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                      type="text"
                      name="confirmPassword"
                      value={validateFormik.values.confirmPassword}
                      onChange={validateFormik.handleChange}
                      placeholder="Confirm Password"
                    />
                    {validateFormik.touched.confirmPassword &&
                    validateFormik.errors.confirmPassword ? (
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.confirmPassword}
                      </p>
                    ) : null}
                    <div className="flex justify-between">
                      <label className="block text-gray-500 font-bold my-4" />
                    </div>
                    <button
                      className="mt-5 tracking-wide font-semibol text-white-500 w-full py-4 rounded-lg  transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                      style={{ backgroundColor: "rgb(7, 160, 165)" }}
                    >
                      <svg
                        className="w-6 h-6 -ml-2"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                        <circle cx="8.5" cy="7" r="4" />
                        <path d="M20 8v6M23 11h-6" />
                      </svg>
                      &nbsp;
                      <span className="ml-">Forget Password</span>
                    </button>
                  </div>
                </form>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block lg:w-1/2 xl:w-5/12 p-6 sm:p-12"  style={{ backgroundColor: "rgb(237, 231, 242)" }}>
            {/* input box */}
            <div className="mt-8flex flex-col items-center">
              <div className="w-full flex-1">
                <div className="my-12 border-b text-center">
                  <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-gray-800 transform translate-y-1/2"></div>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validateFormik.handleSubmit();
                  }}
                >
                  <div className="mx-auto max-w-xs">
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                      type="text"
                      name="email"
                      value={validateFormik.values.email}
                      onChange={validateFormik.handleChange}
                      placeholder="Email"
                    />
                    {validateFormik.touched.email &&
                    validateFormik.errors.email ? (
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.email}
                      </p>
                    ) : null}
                    <div className="relative">
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                      type={showPassword ? "text" : "password"}
                      name="newPassword"
                      value={validateFormik.values.newPassword}
                      onChange={validateFormik.handleChange}
                      placeholder="Password"
                    />
                    <div className="absolute inset-y-0 right-0 top-6 flex items-center pr-3 ">
                          <button type="button" onClick={handleShowPassword}>
                            {showPassword ? (
                              <BiShow className="h-6 w-8" />
                            ) : (
                              <BiHide className="h-6 w-8" />
                            )}
                          </button>
                        </div></div>
                    {validateFormik.touched.newPassword &&
                    validateFormik.errors.newPassword ? (
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.newPassword}
                      </p>
                    ) : null}
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                      type="text"
                      name="confirmPassword"
                      value={validateFormik.values.confirmPassword}
                      onChange={validateFormik.handleChange}
                      placeholder="Confirm Password"
                    />
                    {validateFormik.touched.confirmPassword &&
                    validateFormik.errors.confirmPassword ? (
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.confirmPassword}
                      </p>
                    ) : null}
                    <div className="flex justify-between">
                      <label className="block text-gray-500 font-bold my-4" />
                    </div>
                    <button
                      className="mt-5 tracking-wide font-semibol text-white-500 w-full py-4 rounded-lg  transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                      style={{ backgroundColor: "rgb(7, 160, 165)" }}
                    >
                      <svg
                        className="w-6 h-6 -ml-2"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                        <circle cx="8.5" cy="7" r="4" />
                        <path d="M20 8v6M23 11h-6" />
                      </svg>
                      &nbsp;
                      <span className="ml-">Forget Password</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center">
        <div
          className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1"
          style={{ backgroundColor: "rgb(237, 231, 242)" }}
        >
          <div className="flex-1 bg-white text-center hidden lg:flex">
            <div
              className="m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat"
              style={{ marginTop: "240px" }}
            >
              <img alt="img" className="image" src={DocAid} />
            </div>
          </div>
          <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
    
            <div className="mt-12 flex flex-col items-center">
              <div className="w-full flex-1 mt-8">
                <div className="my-12 border-b text-center"></div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validateFormik.handleSubmit();
                  }}
                >
                  <div className="mx-auto max-w-xs">
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                      type="text"
                      name="email"
                      value={validateFormik.values.email}
                      onChange={validateFormik.handleChange}
                      placeholder="Email"
                    />
                    {validateFormik.touched.email &&
                    validateFormik.errors.email ? (
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.email}
                      </p>
                    ) : null}
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                      type="password"
                      name="newPassword"
                      value={validateFormik.values.newPassword}
                      onChange={validateFormik.handleChange}
                      placeholder="Password"
                    />
                    {validateFormik.touched.newPassword &&
                    validateFormik.errors.newPassword ? (
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.newPassword}
                      </p>
                    ) : null}
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                      type="text"
                      name="confirmPassword"
                      value={validateFormik.values.confirmPassword}
                      onChange={validateFormik.handleChange}
                      placeholder="Confirm Password"
                    />
                    {validateFormik.touched.confirmPassword &&
                    validateFormik.errors.confirmPassword ? (
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.confirmPassword}
                      </p>
                    ) : null}
                    <div className="flex justify-between">
                      <label className="block text-gray-500 font-bold my-4" />
                    </div>
                    <button
                      className="mt-5 tracking-wide font-semibol text-white-500 w-full py-4 rounded-lg  transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                      style={{ backgroundColor: "rgb(7, 160, 165)" }}
                    >
                      <svg
                        className="w-6 h-6 -ml-2"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                        <circle cx="8.5" cy="7" r="4" />
                        <path d="M20 8v6M23 11h-6" />
                      </svg>
                      &nbsp;
                      <span className="ml-">Forget Password</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <ToastContainer />
      {loadingSpinner && <Spinner />}
    </>
  );
};
export default ForgetPassword;
