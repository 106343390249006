import { useDispatch } from "react-redux";
import { convertTo24Hour, formatDateToDDMMYY } from "../../../specialMethod/getBetweenDate";
import { addSchedule } from "../../../redux/features/data/dataSlice";
import apiServices from "../../../../Service/apiService";
import { ADD_SCHEDULE_API } from "../dashboard_api.js/api";

const postScheduleData = async (token, scheduleData) => {
  return await apiServices.post(
    "authentication",
    ADD_SCHEDULE_API,
    { Authorization: "Bearer " + token },
    null,
    scheduleData
  );
};

export const useHandleDoctorSchedule = (updatedSchedule, toggleState, setState, reset, toast) => {
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");

  const handleDoctorSchedule = async (values) => {
    toggleState("loadingSpinner", true, setState);
    const scheduleData = {
      purpose: values.purpose,
      clinicName: values.clinicName,
      startTime: convertTo24Hour(values.startTime),
      endTime: convertTo24Hour(values.endTime),
      stDate: values.stDate,
      endDate: values.endDate,
    };

    try {
      const response = await postScheduleData(token, scheduleData);
      if (response.statusCode === 200) {
        const updatedScheduleData = response.data;
        dispatch(addSchedule(updatedScheduleData));
        updatedSchedule(updatedScheduleData);
        reset();
      }
    } catch (error) {
      console.error("Error scheduling doctor:", error);
      if (error.response?.data?.statusCode === 404) {
        toast.error("Something Went Wrong", { position: "top-center" });
      }
    } finally {
      toggleState("loadingSpinner", false, setState);
    }
  };

  return { handleDoctorSchedule };
};

export const useHandleshortDaySchedule = (updatedSchedule, toggleState, setState, reset, toast) => {
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");

  const handleshortDaySchedule = async (values) => {
    toggleState("loadingSpinner", true, setState);
    const stdDate = new Date(values.stDate);
    const enDate = new Date(values.endDate);
    stdDate.setHours(0, 0, 0, 0);
    enDate.setHours(0, 0, 0, 0);

    const totalDays = (enDate - stdDate) / (1000 * 60 * 60 * 24);
    for (let i = 0; i <= totalDays; i++) {
      const currentStDate = formatDateToDDMMYY(stdDate);
      const scheduleData = {
        purpose: values.purpose,
        clinicName: values.clinicName,
        startTime: convertTo24Hour(values.startTime) || "12:00:00",
        endTime: convertTo24Hour(values.endTime) || "12:00:00",
        stDate: currentStDate,
        endDate: currentStDate,
      };

      try {
        const response = await postScheduleData(token, scheduleData);
        if (response.statusCode === 200) {
          const updatedScheduleData = response.data;
          dispatch(addSchedule(updatedScheduleData));
          updatedSchedule(updatedScheduleData);
        }
      } catch (error) {
        console.error("Error scheduling short day:", error);
        if (error.response?.data?.statusCode === 404) {
          toast.error("Something Went Wrong", { position: "top-center" });
        }
      }

      stdDate.setDate(stdDate.getDate() + 1);
    }

    reset();
    toggleState("loadingSpinner", false, setState);
  };

  return { handleshortDaySchedule };
};

  export const InputField = ({ label, type, name, value, placeholder, onChange, onBlur, error }) => (
    <div className="col-span-2 sm:col-span-1">
      <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor={name}>
        {label}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
  
  export const SelectField = ({ label, name, value, options, onChange, error }) => (
    <div className="col-span-2 sm:col-span-1">
      <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor={name}>
        {label}
      </label>
      <select
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className="w-full py-3 px-4 text-black border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );


  