import React, { useState, useEffect } from "react";
import "./calendarStyles.css"; // Ensure your custom styles are defined
import useData from "../../redux/customHook/useData";
import Spinner from "../loading/spinner";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomToolbar from "./customToolbar"; // Import your custom toolbar
import "../../../component/../App.css";
import MonthCalendar from "./monthCalendar";
import AddScheduleForDoctor from "./addScheduleForDoctor";
import EditSchedule from "./editSchedule";

const localizer = momentLocalizer(moment);

const CalendarSchedule = () => {
  const [state, setState] = useState({
    loadingSpinner: false,
    collectScheduleData: [],
    selectedEvent: null,
    modalSelect: false,
    addScheduleButton: false,
  });

  const [calendarWidth, setCalendarWidth] = useState("760px");

  // Adjust calendar width based on screen size
  const updateCalendarWidth = () => {
    const width = window.innerWidth >= 1025 ? "760px" : "580px";
    setCalendarWidth(width);
  };
console.log(window.innerWidth , "+",calendarWidth)
  useEffect(() => {
    updateCalendarWidth(); // Set initial width
    window.addEventListener("resize", updateCalendarWidth); // Listen to resize events
    return () => window.removeEventListener("resize", updateCalendarWidth); // Clean up
  }, []);

  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const { data } = useData();

  useEffect(() => {
    if (data) {
      toggleState("loadingSpinner", false);
      toggleState("collectScheduleData", data.docIntr || []);
    }
  }, [data]);

  const getRandomColor = (i) => {
    const colorWheelShades = [
      "rgb(237, 237, 243)", "rgb(216, 217, 228)",
      "rgb(221, 252, 254)", "rgb(217,250,250)",
      "rgb(202, 255, 255)", "rgb(255, 229, 205)",
      "rgb(237, 231, 242)",  "rgb(237, 237, 243)",
       "rgb(216, 217, 228)",
      "rgb(221, 252, 254)", "rgb(217,250,250)",
      "rgb(202, 255, 255)", "rgb(255, 229, 205)",
      "rgb(237, 231, 242)", 
    ];
    return colorWheelShades[i % colorWheelShades.length];
  };

  const updatedSchedule = (newSchedule) => {
    setState((prevState) => ({
      ...prevState,
      collectScheduleData: [...prevState.collectScheduleData, newSchedule],
    }));
  };

  const events = state.collectScheduleData.map((event) => ({
    start: new Date(`${event.stDate}T${event.startTime}`),
    end: new Date(`${event.endDate}T${event.endTime}`),
    id: event.id,
    color: getRandomColor(event.id),
    title: `${event.clinicName} - ${event.purpose}`,
    stDate: event.stDate,
      endDate: event.endDate,
      startTime: event.startTime,
      endTime: event.endTime,
      clinicName: event.clinicName,
      purpose: event.purpose,
  }));

  const handleEventSelect = (event) => {
    toggleState("selectedEvent", event);
    console.log(event)
    toggleState("modalSelect", true);
  };

  const closeModal = () => {
    toggleState("modalSelect", false);
    toggleState("selectedEvent", null);
  };

  // Custom Header Component
  const CustomHeader = ({ date }) => {
    const day = moment(date).format("ddd"); // Example: 'Sun'
    const dayNumber = moment(date).format("DD"); // Example: '01'

    return (
      <div style={{ textAlign: "center" }}>
        <span>{dayNumber}</span>
        <br />
        <span>{day}</span>
      </div>
    );
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className="App" style={{ padding: "1px", flexGrow: 1 }}>
          <div className="flex flex-row items-start justify-between">
            <div className="md:w-[600px] sm:w-[200px] w-[500px] md:mt-[75px] mr-5">
              <MonthCalendar />
              <div className="flex items-center my-4 md:mb-10">
                <button
                  onClick={() => toggleState("addScheduleButton", true)}
                  className="bg-teal-700 flex text-white px-4 py-2 rounded-md"
                >
                  <img
                    src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/editLogo.png"
                    alt="Edit"
                    className="mt-1 w-4"
                  />
                  <span className="pl-3">Edit</span>
                </button>
              </div>
            </div>
            <div className="w-full hidden md:block">
              <Calendar
                localizer={localizer}
                startAccessor="start"
                events={events}
                endAccessor="end"
                style={{ height: "530px", width: calendarWidth }}
                eventPropGetter={(event) => ({
                  style: {
                    backgroundColor: event.color,
                    color: "black",
                    fontFamily: "Cambria",
                  },
                })}
                onSelectEvent={handleEventSelect}
                views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
                components={{
                  toolbar: CustomToolbar,
                  month: { header: CustomHeader },
                  week: { header: CustomHeader },
                  day: { header: CustomHeader },
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {state.loadingSpinner && <Spinner />}
      {state.modalSelect && (
        <EditSchedule reset={closeModal} event={state.selectedEvent} />
      )}
      {state.addScheduleButton && (
        <AddScheduleForDoctor
          reset={() => toggleState("addScheduleButton", false)}
          updatedSchedule={updatedSchedule}
        />
      )}
    </>
  );
};

export default CalendarSchedule;
