import React from "react";
import AccessComponent from "./route/route";
// import Translate from "./translate";


const App = () => {
  return (
    <div>
      <AccessComponent />
 
    </div>
  );
};

export default App;
