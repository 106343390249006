import React, { useEffect, useState } from "react";
import Spinner from "../loading/spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import useData from "../../redux/customHook/useData";
import apiServices from "../../../Service/apiService";
import { updatePaymentStatus } from "../../redux/features/allAppointment/appointmentSlice";
import { addClinicAmount } from "../../redux/features/clinicIncome/clinicIncomeSlice";
import { useDispatch } from "react-redux";


const PaymentMode = ({ patientDetails, reset }) => {
  const dispatch = useDispatch();
  const { data } = useData();
  const getToken = sessionStorage.getItem("token");
  const [state, setState] = useState({
    loadingSpinner: false,
    clinicNewFees: null,
    clinicOldFees: null,
    clinicId: null
  });

  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const formik = useFormik({
    initialValues: {
      customFees: "",
      fees: "",
      modePayment: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      fees: Yup.string().required("fees  is required"),
      modePayment: Yup.string().required("Mode Of Payment is required"),
    }),
    onSubmit: (values) => {
      handlePayment(values);
    },
  });
  useEffect(() => {
    if (data && data.clinicDtos) {
      const clinicData = data.clinicDtos.find(
        (clinic) => clinic.location === patientDetails.clinicLocation
      );
      toggleState("clinicNewFees", clinicData.clinicNewFees);
      toggleState("clinicOldFees", clinicData.clinicOldFees);
      toggleState("clinicId", clinicData.id);
    }
  }, []);

  const handlePayment = async (values) => {
    try {
      toggleState("loadingSpinner", true);
      const response = await apiServices.post(
        "authentication",
        "/payment/createAppointmentPayment",
        null,
        null,
        {
          modeOfPayment: values.modePayment,
          amount: values.fees,
          appointmentDate: patientDetails.appointmentDate,
          appointmentId : patientDetails.id,
          clinicId: state.clinicId,
          doctorId: data.id
        }
      );
      if (response) {
        console.log(response)
        handleUnpaidToPaidPayment()
        dispatch(updatePaymentStatus({ id: patientDetails.id }));
        dispatch(
          addClinicAmount({
            type: values.modePayment,
            amount: values.fees,
          })
        );
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  const handleUnpaidToPaidPayment = async () => {
    try {
      const response = await apiServices.patch(
        "authentication",
        `/payment/appointmentPaid/${patientDetails.id}`,
        null,
        null,
        null
      );
      if (response) {
        console.log(response)
        reset();
        toggleState("loadingSpinner", false);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      toggleState("loadingSpinner", false);
    }
  };

  return (
    <>
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="relative p-4 w-full max-w-xl h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
              onClick={reset}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>
            <div className="p-5">
              <div className="text-center">
                <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                  Select Payment Option
                </p>
              </div>
              {/* form */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <div className=" ">
                  <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                    <div className="p-2 w-full">
                      <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                        <input
                          type="radio"
                          name="fees"
                          value={
                            state.clinicNewFees
                              ? state.clinicNewFees.toString()
                              : ""
                          }
                          onChange={formik.handleChange}
                          checked={
                            formik.values.fees ===
                            (state.clinicNewFees
                              ? state.clinicNewFees.toString()
                              : "")
                          }
                          className="mr-6 form-radio h-5 w-5 text-blue-600"
                        />
                        <span className="title-font font-medium">
                          New Patient Fees {state.clinicNewFees}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="p-2 w-full">
                      <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                        <input
                          type="radio"
                          name="fees"
                          value={
                            state.clinicOldFees
                              ? state.clinicOldFees.toString()
                              : ""
                          }
                          onChange={formik.handleChange}
                          checked={
                            formik.values.fees ===
                            (state.clinicOldFees
                              ? state.clinicOldFees.toString()
                              : "")
                          }
                          className="mr-6 form-radio h-5 w-5 text-blue-600"
                        />
                        <span className="title-font font-medium">
                          Old Patient Fees: {state.clinicOldFees}
                        </span>
                      </div>
                    </div>
                    <div className="p-2  w-full">
                      <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                        <input
                          type="radio"
                          name="fees"
                          value="custom"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "fees",
                              formik.values.customFees || ""
                            );
                          }}
                          checked={
                            formik.values.customFees !== "" &&
                            formik.values.fees === formik.values.customFees
                          }
                          className="mr-6 form-radio h-5 w-5 text-blue-600"
                        />
                        <span className="title-font font-medium">
                          Custom Fees:
                          <input
                            name="customFees"
                            onChange={formik.handleChange}
                            value={formik.values.customFees}
                            className="border-b-2 w-20 border-blue-400 focus:border-blue-500 focus:outline-none ml-2"
                            type="text"
                          />
                          <span className="text-red-800 text-[11px]">
                            {" "}
                            * please write amount first
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 mx-auto">
                    <div className="flex flex-wrap -m-2">
                      <div className="p-2 w-1/2">
                        <div className="relative">
                          <button
                            type="submit"
                            name="modePayment"
                            value="upi"
                            onClick={() =>
                              formik.setFieldValue("modePayment", "upi")
                            }
                            className={`form-radio w-full bg-orange-400  rounded-3xl border border-gray-300 focus:ring-2 text-base outline-none text-white py-1 px-16 leading-8 transition-colors duration-200 ease-in-out  ${
                              formik.values.modePayment === "upi"
                                ? "bg-orange-600"
                                : "bg-gray-200"
                            }`}
                          >
                            UPI
                          </button>
                        </div>
                      </div>
                      <div className="p-2 w-1/2">
                        <div className="relative">
                          <button
                            type="submit"
                            name="modePayment"
                            value="cash"
                            onClick={() =>
                              formik.setFieldValue("modePayment", "cash")
                            }
                            className={`w-full bg-orange-400  rounded-3xl border border-gray-300 focus:ring-2 text-base outline-none text-white py-1 px-16 leading-8 transition-colors duration-200 ease-in-out form-radio ${
                              formik.values.modePayment === "cash"
                                ? "bg-orange-600"
                                : "bg-gray-200"
                            }`}
                          >
                            CASH
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {state.loadingSpinner && <Spinner />}
    </>
  );
};

export default PaymentMode;
