import React,{useState}  from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import apiServices from "../../../Service/apiService";
import Spinner from "../loading/spinner"
import { specializationOption } from "../../specialMethod/prescriptionSpecialization";


const EditProfile = ({ onClose, Prof, updatedProfile }) => {
  const [loadinSpinner, setLoadingSpinner] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    formik.handleChange(e);
    if (inputValue) {
      const filtered = specializationOption.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredOptions(filtered);
      setShowOptions(true);
    } else {
      setFilteredOptions([]);
      setShowOptions(false);
    }
  };

  const handleOptionClick = (optionValue, index) => {
    const newSpecialization = [...formik.values.specialization]; // Create a copy of the array
    newSpecialization[index] = optionValue; // Update the value at the specified index
    formik.setFieldValue('specialization', newSpecialization); // Update the formik field value
    setShowOptions(false); // Hide the dropdown options  
  };

  const formik = useFormik({
    initialValues: {
      firstName:  Prof ? Prof.firstName : "",
      lastName: Prof ? Prof.lastName : "",
      email: Prof ? Prof.email : "",
      contact: Prof ? Prof.contact : "",
      degree: Prof ? Prof.degree.map((deg) => deg): "",
      achievements: Prof ? Prof.achievements.map((ach) => ach) : "",
      research_journal: Prof ? Prof.research_journal.map((journal) => journal) : "",
      citations: Prof ? Prof.citations.map((citation) => citation) : "",
      specialization: Prof ? Prof.specialization.map((spec) => spec) : "",
      experience: Prof ? Prof.experience : "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("enter your firstName"),
      lastName: Yup.string().required("enter your lastName"),
      contact: Yup.string()
        .required("enter phone number")
        .matches(/^\d{10}$/, "Number must be 10 digits"),
      email: Yup.string().email().required("enter your email"),
      degree: Yup.array().of(
        Yup.string()
      ),
      achievements: Yup.array().of(
        Yup.string()
      ),
      research_journal: Yup.array().of(
        Yup.string()
      ),
      citations: Yup.array().of(
        Yup.string()
      ),
      specialization: Yup.array().of(
        Yup.string()
      ),
      experience: Yup.number().required("year"),
    }),
    onSubmit: (values) => {
      handleEditFunction(values);
    },
  });
  const token = sessionStorage.getItem("token");
  const handleEditFunction = async (values) => {
    setLoadingSpinner(true);
    try {
      const response = await apiServices.put(
        "authentication",
        `/home/updateDoctor`,
        {
          Authorization: " Bearer " + token,
        },
        null,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          contact: values.contact,
          degree: values.degree,
          password: Prof.password,
          achievements: values.achievements,
          research_journal: values.research_journal,
          citations: values.citations,
          specialization: values.specialization,
          experience: values.experience,
          licenceNumber: Prof.licenceNumber,
        }
      );
      if (response.statusCode === 200) {
        updatedProfile(response.data);
        onClose();
        setLoadingSpinner(false);
      }
    } catch (error) {
      console.error("error", error.response);
    }
  };
  const removeSection = (fieldName, index, formik) => {
    const newFieldValue = formik.values[fieldName].filter(
      (_, idx) => idx !== index
    );
    formik.setFieldValue(fieldName, newFieldValue);
  };
  const addSection = (fieldName, formik) => {
    const fieldValue = formik.values[fieldName];
    const newFieldValue = [...fieldValue, ""];
    formik.setFieldValue(fieldName, newFieldValue);
  };
  const handleAddCitationResearch = () => {
    addSection("citations", formik);
    addSection("research_journal", formik);
  };

  return (
    <>
      <div
        id="container"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="bg-white p-2 max-w-[340px] md:max-w-[620px] h-[80%] md:h-[92%]  overflow-y-auto flex flex-grow-1  rounded-xl">
          <div className="relative px-0  max-w-[full] max-h-full">
            <div className="relative bg-white  ">
              <div className="flex  items-center justify-between xl:pt-5 p-4 md:p-5  rounded-t ">
                <h3 className="text-2xl pl-2 font-semibold text-gray-900 dark:text-gray-700">
                  Edit Profile
                </h3>
                <div onClick={onClose} className="w-8 text-3xl cursor-pointer">X</div>
              </div>
              <div className="p-4 xl:pt-1  ">
                <section className="text-gray-600 body-font relative">
                  <div className="container px-5 mx-auto">
                    <div className=" mx-auto">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          formik.handleSubmit();
                        }}
                      >
                        <div className="flex flex-wrap -m-2">
                          
                        <div className="p-2 w-full md:w-1/2">
                            <div className="relative">
                              <label
                                for="text"
                                className="leading-7 text-sm text-gray-600"
                              >
                                First Name
                              </label>
                              <input
                                type="text"
                                name="firstName"
                                disabled
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                id="card-number"
                                placeholder="First Name"
                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                              />
                              {formik.touched.firstName &&
                              formik.errors.firstName ? (
                                <p style={{ color: "red" }}>
                                  {formik.errors.firstName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="p-2 w-full md:w-1/2">
                            <div className="relative">
                              <label
                                for="text"
                                className="leading-7 text-sm text-gray-600"
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                name="lastName"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                id="card-number"
                                placeholder="Last Name"
                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                              />
                              {formik.touched.lastName &&
                              formik.errors.lastName ? (
                                <p style={{ color: "red" }}>
                                  {formik.errors.lastName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="p-2 w-full md:w-1/2">
                            <div className="relative">
                              <label
                                for="text"
                                className="leading-7 text-sm text-gray-600"
                              >
                                Email
                              </label>
                              <input
                              disabled
                                type="text"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                id="card-number"
                                placeholder="Email Address"
                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                              />
                              {formik.touched.email &&
                              formik.errors.email ? (
                                <p style={{ color: "red" }}>
                                  {formik.errors.email}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="p-2 w-full md:w-1/2">
                            <div className="relative">
                              <label
                                for="text"
                                className="leading-7 text-sm text-gray-600"
                              >
                                Contact
                              </label>
                              <input
                                type="text"
                                name="contact"
                                disabled
                                value={formik.values.contact}
                                onChange={formik.handleChange}
                                id="card-number"
                                placeholder="Contact no."
                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                              />
                              {formik.touched.contact &&
                              formik.errors.contact ? (
                                <p style={{ color: "red" }}>
                                  {formik.errors.contact}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        {formik.values.degree.map((degree, index) => (
                          <div className="p-2 w-full md:w-1/2">
                            <div className="relative">
                              <label
                                for="name"
                                className="leading-7 text-sm text-gray-600"
                              >
                                Degree
                              </label>
                              <input
                                id={`degree${index}`}
                                name={`degree[${index}]`}
                                value={degree}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="Degree"
                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                              />
                               {formik.touched.degree &&
                              formik.errors.degree &&
                              formik.errors.degree[index] && (
                                <p style={{ color: "red" }}>
                                  {formik.errors.degree[index]}
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                          <div className="p-2 w-full md:w-1/2">
                          {formik.values.achievements.map((achievements, index) => (
                            <div className="relative">
                              <label
                                for="text"
                                className="leading-7 text-sm text-gray-600"
                              >
                                Achievements
                              </label>
                              <input
                               id={`achievements${index}`}
                               name={`achievements[${index}]`}
                               value={achievements}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                                placeholder="Achievements"
                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                              />
                              {formik.touched.achievements &&
                              formik.errors.achievements &&
                              formik.errors.achievements[index] && (
                                <p style={{ color: "red" }}>
                                  {formik.errors.achievements[index]}
                                </p>
                              )}
                              <div className="flex items-center">
                            {/* Minus button */}
                            
                            {index > 0 &&           <button
                                className="text-black px-2 rounded-md focus:outline-none focus:shadow-outline"
                                onClick={() =>
                                  removeSection("achievements", index, formik)
                                }
                              >
                                X
                              </button>}
                         
                            {/* Plus button */}
                          </div>
                            </div>
                            
                          ))}
                           <div className="flex justify-end">
                        <div className="mb-4  md:mr-2 sm text-center">
                          <button
                            className="w-full px-1 py-1  font-bold text-gray-500 bg-blue-500 rounded-lg dark:bg-white dark:text-gray-500 focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={() => addSection("achievements", formik)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                          </div>
                          <div className="p-2 w-full md:w-1/2">
                          {formik.values.research_journal.map((research_journal, index) => (
                          <div className="p-2 w-full ">
                            <div className="relative">
                              <label
                                for="name"
                                className="leading-7 text-sm text-gray-600"
                              >
                                Research Journal
                              </label>
                              <input
                               id={`research_journal${index}`}
                               name={`research_journal[${index}]`}
                               value={research_journal}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                                placeholder="Research Journal"
                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                              />
                              {formik.touched.research_journal &&
                              formik.errors.research_journal &&
                              formik.errors.research_journal[index] && (
                                <p style={{ color: "red" }}>
                                  {formik.errors.research_journal[index]}
                                </p>
                              )}
                                <div className="flex items-center">
                            {/* Minus button */}
                            
                            {index > 0 &&      <button
                                className="text-black px-2 rounded-md focus:outline-none focus:shadow-outline"
                                onClick={() =>
                                  removeSection("research_journal", index, formik)
                                }
                              >
                                X
                              </button>}
                         
                            {/* Plus button */}
                          </div>
                            </div>
                          </div>
                          ))}
                         <div className="flex justify-end">
                        <div className="mb-4  md:mr-2 sm text-center">
                          <button
                            className="w-full px-1 py-1  font-bold text-gray-500 bg-blue-500 rounded-lg dark:bg-white dark:text-gray-500 focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleAddCitationResearch}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      </div>
                      <div className="p-2 w-full md:w-1/2">
                          {formik.values.citations.map((citations, index) => (
                          <div className="p-2 w-full ">
                            <div className="relative">
                              <label
                                for="text"
                                className="leading-7 text-sm text-gray-600"
                              >
                                Citations
                              </label>
                              <input
                                  id={`citations${index}`}
                                  name={`citations[${index}]`}
                                  value={citations}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                placeholder="Citations"
                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                              />
                             {formik.touched.citations &&
                              formik.errors.citations &&
                              formik.errors.citations[index] && (
                                <p style={{ color: "red" }}>
                                  {formik.errors.citations[index]}
                                </p>
                              )}
                              
                               <div className="flex items-center">
                            {/* Minus button */}
                            
                            {index > 0 && <button
                                className="text-black px-2 rounded-md focus:outline-none focus:shadow-outline"
                                onClick={() =>
                                  removeSection("citations", index, formik)
                                }
                              >
                                X
                              </button>}
                         
                            {/* Plus button */}
                          </div>
                            </div>
                          </div>
                          ))}
                          <div className="flex justify-end">
                          <div className="mb-4  md:mr-2 sm text-center">
                          <button
                            className="w-full px-1 py-1  font-bold text-gray-500 bg-blue-500 rounded-lg dark:bg-white dark:text-gray-500 focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleAddCitationResearch}
                          >
                            +
                          </button>
                        </div>
                        </div>
                        </div>
                        <div className="p-2 w-full md:w-1/2">
                          {formik.values.specialization.map((specialization, index) => (
                          <div className="p-2 w-full">
                            <div className="relative">
                              <label
                                for="name"
                                className="leading-7 text-sm text-gray-600"
                              >
                                Specialization
                              </label>
                              {/* <input
                                  id={`specialization${index}`}
                                  name={`specialization[${index}]`}
                                  value={specialization}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                placeholder="Specialization"
                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                              /> */}
                                <input
           id={`specialization${index}`}
           name={`specialization[${index}]`}
          type="text"
          onChange={handleInputChange}
          onBlur={formik.handleBlur}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={formik.values.specialization}
          placeholder="Specialization"
        />
                                {formik.touched.specialization &&
                              formik.errors.specialization &&
                              formik.errors.specialization[index] && (
                                <p style={{ color: "red" }}>
                                  {formik.errors.specialization[index]}
                                </p>
                              )}
                               <div className="flex items-center">
                            {/* Minus button */}
                            
                            {showOptions && (
                                 <ul   className="bg-gray-50 border cursor-pointer border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500">
          {filteredOptions.map(option => (
            <li className="bg-gray-50 border cursor-pointer border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" key={option.value} onClick={() => handleOptionClick(option.value,index)}>
              {option.label}
            </li>
          ))}
        </ul>)}
                         
                            {/* Plus button */}
                          </div>
                            </div>
                          </div>

                            ))}
                        </div>
                          <div className="p-2 w-full md:w-1/2">
                            <div className="relative">
                              <label
                                for="text"
                                className="leading-7 text-sm text-gray-600"
                              >
                                Years of Experience
                              </label>
                              <input
                                type="text"
                                name="experience"
                                value={formik.values.experience}
                                onChange={formik.handleChange}
                                id="card-number"
                                placeholder="Year of Experience"
                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                              />
                              {formik.touched.experience &&
                              formik.errors.experience ? (
                                <p style={{ color: "red" }}>
                                  {formik.errors.experience}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="p-2 w-full md:w-1/2">
                            <button className="flex mt-2  text-white bg-red-500 border-0 py-2 px-8 focus:outline-none hover:bg-red-600 rounded text-lg">
                              Update Profile
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loadinSpinner && <Spinner />}
    </>
  );
};

export default EditProfile;
