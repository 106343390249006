import React, { useState, useCallback } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../loading/spinner";
import ModalSelect from "./modalSelect";
import { toggleState } from "../../specialMethod/toggleState";
import { useHandleEditDoctorSchedule } from "./dashboard_function/editSchedule_function";

const EditSchedule = ({ reset, event }) => {
  const [state, setState] = useState({
    deleted: false,
    loadingSpinner: false,
  });

  const { handleDoctorSchedule } = useHandleEditDoctorSchedule(event, reset, toggleState, setState);

  const formik = useFormik({
    initialValues: {
      purpose: event?.purpose || "",
      clinicName: event?.clinicName || "",
      startTime: event?.startTime || "",
      endTime: event?.endTime || "",
      stDate: event?.stDate || "",
      endDate: event?.endDate || "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      purpose: Yup.string().required("Purpose is required"),
      clinicName: Yup.string().required("Clinic name is required"),
      startTime: Yup.string().required("Start time is required"),
      endTime: Yup.string().required("End time is required"),
      stDate: Yup.string().required("Start date is required"),
      endDate: Yup.string().required("End date is required"),
    }),
    onSubmit: (values) => handleDoctorSchedule(values),
  });

  const handleDelete = useCallback(() => {
    toggleState("deleted", true, setState);
  }, []);

  const handleResetDelete = useCallback(() => {
    toggleState("deleted", false, setState);
    reset();
  }, [reset]);

  return (
    <>
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
              onClick={reset}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>
            <div className="p-5">
              <p className="mb-4 text-sm font-normal text-gray-800"></p>
              <div className="text-center">
                <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                  Edit Schedule
                </p>
              </div>

              {/* Form */}
              <form
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div className="grid grid-cols-2 gap-6">
                  {[
                    { label: "Purpose", name: "purpose", type: "text" },
                    { label: "Location", name: "clinicName", type: "text" },
                    { label: "Start Time", name: "startTime", type: "time" },
                    { label: "End Time", name: "endTime", type: "time" },
                    { label: "From", name: "stDate", type: "date" },
                    { label: "To", name: "endDate", type: "date" },
                  ].map(({ label, name, type }) => (
                    <div className="col-span-2 sm:col-span-1" key={name}>
                      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-2">
                        {label}
                      </label>
                      <input
                        type={type}
                        id={name}
                        name={name}
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                      />
                      {formik.touched[name] && formik.errors[name] && (
                        <p className="text-red-500 text-xs">{formik.errors[name]}</p>
                      )}
                    </div>
                  ))}
                </div>
                <div className="mt-8 mx-auto">
                  <div className="flex flex-wrap -m-2">
                    <div className="p-2 w-1/2">
                      <button
                        type="button"
                        onClick={handleDelete}
                        className="w-full bg-red-400 rounded border border-gray-300 text-white py-1 px-16"
                      >
                        Delete
                      </button>
                    </div>
                    <div className="p-2 w-1/2">
                      <button
                        type="submit"
                        className="w-full bg-green-500 rounded border border-gray-300 text-white py-1 px-16"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {state.deleted && <ModalSelect reset={handleResetDelete} event={event} />}
      <ToastContainer />
      {state.loadingSpinner && <Spinner />}
    </>
  );
};

export default EditSchedule;
