import React, { useState, useEffect } from "react";
import NavbarWrapper from "../../../widgets/navbarWrapper";
import Spinner from "../loading/spinner";
import useData from "../../redux/customHook/useData";
import useProfilePhoto from "../../redux/customHook/useProfileImage";
import EditProfile from "./editProfile";
import UploadPhoto from "./uploadPhoto";

const Profile = () => {
  const { profilePhotoURL } = useProfilePhoto();
  const [state, setState] = useState({
    uploadPhoto: false,
    loading: true,
    edit: false,
    imageURL:""  // State to hold the image URL
  });

  const toggleState = (key, value) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };
 
  const handleEdit=(prof) => {
    toggleState("selectedProfileDetail",prof);
    toggleState("edit", !state.edit);
  }
  const handleOnClose = () => {
    toggleState("edit", false);
  };
  const updatedProfile = (newProfile) => {
    console.log(newProfile)
    toggleState("profileDetail", [newProfile]);
  };
  const { data } = useData();
  useEffect(() => {
    if (data) {
      console.log(data)
      toggleState("loading", false);
      toggleState("profileDetail", [data] || []);
    }
  }, [data]);
  useEffect(() => {
    if (profilePhotoURL) {
      toggleState("imageURL", profilePhotoURL || []);
    }
  }, [profilePhotoURL]);
  return (
    <>
      <NavbarWrapper showNavbar={true} />

      {state.profileDetail?.map((value, index) => {
        return (
          <div key={index} className="flex h-screen   bg-white">
            {/* <!-- Sidebar --> */}
            <div
              className="hidden md:flex flex-col  w-1/5 md:w-2/5 lg:w-1/5  h-full "
              style={{ backgroundColor: "rgb(237,231,242)" }}
            >
              <div className="flex items-center md:mt-20 mt-0 justify-center ">
                <div className="text-center ">
                  {state.imageURL && (
                    <img
                      id="myImage"
                      className="h-36 w-36 rounded-full border-4 border-white dark:border-gray-800 mx-auto my-4"
                      src={state.imageURL}
                      alt="Profile"
                      title="Profile Picture"
                    />
                  )}
                </div>
              </div>

              <div className="flex flex-col flex-1 overflow-y-auto">
                <nav className="flex-1 px-2  ">
                  <p
                    href="/"
                    className="flex items-center px-4  text-lg  text-gray-800 "
                  >
                    Email
                  </p>
                  <p
                    href="/"
                    className="flex items-center font-semibold px-4 py-2 text-gray-800 "
                  >
                    {value.email}
                  </p>

                  <p className="flex items-center px-4 py-1 text-gray-800 ">
                    Phone Number
                  </p>
                  <p className="flex items-center font-semibold px-4 text-gray-800 ">
                    {value.contact}
                  </p>
                  <p
                    href="/"
                    className="flex items-center px-4 py-2 mt-2 text-gray-800 "
                  >
                    Registration Number
                  </p>
                  <p href="/" className="flex font-semibold items-center px-4 text-gray-800 ">
                    {value.licenceNumber}
                  </p>
                </nav>
              </div>
            </div>
            {/* main content */}
            <div className="flex flex-col md:mt-20 mt-0 flex-1 ">
              {/* profile name */}
              <div className="ml-10   py-2.5">
                <div className="montserrat font-semibold text-gray-900 ">
                  Welcome
                </div>
                <div className="text-2xl font-bold text-gray-900 montserrat">
                  {value.firstName}&nbsp;
                  {value.lastName}
                </div>
                {/* edit and upload button */}
                <div className="flex flex-row pt-3 ">
                  <button
                    type="button"
                    onClick={() => {
                      toggleState("uploadPhoto", true);
                    }}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    Upload Photo
                  </button>
                  <button
                    type="button"
                    onClick={()=>{handleEdit(value)}}
                    className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    Edit
                  </button>
                </div>
              </div>
              <section className="text-gray-600 body-font">
                <div className="container px-5  mx-auto">
                  <div className="flex flex-wrap -mx-4 text-left">
                    <div className="lg:w-2/5 mt-0 md:mt-3  ">
                      <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-800  mb-3 ml-10 md:ml-10">
                        Degree
                      </h2>
                      <p className="leading-relaxed text-base">
                        {value.degree.map((degree, index) => {
                          return (
                            <div
                              key={index}
                              className="max-w-2xl mx-10 mt-2 mb-2 "
                              style={{ width: "20rem" }}
                            >
                              <div className="flex  bg-white border border-gray-500 rounded-xl overflow-hidden items-center justify-start">
                                <div className="flex flex-col gap-2 py-2 pl-2">
                                  <p className="text-md text-black font-bold">{degree}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </p>{" "}
                    </div>
                    <div className="lg:w-2/5 pl-0 md:pr-8">
                      <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-800  mb-3 ml-10 md:ml-10">
                        Achievements
                      </h2>
                      <p className="leading-relaxed text-base">
                        {" "}
                        {value.achievements.map((achivmnt, index) => {
                          return (
                            <div className="flex flex-col">
                              <div
                                key={index}
                                className="max-w-2xl mx-10 mt-2 mb-2 "
                                style={{ width: "20rem" }}
                              >
                                <div className="flex  bg-white border border-gray-500 rounded-xl overflow-hidden items-center justify-start">
                                  <div className="flex flex-col gap-2 py-2 pl-2">
                                    <p className="text-md text-black font-bold">{achivmnt}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </p>{" "}
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-4  text-left">
                    <div className="lg:w-2/5    ">
                      <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-800 mb-3 ml-10 md:ml-10">
                      Research Journal
                      </h2>
                      <p className="leading-relaxed text-base">
                      {value.research_journal.map((rschjournal, index) => {
                    return (
                      <div
                        key={index}
                        className="max-w-2xl mx-10 mt-2 mb-2 "
                        style={{ width: "20rem" }}
                      >
                        <div className="flex  bg-white border border-gray-500 rounded-xl overflow-hidden items-center justify-start">
                          <div className="flex flex-col gap-2 py-2 pl-2">
                            <p className="text-md text-black font-bold">{rschjournal}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                      </p>{" "}
                    </div>
                    <div className="lg:w-2/5 pl-0 md:pr-8">
                      <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-800  mb-3 ml-10 md:ml-10">
                        Citation
                      </h2>
                      <p className="leading-relaxed text-base">
                        {" "}
                        {value.citations.map((citations, index) => {
                    return (
                      <div
                        key={index}
                        className="max-w-2xl mx-10 mt-2 mb-2 "
                        style={{ width: "20rem" }}
                      >
                        <div className="flex  bg-white border border-gray-500 rounded-xl overflow-hidden items-center justify-start">
                          <div className="flex flex-col gap-2 py-2 pl-2">
                            <p className="text-md text-black font-bold">{citations} </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                      </p>{" "}
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-4  text-left">
                    <div className="lg:w-2/5  ">
                      <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-800  mb-3 ml-10 md:ml-10">
                        Specalization
                      </h2>
                      <p className="leading-relaxed text-base">
                      {value.specialization.map((spcl, index) => {
                    return (
                      <div
                        key={index}
                        className="max-w-2xl mx-10 mt-2 mb-2 "
                        style={{ width: "20rem" }}
                      >
                        <div className="flex  bg-white border border-gray-500 rounded-xl overflow-hidden items-center justify-start">
                          <div className="flex flex-col gap-2 py-2 pl-2">
                            <p className="text-md text-black font-bold">{spcl}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                      </p>{" "}
                    </div>
                    <div className="lg:w-2/5 pl-0 md:pr-8">
                      <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-800  mb-3 ml-10 md:ml-10">
                      Years Of Experience
                      </h2>
                      <p className="leading-relaxed text-base">
                        {" "}
                     
                         
                            <div className="flex flex-col">
                              <div
                                key={index}
                                className="max-w-2xl mx-10 mt-2 mb-2 "
                                style={{ width: "20rem" }}
                              >
                                <div className="flex  bg-white border border-gray-500 rounded-xl overflow-hidden items-center justify-start">
                                  <div className="flex flex-col gap-2 py-2 pl-2">
                                  <p className="text-md text-black font-bold">
                          {value.experience} years
                        </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          
                        
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </section>

             

        
       {state.edit && <EditProfile onClose={handleOnClose} Prof={state.selectedProfileDetail} updatedProfile={updatedProfile}/>}
               
            </div>
          </div>
        );
      })}
      {state.loading && <Spinner />}
      {state.uploadPhoto && (
        <UploadPhoto
          reset={() => {
            toggleState("uploadPhoto", false);
          }}
          imageUploaded={state.imageURL}
          data={data}
        />
      )}
    </>
  );
};

export default Profile;
