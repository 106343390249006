import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import useData from "../../redux/customHook/useData";
import * as Yup from "yup";
import Spinner from "../loading/spinner";
import apiServices from '../../../Service/apiService';
import { useDispatch } from 'react-redux';
import { updateAppointmentSuccess } from '../../redux/features/allAppointment/appointmentSlice';

const EditScheduleAppointment = ({detail,reset}) => {
  const token = sessionStorage.getItem("token");


  const [state, setState] = useState({
    loadingSpinner: true,
    selectClinic: [],
  });

  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const { data } = useData();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (data) {
      toggleState("loadingSpinner", false);
      const activeClinics = data.clinicDtos.filter(clinic => clinic.clinicStatus === "Active");
      toggleState("selectClinic", activeClinics || []);
    }
  }, [data]);
  const handleClinicChange = (e) => {
    const clinicId = e.target.value;
    const clinicLocation =
      e.target.selectedOptions[0].getAttribute("data-location");

    formik.setValues({
      ...formik.values,
      clinicId: clinicId,
      clinicLocation: clinicLocation,
    });
  };
  const formik = useFormik({
    initialValues: {
      name: detail ? detail.name: "",
      abhaNumber:detail ? detail.abhaNumber:   "",
      age:detail ? detail.age:  "",
      contact: detail ? detail.contact: "" ,
      gender: detail ? detail.gender: "",
      appointmentDate: detail ? detail.appointmentDate: "",
      appointmentTime: detail ? detail.appointmentTime:  "",
      clinicId: detail ? detail.clinicId:  "", // Add clinicId field to form values
      clinicLocation: detail ? detail.clinicLocation:  "",
      guardianName: detail ? detail.guardianName: "",
      address: detail ? detail.address: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("name is required"),
      abhaNumber: Yup.string(),
      age: Yup.string().required("age is required"),
      contact: Yup.string().required("contact is required"),
      gender: Yup.string().required("Gender is required"),
      appointmentDate: Yup.string().required("appointment date is required"),
      appointmentTime: Yup.string().required("appointment time is required"),
      clinicId: Yup.string().required("Clinic is required"), // Add validation for clinicId
      clinicLocation: Yup.string().required("Clinic is required"),
    }),
    onSubmit: (values) => {
      handleEditAppointment(values);
    },
  });
  const handleEditAppointment = async (values) => {
    toggleState("loadingSpinner", true);

    try {
      const response = await apiServices.patch(
        "authentication",
        "/appointment/update",
        {
          Authorization: " Bearer " + token,
        },
        {
           id: detail.id  
        },
        {
          name: values.name,
          abhaNumber: values.abhaNumber,
          age: values.age,
          contact: values.contact,
          gender: values.gender,
          appointmentDate: values.appointmentDate,
          appointmentTime: values.appointmentTime,
          clinicLocation: formik.values.clinicLocation,
          guardianName: values.guardianName,
          address: values.address
        }
      );
      if (response) {
        reset();
        toggleState("loadingSpinner", false);
        dispatch(updateAppointmentSuccess(response))   
      }
    } catch (error) {
      console.error("error is coming ", error);
    } finally {
      toggleState("loadingSpinner", false);
    }
  };


  
  return (
    <>
    <div
    id="login-popup"
    tabIndex="-1"
    className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
  >
    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
      <div className="relative bg-white rounded-lg shadow">
        <button
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
          onClick={reset}
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="#c6c7c7"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              cliprule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close popup</span>
        </button>
        <div className="p-5">
          <p className="mb-4 text-sm font-normal text-gray-800"></p>

          <div className="text-center">
            <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
              Edit Appoinment
            </p>
          </div>

          {/* form */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <div className="grid grid-cols-2 gap-6">
              <div className="col-span-2 sm:col-span-1">
                <label
                  for="card-number"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Name 
                </label>
                <input
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  id="card-number"
                  placeholder="Full Name"
                  className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-red-400">{formik.errors.name}</div>
                )}
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  for="expiration-date"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  ABHA Number
                </label>
                <input
                  type="text"
                  name="abhaNumber"
                  value={
                    formik.values.abhaNumber
                  }
                  id="expiration-date"
                  placeholder="ABHA NUMBER"
                  className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                />
                {formik.touched.abhaNumber && formik.errors.abhaNumber ? (
                  <p style={{ color: "red" }}>{formik.errors.abhaNumber}</p>
                ) : null}
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  for="cvv"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Age
                </label>
                <input
                  type="number"
                  name="age"
                  value={formik.values.age}
                  onChange={formik.handleChange}
                  id="cvv"
                  placeholder="Age"
                  className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                />
                {formik.touched.age && formik.errors.age ? (
                  <p style={{ color: "red" }}>{formik.errors.age}</p>
                ) : null}
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  for="card-holder"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  name="contact"
            
                  value={formik.values.contact}
                  onChange={formik.handleChange}
                  id="card-holder"
                  placeholder="Mobile Number"
                  className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                />
                {formik.touched.contact && formik.errors.contact ? (
                  <p style={{ color: "red" }}>{formik.errors.contact}</p>
                ) : null}
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  for="card-number"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Gender
                </label>
                <select
                  className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                  id="service"
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHER">Other</option>
                </select>
                {formik.touched.gender && formik.errors.gender ? (
                  <p style={{ color: "red" }}>{formik.errors.gender}</p>
                ) : null}
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  for="expiration-date"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Schedule Date
                </label>
                <input
                  type="date"
                  name="appointmentDate"
                  value={formik.values.appointmentDate}
                  onChange={formik.handleChange}
                  id="expiration-date"
                  placeholder="MM / YY"
                  className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                />
                {formik.touched.appointmentDate &&
                formik.errors.appointmentDate ? (
                  <p style={{ color: "red" }}>
                    {formik.errors.appointmentDate}
                  </p>
                ) : null}
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  for="card-holder"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Guardian Name
                </label>
                <input
                  type="text"
                  name="guardianName"
            
                  value={formik.values.guardianName}
                  onChange={formik.handleChange}
                  id="card-holder"
                  placeholder="Mobile Number"
                  className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                />
                {formik.touched.guardianName && formik.errors.guardianName ? (
                  <p style={{ color: "red" }}>{formik.errors.guardianName}</p>
                ) : null}
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  for="card-holder"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Address
                </label>
                <input
                  type="text"
                  name="address"
            
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  id="card-holder"
                  placeholder="Mobile Number"
                  className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                />
                {formik.touched.address && formik.errors.address ? (
                  <p style={{ color: "red" }}>{formik.errors.address}</p>
                ) : null}
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  for="clinic"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Select Clinic
                </label>
                <select
                  id="clinic"
                  name="clinicId"
                  value={formik.values.clinicId}
                  onChange={(e) => handleClinicChange(e)}
                  className="w-full py-2 px-4 text-black border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                >
                  <option value="">Select Clinic</option>
                  {state.selectClinic.map((clinic) => (
                    <option
                      key={clinic.id}
                      value={clinic.id}
                      data-location={clinic.location}
                    >
                      {clinic.location.substring(0, 12)}
                    </option>
                  ))}
                </select>
                {formik.touched.clinicId && formik.errors.clinicId ? (
                  <p style={{ color: "red" }}>{formik.errors.clinicId}</p>
                ) : null}
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  for="cvv"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Schedule Time
                </label>
                <input
                  type="time"
                  name="appointmentTime"
                  value={formik.values.appointmentTime}
                  onChange={formik.handleChange}
                  id="cvv"
                  placeholder="000"
                  className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                />
                {formik.touched.appointmentTime &&
                formik.errors.appointmentTime ? (
                  <p style={{ color: "red" }}>
                    {formik.errors.appointmentTime}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="mt-8">
              <button
                type="submit"
                className="w-full bg-green-500 hover:bg-blue-600 text-white font-medium py-2 rounded-lg focus:outline-none"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  {state.loadingSpinner && <Spinner />}
  </>
  )
}

export default EditScheduleAppointment