// hooks/useProfilePhoto.js
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfilePhoto } from '../api/fetchProfilePhoto';

const useProfilePhoto = () => {
  const dispatch = useDispatch();
  const profilePhotoURL = useSelector((state) => state.user.profilePhotoURL);
  const isLoading = useSelector((state) => state.user.isLoading);
  const error = useSelector((state) => state.user.error);

  useEffect(() => {
    dispatch(fetchProfilePhoto());
  }, [dispatch]);

  return { profilePhotoURL, isLoading, error };
};

export default useProfilePhoto;
