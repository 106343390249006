import React, { useEffect, useRef, useState } from "react";
import SubscriptionModal from "../account/subscriptionModal";

const DueModal = ({ reset, daysLeft }) => {
  const [payment, setPayment] = useState(false);
  const [blockDay, setBlockDay] = useState(0);
  const handleReset = () => {
    if (daysLeft > -7) {
      reset();
    }
  };
  console.log(daysLeft);
  const handleCheckoutFunction = () => {
    setPayment(true);
  };
  useEffect(() => {
    setBlockDay(7 + daysLeft);
  }, []);

  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
    >
      <div class="relative p-4 w-full max-w-md h-full md:h-auto">
        <div class="relative bg-white rounded-lg shadow">
          <button
            onClick={handleReset}
            type="button"
            class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="#c6c7c7"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                cliprule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Close popup</span>
          </button>
          <div className="p-5">
            {daysLeft >= 0 && (
              <p className="bg-red-600 rounded-xl text-white px-2 py-2 w-[120px] text-xs m-3 mt-4">
                {" "}
                Ending in {daysLeft} Days
              </p>
            )}

            {daysLeft >= 0 ? (
              <h2 className="text-red-600 text-2xl m-3">
                Your Payment for subscription is due
              </h2>
            ) : (
              blockDay > 0 && (
                <h2 className="text-red-600 text-2xl m-3">
                  Your Account will be blocked in {blockDay} days
                </h2>
              )
            )}
            {blockDay <= 0 && (
              <h2 className="text-red-600 text-2xl m-3">
                Your Account has been blocked
              </h2>
            )}
            <p className="m-3 mb-8 text-[14px]"> </p>
            <div className="flex justify-between">
              <button
                type="button"
                onClick={handleCheckoutFunction}
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 w-full dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
      {payment && (
        <SubscriptionModal
          value={() => {
            setPayment(false);
          }}
        />
      )}
    </div>
  );
};

export default DueModal;
