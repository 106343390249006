import React, {useState} from 'react'

const TagInput = ({
    label,
    placeholder,
    options,
    inputHook, // Pass the hook instance here
  }) => {
    const {
      inputValue,
      suggestions,
      selectedItems,
      handleInputChange,
      handleKeyDown,
      addItem,
      removeItem
    } = inputHook;
  
    return (
      <div className="border-2 border-gray-300 mx-5 px-5 py-3 rounded-xl mb-5">
        <p className="text-xl text-black pb-1">{label}</p>
        <div className="flex flex-wrap gap-2 mb-2">
          {/* Render Selected Items */}
          {selectedItems.map((item, index) => (
            <div
              key={index}
              className="flex items-center bg-blue-100 text-blue-800 text-sm px-2 py-1 rounded-full"
            >
              {item}
              <button
                className="text-blue-500 ml-2"
                onClick={() => removeItem(item)}
              >
                ×
              </button>
            </div>
          ))}
  
          {/* Input Field */}
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
  
        {/* Suggestions Dropdown */}
        {suggestions.length > 0 && (
          <ul className="border rounded-md mt-1 bg-white shadow-lg max-h-40 overflow-y-auto">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => addItem(suggestion)}
                className="px-3 py-2 cursor-pointer hover:bg-blue-100"
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };


export default TagInput