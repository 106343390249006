import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "../features/data/dataSlice";
import clinicIncomeSlice from "../features/clinicIncome/clinicIncomeSlice";
import userReducer from "../features/profileReducer/reducer";
import patientSlice from "../features/patientVisit/patientSlice";
import { thunk } from "redux-thunk"; // Importing 'thunk' directly from 'redux-thunk'
import appointmentSlice from "../features/allAppointment/appointmentSlice";
import PrescriptionReducer from "../features/prescriptionReducer/reducer";

const customizedMiddleware = (getDefaultMiddleware) => {
  return getDefaultMiddleware().concat(thunk);
};
const store = configureStore({
  reducer: {
    appointment: appointmentSlice,
    data: dataReducer,
    clinicIncome: clinicIncomeSlice, 
    user: userReducer,
    patient:patientSlice,
    prescription :PrescriptionReducer
  },
  middleware: customizedMiddleware,
});

export default store;
