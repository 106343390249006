import { createSlice } from "@reduxjs/toolkit";

const patientSlice = createSlice({
  name: "patient",
  initialState: {
    patientVisit: 0,
    monthVisit: 0,
    loading: false,
    monthLoading: false,
    error: null,
    monthError: null,
    VisitFetched: false,
    monthFetched: false,
  },
  reducers: {
    fetchVisitStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchVisitSuccess(state, action) {
      state.loading = false;
      state.patientVisit = action.payload;
      state.error = null;
      state.VisitFetched = true;
    },
    fetchVisitFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchMonthStart(state) {
      state.monthLoading = true;
      state.monthError = null;
    },
    fetchMonthSuccess(state, action) {
      state.monthLoading = false;
      state.monthVisit = action.payload;
      state.monthError = null;
      state.monthFetched = true;
    },
    fetchMonthFailure(state, action) {
      state.monthLoading = false;
      state.monthError = action.payload;
    },
    addPatientCount(state) {
      state.patientVisit += 1;
      state.monthVisit += 1;
    },
    removePatient(state) {
      state.patientVisit -= 1;
      state.monthVisit -= 1;
    },
  },
});

export const {
  fetchVisitStart,
  fetchVisitSuccess,
  fetchVisitFailure,
  fetchMonthStart,
  fetchMonthSuccess,
  fetchMonthFailure,
  addPatientCount,
  removePatient,
} = patientSlice.actions;

export const selectpatientVisit = (state) => state.patient.patientVisit;
export const selectpatientVisitloading = (state) => state.patient.loading;
export const selectpatientVisitError = (state) => state.patient.error;
export const selectmonthVisit = (state) => state.patient.monthVisit;
export const selectmonthLoading = (state) => state.patient.monthLoading;
export const selectmonthError = (state) => state.patient.monthError;

export default patientSlice.reducer;
