import jsPDF from "jspdf";
import "jspdf-autotable";
import apiServices from "../../../../Service/apiService";
import { formatDateToDDMMYY, getStartAndEndDateOfMonth } from "../../../specialMethod/getBetweenDate";
import { Customreport_API, YEARLY_REPORT_API } from "../account_api/api";
import qr from "../../../../assets/doc-aid.png";

const token = sessionStorage.getItem("token");
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = getStartAndEndDateOfMonth();
const startDateOfMonth = formatDateToDDMMYY(currentMonth.startDateOfMonth);
const endDateOfMonth = formatDateToDDMMYY(currentMonth.endDateOfMonth);

export const getMonthReport = async ({ clinicId, clinicLocation }) => {
    try {
        const response = await apiServices.get(
            "authentication",
            Customreport_API,
            { Authorization: "Bearer " + token },
            { startDate: startDateOfMonth, endDate: endDateOfMonth, clinicId }
        );

        if (response) {
            return formatReportData(response.data);
        }
    } catch (error) {
        console.error('Error fetching monthly report:', error);
    }
};

export const getYearReport = async ({ clinicId }) => {
    try {
        const response = await apiServices.get(
            "authentication",
            YEARLY_REPORT_API,
            null,
            { year: currentYear, clinicId }
        );

        if (response) {
            return formatReportData(response.data);
        }
    } catch (error) {
        console.error('Error fetching yearly report:', error);
    }
};

export const getCustomRangeReport = async ({ clinicId, startDate, endDate }) => {
    try {
        const response = await apiServices.get(
            "authentication",
            Customreport_API,
            { Authorization: "Bearer " + token },
            { startDate, endDate, clinicId }
        );

        if (response) {
            return formatReportData(response.data);
        }
    } catch (error) {
        console.error('Error fetching custom range report:', error);
    }
};


export const formatReportData = (reportData) => {
    const totalSummary = reportData['9999-12-31'] || {};
    const tableBody = Object.keys(reportData)
        .filter(date => date !== '9999-12-31')
        .map(date => {
            const dayReport = reportData[date] || {};
            return [
                date,
                dayReport.totalAppointments || 0,
                dayReport.totalAmount || 0,
                dayReport.cashAmount || 0,
                dayReport.upiAmount || 0,
            ];
        });
    const totalSummar = [
        'Total Summary',
        totalSummary.totalAppointments || 0,
        totalSummary.totalAmount || 0,
        totalSummary.cashAmount || 0,
        totalSummary.upiAmount || 0,
    ];
    return { tableBody, totalSummar };
};

export const handleAllClinicReport = async (values, allIds, selectClinic) => {
    const { Present } = values;

    let allTableBody = []; // To store all table data]

    for (let i = 0; i < allIds.length; i++) {
        const clinicId = allIds[i].id;
        const clinicLocation = allIds[i].location;

        const reportValues = {
            ...values,
            clinicId,
            clinicLocation,
        };

        let response;
        if (Present === 'month') {
            response = await getMonthReport(reportValues);
        } else if (Present === 'year') {
            response = await getYearReport(reportValues);
        } else {
            response = await getCustomRangeReport(reportValues);
        }

        // Add each clinic's report data to the combined lists
        if (response) {
            const { tableBody, totalSummar } = response;
            if (i > 0) {
                allTableBody.push([{ content: '', colSpan: 5, styles: { halign: 'center', fillColor: [255, 255, 255] } }]); // Add blank row for spacing
            }
            // Add the clinic location as a header row before each clinic's data
            allTableBody.push([{ content: `Clinic Location: ${clinicLocation}`, colSpan: 5, styles: { halign: 'left', fontStyle: 'bold' } }]);

            allTableBody.push(...tableBody);
            if (Present !== 'year') {
                allTableBody.push(totalSummar)
            }

        }
    }

    // Generate combined PDF after gathering data for all clinics
    const doc = new jsPDF();
    generatePDF(doc, qr,
        ['Date/Month', 'Total Appointments', 'Total Amount', 'Cash Amount', 'UPI Amount'],
        allTableBody,
        [],
        'All Clinics',
        selectClinic
    );
};

const generatePDF = (doc, imageSrc, header, body, summary, location, selectClinic) => {

    const img = new Image();
    img.src = imageSrc;

    img.onload = () => {
        doc.addImage(img, 'JPEG', 150, 6, 48, 16);
        doc.text(`${selectClinic.firstName} ${selectClinic.lastName}`, 14, 10);
        doc.text(`Clinic Location - ${location}`, 14, 19);
        // Main table
        doc.autoTable({ startY: 32, head: [header], body });

        // Summary table
        const finalY = doc.lastAutoTable?.finalY || 50;
        if (summary && summary.length > 0) {
            doc.autoTable({
                startY: finalY + 10,
                head: [['Summary', 'Total Appointments', 'Total Amount', 'Cash Amount', 'UPI Amount']],
                body: summary,
            });
        }

        doc.save('report.pdf');
    };
};

export const handleGetReport = async ({ clinicId, Present, clinicLocation, startDate, endDate }, selectClinic) => {
    try {
        let response;
        if (Present === 'month') {
            response = await apiServices.get(
                "authentication",
                Customreport_API,
                { Authorization: "Bearer " + token },
                { startDate: startDateOfMonth, endDate: endDateOfMonth, clinicId }
            );
        } else if (Present === 'year') {
            response = await apiServices.get(
                "authentication",
                YEARLY_REPORT_API,
                null,
                { year: currentYear, clinicId }
            );
        } else {
            response = await apiServices.get(
                "authentication",
                Customreport_API,
                { Authorization: "Bearer " + token },
                { startDate, endDate, clinicId }
            );
        }

        if (response) {
            const formattedData = formatReportData(response.data);
            const doc = new jsPDF();
            generatePDF(
                doc,
                qr,
                ['Date/Month', 'Total Appointments', 'Total Amount', 'Cash Amount', 'UPI Amount'],
                formattedData.tableBody,
                [formattedData.totalSummar],
                clinicLocation,
                selectClinic
            );
        }
    } catch (error) {
        console.error('Error fetching monthly report:', error);
    }
};
