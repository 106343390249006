import React, { useRef, useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./print.css";
import html2canvas from "html2canvas";
import apiServices from "../../../Service/apiService";
import useData from "../../redux/customHook/useData";

const PrintPriscription = ({ doctorDetails, patientData, reset, prescriptionPhotoURLs }) => {
  const [selectImage, setSelectImage] = useState("");
  const {data} = useData();

  const [prescriptionImage, setPrescriptionImage] = useState(null);
   // Map clinic locations to their IDs
   const clinicLocationToIdMap = data?.clinicDtos?.reduce((map, clinic) => {
    map[clinic.location] = clinic.id;
    return map;
  }, {});

  // Set prescription image based on clinic ID
  useEffect(() => {
    if (clinicLocationToIdMap) {
      const clinicId = clinicLocationToIdMap[patientData.clinicLocation];
      if (clinicId) {
        setPrescriptionImage(prescriptionPhotoURLs[clinicId]);
      }
    }
  }, [clinicLocationToIdMap, patientData.clinicLocation, prescriptionPhotoURLs]);
  useEffect(() => {
    const s=doctorDetails.specialization[0].toUpperCase();
    switch (s) {
      case "DENTIST": 
        setSelectImage("https://d2sv8898xch8nu.cloudfront.net/MediaFiles/dentist.png");
        break;
      case "EYE SPECIALIST":
        setSelectImage("https://d2sv8898xch8nu.cloudfront.net/MediaFiles/optometrist.png");
        break;
      case "OPTHALMOLOGIST":
        setSelectImage("https://d2sv8898xch8nu.cloudfront.net/MediaFiles/optometrist.png");
        break;
      default:
        setSelectImage(""); // or a placeholder image
    }
  }, [doctorDetails.specialization]);
  console.log(doctorDetails.specialization[0])
  console.log(selectImage)

  // const clinicDetails = doctorDetails.clinics;
  const [imagePrescription, setImagePrescription] = useState(null);
  const captureRef = useRef(null);
  const excludeRef = useRef();
  const navigate = useNavigate();
  const getAllDegree = doctorDetails.degree;
  const handlePrint = () => {
    window.print();
  };
  const formik = useFormik({
    initialValues: {
      note: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      note: Yup.string().required("Please write medicine here "),
    }),
    onSubmit: (values) => {
      uploadPrisciptionPage(values);
    },
  });

  const uploadPrisciptionPage = async () => {
    if (captureRef.current) {
      if (excludeRef.current) {
        excludeRef.current.style.display = "none";
      }
      try {
        const canvas = await html2canvas(captureRef.current);
        const imgData = canvas.toDataURL("image/png");
        setImagePrescription(imgData);
        // Or download the image
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "prescription.png";
        link.click();
      } catch (error) {
        console.error("Error capturing image", error);
      }
    }
  };
  if (imagePrescription) {
    const typeMatch = imagePrescription.match(/^data:(image\/\w+);base64,/);
    if (typeMatch) {
      const imageType = typeMatch[1].split("/")[1];

      // Convert base64 to Blob
      const byteCharacters = atob(imagePrescription.split(",")[1]);
      const byteArray = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteArray], { type: `image/${imageType}` });

      // Create a file from the Blob
      const filename = `image.${imageType}`;

      const file = new File([blob], filename, { type: `image/${imageType}` });
      // Prepare image data for upload
      const formData = new FormData();
      formData.append("contact", patientData.contact);
      formData.append("image", file);

      try {
        if (!imagePrescription) {
          throw new Error("Please select a file.");
        }
        const response = apiServices.post(
          "authentication",
          "/prescriptionUpload",
          null,
          null,
          formData
        );
        if (response) {
          reset();
          navigate(`/uploadDocument/${patientData.contact}`, {
            state: { imagePrescription },
          });
        }
      } catch (error) {
        console.error("Error uploading prescription:", error);
      }
    }
  }
  return (
    <>
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="relative p-4 w-full max-w-4xl h-full md:h-[99%]">
          <div className="relative bg-white rounded-lg shadow w-full h-[99%] my-2 max-h-screen overflow-y-auto">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
              onClick={reset}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>

            <div className="" ref={captureRef}>
            {prescriptionImage ? (
                <img
                  className="w-full h-auto"
                  src={`data:image/jpeg;base64,${prescriptionImage}`}
                  alt="Prescription"
                />
              ) : (
                <div className="p-5">
                  <h5 className="text-lg mb-0.5 font-medium">
                    {doctorDetails.firstName} {doctorDetails.lastName}
                  </h5>
                  <p className="mb-1 text-sm font-normal text-gray-800">
                    {doctorDetails.specialization}
                  </p>
                  <p className="mb-1 text-sm font-normal text-gray-800">{getAllDegree}</p>
                  <p className="mb-1 text-sm font-normal text-gray-800">
                    Location: {patientData.clinicLocation}
                  </p>
                  <p className="mb-4 text-sm font-normal text-gray-800">
                    Mobile No: {patientData.contact}
                  </p>
                </div>
              )}

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
                className="w-full"
              >
                <div className="flex items-center justify-around  pl-4 md:p-2 border-t-2 border-gray-200 rounded-b dark:border-black">
                  <p className="text-black  text-sm px-1 py-2.5 text-center ">
                    Patient Name: &nbsp;{patientData.name}
                  </p>
                  <p className="text-black text-sm px-1 py-2.5 text-center ">
                    Age: &nbsp;{patientData.age}
                  </p>
                  <p className="text-black text-sm px-2 py-2.5 text-center ">
                    Sex: &nbsp;{patientData.gender}
                  </p>
                  <p className="text-black text-sm px-2 py-2.5 text-center ">
                    Date: &nbsp;{patientData.appointmentDate}
                  </p>
                </div>
                <section className="text-gray-600 body-font">
                  <div className="container px-5 mx-auto">
                    <div className="flex flex-wrap -m-4">
                      <div className="p-4 w-1/2">
                        <div className="left-3">
                          <div className="text-xl">Diagnosis</div>
                          <textarea
                            className="block w-full bg-transparent p-2 text-sm resize-none overflow-hidden"
                            contentEditable="true"
                            style={{ outline: "none", minHeight: "216px" }}
                          />
                          <img
                            src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/medicalPriscription.jpg"
                            alt="Example medical"
                            className="w-20 h-16"
                          />
                          <div className="mb-4 ">
                            <textarea
                              id="userInput"
                              name="note"
                              value={formik.values.note}
                              onChange={formik.handleChange}
                              className="block w-full bg-transparent p-2 text-lg resize-none overflow-hidden"
                              contentEditable="true"
                              style={{ outline: "none", minHeight: "300px" }} // Adjust min-height as needed
                            ></textarea>
                            {formik.touched.note && formik.errors.note ? (
                              <p style={{ color: "red" }}>
                                {formik.errors.note}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="p-4 w-1/2">
                        <div className="flex mr-15">
                          <p className="text-black text-sm ml-12 px-2 py-2.5 text-center mr-8 ">
                            Height:-
                            <span>
                              <input
                                className="w-10 ml-2 resize-none overflow-hidden"  
                                placeholder="___"
                                name="height"
                              />{" "}
                            </span>
                          </p>
                          <p className="text-black text-sm px-2 py-2.5 text-center ">
                            Weight:-{" "}
                            <span>
                              <input
                                className="w-10 ml-2"
                                placeholder="____"
                                name="width"
                              />{" "}
                            </span>
                          </p>
                        </div>
                        <div className="flex mr-15">
                          <p className="text-black text-sm ml-12 px-2 py-2.5 text-center mr-7 ">
                            BP:-
                            <span>
                              <input
                                className="w-[25px] ml-2 resize-none overflow-hidden"  
                                placeholder="_________"
                                name="BP"
                              />{" / "}
                               <input
                                className="w-[25px] ml-2 resize-none overflow-hidden"  
                                placeholder="_________"
                                name="BP"
                              />
                            </span>
                          </p>
                          <p className="text-black text-sm px-2 py-2.5 text-center ">
                            Sugar:-{" "}
                            <span>
                              <input
                                className="w-10 ml-2"
                                placeholder="____"
                                name="Sugar"
                              />{" "}
                            </span>
                          </p>
                        </div>
                        {selectImage !== "" && (
                          <img
                            src={selectImage}
                            alt="dentist"
                            className="h-50"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </section>

                <div
                  ref={excludeRef}
                  className="flex items-center p-1 md:p-5 mb-2 border-gray-200 rounded-b dark:border-gray-600"
                >
                  <div className="flex">
                    <button
                      data-modal-hide="default-modal"
                      type="button"
                      onClick={handlePrint}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Print
                    </button>
                    <button
                      data-modal-hide="default-modal"
                      type="submit"
                      className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default PrintPriscription;





/// for whatsap notification 



// import React, { useRef, useState, useEffect } from "react";
// import { useNavigate} from "react-router-dom";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import { ToastContainer} from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import "./print.css";
// import html2canvas from "html2canvas";
// import apiServices from "../../../Service/apiService";
// import useData from "../../redux/customHook/useData";
// import jsPDF from "jspdf";
// import { useReactToPrint } from "react-to-print";

// const PrintPriscription = ({ doctorDetails, patientData, reset, prescriptionPhotoURLs }) => {
//   const [selectImage, setSelectImage] = useState("");
//   const {data} = useData();

//   const [prescriptionImage, setPrescriptionImage] = useState(null);
//    // Map clinic locations to their IDs
//    const clinicLocationToIdMap = data?.clinicDtos?.reduce((map, clinic) => {
//     map[clinic.location] = clinic.id;
//     return map;
//   }, {});

//   // Set prescription image based on clinic ID
//   useEffect(() => {
//     if (clinicLocationToIdMap) {
//       const clinicId = clinicLocationToIdMap[patientData.clinicLocation];
//       if (clinicId) {
//         setPrescriptionImage(prescriptionPhotoURLs[clinicId]);
//       }
//     }
//   }, [clinicLocationToIdMap, patientData.clinicLocation, prescriptionPhotoURLs]);
//   useEffect(() => {
//     const s=doctorDetails.specialization[0].toUpperCase();
//     switch (s) {
//       case "DENTIST": 
//         setSelectImage("https://d2sv8898xch8nu.cloudfront.net/MediaFiles/dentist.png");
//         break;
//       case "EYE SPECIALIST":
//         setSelectImage("https://d2sv8898xch8nu.cloudfront.net/MediaFiles/optometrist.png");
//         break;
//       case "OPTHALMOLOGIST":
//         setSelectImage("https://d2sv8898xch8nu.cloudfront.net/MediaFiles/optometrist.png");
//         break;
//       default:
//         setSelectImage(""); // or a placeholder image
//     }
//   }, [doctorDetails.specialization]);
//   console.log(doctorDetails.specialization[0])
//   console.log(selectImage)

//   // const clinicDetails = doctorDetails.clinics;
//   const [imagePrescription, setImagePrescription] = useState(null);
//   const captureRef = useRef(null);
//   const excludeRef = useRef();
//   const navigate = useNavigate();
//   const getAllDegree = doctorDetails.degree;
//   const handlePrint = () => {
//     window.print();
//   };
//   const formik = useFormik({
//     initialValues: {
//       note: "",
//     },
//     validateOnBlur: false,
//     validateOnChange: false,
//     validationSchema: Yup.object().shape({
//       note: Yup.string().required("Please write medicine here "),
//     }),
//     onSubmit: (values) => {
//       console.log(values)
//       uploadPrisciptionPage(values);
//     },
//   });
//   const uploadPrisciptionPage = useReactToPrint({
//     content: () => {
//       if (excludeRef.current) {
//         excludeRef.current.style.display = "none";
//       }
//       return captureRef.current;
//     },
//     documentTitle: "Prescription",
//     onAfterPrint: async () => {
//       try {
//         const input = captureRef.current;
//         const pdf = new jsPDF("p", "mm", "a4");

//         // Compress further by lowering the quality and scale
//         const canvas = await html2canvas(input, {
//           scale: 1, // Lower scale for further compression
//           useCORS: true,
//         });

//         // Further reduce image quality (0.2 for very high compression)
//         const imgData = canvas.toDataURL("image/jpeg", 0.37);

//         const imgWidth = 210; // A4 width in mm
//         const pageHeight = 297; // A4 height in mm
//         const imgHeight = (canvas.height * imgWidth) / canvas.width;
//         let heightLeft = imgHeight;

//         let position = 0;

//         // Add the first page
//         pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
//         heightLeft -= pageHeight;

//         // Handle additional pages if content exceeds one page
//         while (heightLeft > 0) {
//           position = heightLeft - imgHeight;
//           pdf.addPage();
//           pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
//           heightLeft -= pageHeight;
//         }

//         // Save the further compressed PDF as Blob
//         const pdfBlob = pdf.output("blob");

//         // Prepare FormData for API submission
//         const formDataToSubmit = new FormData();
//         formDataToSubmit.append("file", pdfBlob, "agreement.pdf");
//         // formDataToSubmit.append("doctorId", doctorId);

//         // Log compressed PDF size
//         console.log("Further Compressed PDF size in bytes:", pdfBlob.size);

//         // Submit the FormData to the API
//         // const response = await apiServices.post(
//         //   "authentication",
//         //   "/doctorTermAndCondition/create",
//         //   null,
//         //   {
//         //     // doctorId: doctorId,
//         //   },
//         //   formDataToSubmit
//         // );

//         // if (response) {
//         //   console.log("Upload successful", response.data);
//         //   reset();
//         //   navigate(`/uploadDocument/${patientData.contact}`, {
//         //     state: { imagePrescription },
//         //   });
//         // } else {
//         //   console.error("Failed to upload PDF:", response.status);
//         // }
//       } catch (error) {
//         console.error("Error uploading PDF:", error.message);
//       }
//     },
//   });

//   // const uploadPrisciptionPage = async () => {
//   //   if (captureRef.current) {
//   //     if (excludeRef.current) {
//   //       excludeRef.current.style.display = "none";
//   //     }
//   //     try {
//   //       const canvas = await html2canvas(captureRef.current);
//   //       const imgData = canvas.toDataURL("image/png");
//   //       setImagePrescription(imgData);
//   //       // Or download the image
//   //       const link = document.createElement("a");
//   //       link.href = imgData;
//   //       link.download = "prescription.png";
//   //       link.click();
//   //     } catch (error) {
//   //       console.error("Error capturing image", error);
//   //     }
//   //   }
//   // };
//   // if (imagePrescription) {
//   //   const typeMatch = imagePrescription.match(/^data:(image\/\w+);base64,/);
//   //   if (typeMatch) {
//   //     const imageType = typeMatch[1].split("/")[1];

//   //     // Convert base64 to Blob
//   //     const byteCharacters = atob(imagePrescription.split(",")[1]);
//   //     const byteArray = new Uint8Array(byteCharacters.length);
//   //     for (let i = 0; i < byteCharacters.length; i++) {
//   //       byteArray[i] = byteCharacters.charCodeAt(i);
//   //     }
//   //     const blob = new Blob([byteArray], { type: `image/${imageType}` });

//   //     // Create a file from the Blob
//   //     const filename = `image.${imageType}`;

//   //     const file = new File([blob], filename, { type: `image/${imageType}` });
//   //     // Prepare image data for upload
//   //     const formData = new FormData();
//   //     formData.append("contact", patientData.contact);
//   //     formData.append("image", file);

//   //     try {
//   //       if (!imagePrescription) {
//   //         throw new Error("Please select a file.");
//   //       }
//   //       const response = apiServices.post(
//   //         "authentication",
//   //         "/prescriptionUpload",
//   //         null,
//   //         null,
//   //         formData
//   //       );
//   //       if (response) {
//   //         reset();
//   //         navigate(`/uploadDocument/${patientData.contact}`, {
//   //           state: { imagePrescription },
//   //         });
//   //       }
//   //     } catch (error) {
//   //       console.error("Error uploading prescription:", error);
//   //     }
//   //   }
//   // }
//   return (
//     <>
//       <div
//         id="login-popup"
//         tabIndex="-1"
//         className="bg-black/50 fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
//       >
//         <div className="relative p-4 w-full max-w-4xl h-full md:h-[99%]">
//           <div className="relative bg-white rounded-lg shadow w-full h-[99%] my-2 max-h-screen overflow-y-auto">
//             <button
//               type="button"
//               className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
//               onClick={reset}
//             >
//               <svg
//                 aria-hidden="true"
//                 className="w-5 h-5"
//                 fill="#c6c7c7"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span className="sr-only">Close popup</span>
//             </button>

//             <div className="" ref={captureRef}>
//             {prescriptionImage ? (
//                 <img
//                   className="w-full h-auto"
//                   src={`data:image/jpeg;base64,${prescriptionImage}`}
//                   alt="Prescription"
//                 />
//               ) : (
//                 <div className="p-5">
//                   <h5 className="text-lg mb-0.5 font-medium">
//                     {doctorDetails.firstName} {doctorDetails.lastName}
//                   </h5>
//                   <p className="mb-1 text-sm font-normal text-gray-800">
//                     {doctorDetails.specialization}
//                   </p>
//                   <p className="mb-1 text-sm font-normal text-gray-800">{getAllDegree}</p>
//                   <p className="mb-1 text-sm font-normal text-gray-800">
//                     Location: {patientData.clinicLocation}
//                   </p>
//                   <p className="mb-4 text-sm font-normal text-gray-800">
//                     Mobile No: {patientData.contact}
//                   </p>
//                 </div>
//               )}

//               <form
//                 onSubmit={(e) => {
//                   e.preventDefault();
//                   formik.handleSubmit();
//                 }}
//                 className="w-full"
//               >
//                 <div className="flex items-center justify-around  pl-4 md:p-2 border-t-2 border-gray-200 rounded-b dark:border-black">
//                   <p className="text-black  text-sm px-1 py-2.5 text-center ">
//                     Patient Name: &nbsp;{patientData.name}
//                   </p>
//                   <p className="text-black text-sm px-1 py-2.5 text-center ">
//                     Age: &nbsp;{patientData.age}
//                   </p>
//                   <p className="text-black text-sm px-2 py-2.5 text-center ">
//                     Sex: &nbsp;{patientData.gender}
//                   </p>
//                   <p className="text-black text-sm px-2 py-2.5 text-center ">
//                     Date: &nbsp;{patientData.appointmentDate}
//                   </p>
//                 </div>
//                 <section className="text-gray-600 body-font">
//                   <div className="container px-5 mx-auto">
//                     <div className="flex flex-wrap -m-4">
//                       <div className="p-4 w-1/2">
//                         <div className="left-3">
//                           <div className="text-xl">Diagnosis</div>
//                           <textarea
//                             className="block w-full bg-transparent p-2 text-sm resize-none overflow-hidden"
//                             contentEditable="true"
//                             style={{ outline: "none", minHeight: "216px" }}
//                           />
//                           <img
//                             src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/medicalPriscription.jpg"
//                             alt="Example medical"
//                             className="w-20 h-16"
//                           />
//                           <div className="mb-4 ">
//                             <textarea
//                               id="userInput"
//                               name="note"
//                               value={formik.values.note}
//                               onChange={formik.handleChange}
//                               className="block w-full bg-transparent p-2 text-lg resize-none overflow-hidden"
//                               contentEditable="true"
//                               style={{ outline: "none", minHeight: "300px" }} // Adjust min-height as needed
//                             ></textarea>
//                             {formik.touched.note && formik.errors.note ? (
//                               <p style={{ color: "red" }}>
//                                 {formik.errors.note}
//                               </p>
//                             ) : null}
//                           </div>
//                         </div>
//                       </div>
//                       <div className="p-4 w-1/2">
//                         <div className="flex mr-15">
//                           <p className="text-black text-sm ml-12 px-2 py-2.5 text-center mr-8 ">
//                             Height:-
//                             <span>
//                               <input
//                                 className="w-10 ml-2 resize-none overflow-hidden"  
//                                 placeholder="___"
//                                 name="height"
//                               />{" "}
//                             </span>
//                           </p>
//                           <p className="text-black text-sm px-2 py-2.5 text-center ">
//                             Weight:-{" "}
//                             <span>
//                               <input
//                                 className="w-10 ml-2"
//                                 placeholder="____"
//                                 name="width"
//                               />{" "}
//                             </span>
//                           </p>
//                         </div>
//                         <div className="flex mr-15">
//                           <p className="text-black text-sm ml-12 px-2 py-2.5 text-center mr-7 ">
//                             BP:-
//                             <span>
//                               <input
//                                 className="w-[25px] ml-2 resize-none overflow-hidden"  
//                                 placeholder="_________"
//                                 name="BP"
//                               />{" / "}
//                                <input
//                                 className="w-[25px] ml-2 resize-none overflow-hidden"  
//                                 placeholder="_________"
//                                 name="BP"
//                               />
//                             </span>
//                           </p>
//                           <p className="text-black text-sm px-2 py-2.5 text-center ">
//                             Sugar:-{" "}
//                             <span>
//                               <input
//                                 className="w-10 ml-2"
//                                 placeholder="____"
//                                 name="Sugar"
//                               />{" "}
//                             </span>
//                           </p>
//                         </div>
//                         {selectImage !== "" && (
//                           <img
//                             src={selectImage}
//                             alt="dentist"
//                             className="h-50"
//                           />
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                 </section>

//                 <div
//                   ref={excludeRef}
//                   className="flex items-center p-1 md:p-5 mb-2 border-gray-200 rounded-b dark:border-gray-600"
//                 >
//                   <div className="flex">
//                     <button
//                       data-modal-hide="default-modal"
//                       type="button"
//                       onClick={handlePrint}
//                       className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//                     >
//                       Print
//                     </button>
//                     <button
//                       data-modal-hide="default-modal"
//                       type="submit"
//                       className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
//                     >
//                       Submit
//                     </button>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//       <ToastContainer />
//     </> 
//   );
// };
// export default PrintPriscription;











