import apiServices from "../../../Service/apiService";

export async function fetchAppointment(date) {
  const token = sessionStorage.getItem("token");
  try {
    const response = await apiServices.get(
      "authentication",
      "/clinic/ClincsToAppointment",
      {
        Authorization: " Bearer " + token,
      },
      {
        startDate: date,
        endDate: date,
      },null
    );
    if (response) {
      return response;
    }
  } catch (error) {
    return {
      error: error,
      message: "Something went wrong",
    };
  }
}