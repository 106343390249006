import html2canvas from "html2canvas";
import React, { useState, useEffect , useRef} from "react";
import { useReactToPrint } from "react-to-print";
import apiServices from "../../../Service/apiService";
import jsPDF from "jspdf";


const Term = ({ formData, onSubmit ,doctorId }) => {
  const [collectDoctorData, setCollectDoctorData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const componentRef = useRef();
 
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Medical Onboarding Agreement",
    onAfterPrint: async () => {
      try {
        const input = componentRef.current;
        const pdf = new jsPDF("p", "mm", "a4");

        // Compress further by lowering the quality and scale
        const canvas = await html2canvas(input, {
          scale: 1, // Lower scale for further compression
          useCORS: true,
        });

        // Further reduce image quality (0.2 for very high compression)
        const imgData = canvas.toDataURL("image/jpeg", 0.37);

        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        // Add the first page
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        // Handle additional pages if content exceeds one page
        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        // Save the further compressed PDF as Blob
        const pdfBlob = pdf.output("blob");

        // Prepare FormData for API submission
        const formDataToSubmit = new FormData();
        formDataToSubmit.append("file", pdfBlob, "agreement.pdf");
        formDataToSubmit.append("doctorId", doctorId);

        // Log compressed PDF size
        console.log("Further Compressed PDF size in bytes:", pdfBlob.size);

        // Submit the FormData to the API
        const response = await apiServices.post(
          "authentication",
          "/doctorTermAndCondition/create",
          null,
          {
            doctorId: doctorId,
          },
          formDataToSubmit
        );

        if (response) {
          console.log("Upload successful", response.data);
          onSubmit();
        } else {
          console.error("Failed to upload PDF:", response.status);
        }
      } catch (error) {
        console.error("Error uploading PDF:", error.message);
      }
      onSubmit();
    },
  });


  const handleSubmit = (e) => {
    e.preventDefault();

    // Trigger the PDF generation and print
    handlePrint();
  };

  useEffect(() => {
    if (formData) {
      setCollectDoctorData(formData || []);
    }
  }, [formData]);
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="w-[80%] border border-1 border-gray-300 overflow-auto scroll grid h-screen ml-0 p-6 md:ml-5 mt-10 rounded">
        <div ref={componentRef} className="px-4">
          <h3 className="text-center font-bold underline p-10">
            MEDICAL PRACTITIONERS ON-BOARDING AGREEMENT
          </h3>
          <p className="pb-5 ">
            <span className="font-bold">
              This Medical Practitioners On-boarding Agreement
            </span>{" "}
            is entered into on this <span>today's date</span> by and amongst
          </p>

          <p>
            <span className="font-bold">Reshita</span>, a firm having its place
            of business at Flat no. 33, Mangaldeep Apartment, Patliputra colony,
            Patna, Bihar, (hereinafter referred to as{" "}
            <span className="font-bold"> “the Firm” </span>, which term shall,
            unless repugnant to the context or meaning thereof, mean and include
            its successors in interest, liquidators and assigns), the party of{" "}
            <span className="font-bold">the First Part </span>
          </p>
          <h1 className="text-center font-bold p-5">AND</h1>
          <p className="py-5">
            {" "}
            <span className="font-bold">
              {collectDoctorData.firstName} {collectDoctorData.lastName},
            </span>{" "}
            having registration no. {collectDoctorData.licenceNumber} (hereinafter
            referred to as{" "}
            <span className="font-bold">
              {" "}
              “the Registered Medical Practitioner/RMP”{" "}
            </span>
            which term shall, unless repugnant to the context or meaning thereof,
            mean and include its successors in interest, legal heirs and assigns),
            the party of <span className="font-bold"> the Second Part</span>
          </p>
          <p className="py-5">
          <span className="font-bold">WHEREAS </span>the Firm and the RMP are
          hereinafter referred to individually as a{" "}
          <span className="font-bold">Party" </span>and collectively as the{" "}
          <span className="font-bold">"Parties"</span>
        </p>
        <p>
          <span className="font-bold">WHEREAS </span> the Firm is engaged in the
          business of developing software and web applications and providing
          other IT solutions
        </p>
        <p className="py-5">
          <span className="font-bold">WHEREAS </span> the Firm has developed a
          SAAS product namely DOC-AID which provides a platform to the RMP
          providing assistance in managing appointments, prescriptions, and
          payments and streamlines patient on-boarding and optimizing time for
          accurate diagnoses.
        </p>
        <p>
          <span className="font-bold">WHEREAS </span> the RMP is engaged in
          rendering specialized professional services for the diagnosis,
          treatment, and medication of its patients;{" "}
        </p>
        <p className="py-5">
          <span className="font-bold">AND WHEREAS </span> the RMP wish to engage
          the services of the Firm, through its SAAS product DOC-AID in
          organizing their patient medical data.{" "}
        </p>
        <p className="font-bold">
          Now, THEREFORE, in consideration of the promises and the mutual
          covenants to be faithfully performed, the Parties hereto agree as
          follows.
        </p>
        <p className="font-bold underline py-5"> 1. PURPOSE AND SCOPE</p>
        <p className="ml-5">
          This Agreement aims at outlining the terms and conditions regulating
          the services offered by the Firm’s SAAS Product, referred to as
          Doc-Aid, particularly in the context of collecting, storing, and
          processing sensitive personal data (medical data) of individuals on
          its digital platform. It is further understood, that the medical data
          of individuals (hereinafter referred to as Data Principal) shall be
          made exclusively accessible to RMP treating or advising such Data
          Principals, provided Data Principal furnish their Ayushman Bharat
          Health Account ID (hereinafter abbreviated as “ABHA ID”), if any, and
          their contact number to the RMP. This Agreement shall formulate the
          procedure through which DOC- AID shall collect, store, and process the
          personal data of the Data Principal, and ensure that the said
          procedure is in consonance with the Reasonable Security Practices and
          Procedures provided under the Health Data Management Policy issued by
          the National Digital Health Mission (hereinafter abbreviated as
          “NDHM”).
        </p>
        <p className="font-bold underline py-5">
          {" "}
          2. DEFINITIONS AND INTERPRETATIONS
        </p>
        <p className="ml-5">
          Unless the contrary intention appears, the definitions and rules of
          interpretation set forth in CLAUSE 2 (Definitions and Interpretations)
          shall apply to this Agreement.{" "}
        </p>
        <p className="font-bold underline py-5"> 2.1 DEFINITIONS:</p>
        <p className="ml-5">
          {" "}
          <span className="font-bold"> (a) “ABHA”</span> means Ayushman Bharat
          Health Account created under the Ayushman Bharat Digital Mission
          (ABDM) for seamless management and sharing of medical records upon
          holder’s consent.{" "}
        </p>
        <p className="ml-5 py-5">
          {" "}
          <span className="font-bold">(b) “ABHA-ID”</span>means the Ayushman
          Bharat Health Account ID bearing a unique 14-digit identification
          number, issued and administered through the Ayushman Bharat Digital
          Mission. (ABDM) portal, which facilitates its holder with a digital
          health locker to receive, store & share medical records, enabling
          convenient access to medical treatment history and other medical
          records{" "}
        </p>
        <p className="ml-5">
          {" "}
          <span className="font-bold">(c) “Account”</span> shall mean the user
          account created by the registered user of DOC-AID for availing the
          services provided by DOC-AID.{" "}
        </p>
        <p className="ml-5 py-5">
          {" "}
          <span className="font-bold">(d) “Applicable Laws”</span> shall mean
          and include all applicable statutes, enactments, acts of legislature
          or parliament, laws, ordinances, rules, by-laws, regulations,
          notifications, guidelines, policies, directions, directives and orders
          of any governmental authority, tribunal, or a court of India. records{" "}
        </p>
        <p className="ml-5">
          {" "}
          <span className="font-bold">(e) “Data Principal”</span>shall mean as
          defined in Clause 1 and includes the patients/ individuals who furnish
          their free consent for collection, storage and processing of their
          sensitive personal data on DOC-AID records{" "}
        </p>
        <p className="ml-5 py-5">
          {" "}
          <span className="font-bold">
            (f) “DOC-AID/ Application/ Platform”
          </span>{" "}
          is the SAAS product developed by the Firm, which facilitates in the
          maintenance of registered account details of the Users, medical
          records of Data Principal, including but not limited to prescriptions,
          medical history, laboratory test results, and any/all other relevant
          information uploaded on DOC-AID.{" "}
        </p>
        <p className="ml-5">
          {" "}
          <span className="font-bold">(g) “User”</span>shall mean and include
          the RPM who create a registered account on DOC-AID.
        </p>
        <p className="ml-5 py-5">
          {" "}
          <span className="font-bold">(h) “User Data”</span>shall mean and
          include the data uploaded by the User, including without limitation:{" "}
        </p>
        <p className="ml-12 py-2">
          i. Log in credentials /ABHA ID/ Contact Information and other relevant
          personal information of the User;
        </p>
        <p className="ml-12">
          ii. financial information relating to bank account or credit card or
          debit card or other payment instrument details of the User
        </p>
        <p className="ml-12 py-2">
          iii. physical, physiological and mental health condition of the Data
          Principal;
        </p>
        <p className="ml-12">
          iv. physical, physiological and mental health condition of the Data
          Principal;
        </p>
        <p className="ml-12 py-2">
          v. Biometric information of User or Data Principal;
        </p>
        <p className="ml-12">
          vi. any/ all information uploaded on DOC-AID as part of its due
          operations;
        </p>
        <p className="font-bold underline py-5"> 3. DATA HANDLING PROTOCOLS</p>
        <p className="ml-5">
          The Parties understand and agree to adhere to the following data
          handling protocols:{" "}
        </p>
        <p className="font-bold underline py-5">
          {" "}
          3.1 Secure Storage and Management:
        </p>
        <p className="ml-5">
          the Parties shall adhere to the standard safety protocols implemented
          to safeguard and protect the confidentiality, integrity, and
          accessibility of the data stored on DOC-AID, ensuring secure and
          operational management.{" "}
        </p>
        <p className="font-bold underline py-5">
          3.2 Comprehensive Medical Records:
        </p>
        <p className="ml-5">
          DOC-AID shall facilitate the maintenance of registered accounts
          details of the Users, medical records of Data Principal, including but
          not limited to prescriptions, medical history, laboratory test
          results, and any/all other relevant information uploaded on DOC-AID.
          The scope of maintenance of such data by DOC-AID shall be limited to
          collection, storage and processing, upon the directions received by
          the User, and shall not have the right of any modification or
          deviation from its original format.{" "}
        </p>
        <p className="font-bold underline py-5">
          3.3 Appointment Management (No Consultations):
        </p>
        <p className="ml-5">
          DOC-AID shall facilitate the User in the management and organization
          of appointments of Data Principals through reminders and to ensure
          overall practice efficiency. However, DOC-AID itself shall not provide
          any scheduling of consultation appointments on its platform.{" "}
        </p>
        <p className="font-bold underline py-5">3.4 Access Point:</p>
        <p className="ml-5">
          The access point of the personal and medical data of Data Principal
          shall be its ABHA ID and contact number, ensuring that the RMP on
          Doc-Aid shall be able to access, upload and process the personal data
          of Data Principal in a hassle free and uniform manner{" "}
        </p>
        <p className="font-bold underline py-5">
          3.5 Robust Security Measures:
        </p>
        <p className="ml-5">
          the Parties shall adhere to the data safety measures as provided on
          DOC-AID, in accordance with clause 4 & 6 of the Agreement.
        </p>
        <p className="font-bold underline py-5">
          3.6 Data Backup and Recovery:
        </p>
        <p className="ml-5">
          : the Firm shall duly maintain and back-up the personal data of the
          Data Principal at regular intervals, and have in place a data recovery
          procedure in case of failure of DOC-AID or any data loss from the
          platform.
        </p>
        <p className="font-bold underline py-5">3.7 Data Retention Policies:</p>
        <p className="ml-5">
          The Firm has established clear policies for the retainment and
          disposal of all the data on DOC-AID for a reasonable period of time,
          in accordance with the provisions of the applicable law.
        </p>
        <p className="font-bold underline py-5">3.8 Technical Support:</p>
        <p className="ml-5">
          {" "}
          The Firm shall provide its technical support to the RMP to ensure
          smooth access and management of data and the usage of DOC-AID,
          including data encryption and regular upgradation of the Application
        </p>
        <p className="font-bold underline py-5">4. OBLIGATIONS OF THE RMP</p>
        <p className="py-5">4.1 Accuracy of Data-</p>
        <p className="ml-5">
          The RMP shall be solely responsible for the accuracy and correctness
          of the medical data and personal information uploaded or accessed on
          DOC-AID. Hence, the RMP is obligated to upload and provide the most
          accurate data of the Data Principal on the Application. RMP
          understands and agrees that DOC-AID or the Firm shall not be liable at
          any point of time, whatsoever in nature, for the accuracy and
          correctness of the medical data and personal information uploaded or
          accessed on DOC-AID.
        </p>
        <p className="py-5">4.2 Activities on the Application-</p>
        <p className="ml-5">
          The RMP shall be responsible for all its activities through its
          assigned unique account on DOC-AID, which shall include without
          limitation, all its collection, modification, transmission and
          processing of the personal data of the Data Principal, and shall be
          obligated to fulfil any liability incurred through such activities
        </p>
        <p className="py-5">4.3 Authorization of Data Principal-</p>
        <p className="ml-5">
          The RMP shall obtain the necessary authorization from the Data
          Principal prior to the collection, storage, and processing of its
          personal data on DOC-AID. The RMP shall be permitted to collect or
          process such personal data only upon the receipt of valid and free
          consent from the Data Principal. The RMP shall inform the Data
          Principal about the usage, transmission or modification of its
          personal data that may be done by the RMP and the associated rights of
          the Data Principal regarding the withdrawal of such consent.
        </p>
        <p className="py-5">
          4.4 The RMP shall be obligated to adhere to the data security measures
          and procedures formulated for protecting the personal sensitive data
          of the Data Principal, provided under the policies of DOC-AID.{" "}
        </p>
        <p className="font-bold underline py-5">
          5. COVENANTS AND UNDERTAKINGS OF RMP{" "}
        </p>
        <p>
          5.1 The RMP specifically agrees that the data collected and processed
          by RMP is the most sensitive personal data of the Data Principal, and
          it undertakes the following to be adhered at all times while using the
          DOC-AID:
        </p>
        <p className="ml-5 py-5">
          i. The RMP shall not publish, display or post publically the personal
          data of the Data Principal except for the purpose of collection,
          storage and processing of such data on DOC-Aid, in accordance with the
          present Agreement and upon the duly obtained consent of the Data
          Principal
        </p>
        <p className="ml-5">
          ii. The RMP shall not use the personal data of the Data Principal for
          any commercial purposes available on DOC-AID.
        </p>
        <p className="ml-5 py-5 ">
          iii. The RMP shall only upload the personal medical data of the Data
          Principal and shall refrain from uploading any explicit content or
          false information or any information prohibited under any Applicable
          Law;
        </p>
        <p className="ml-5">
          iv. The RMP shall be restricted to create only one unique account on
          DOC-AID for the purposes stated in this Agreement and shall not
          further share the details of the login credentials of its Account with
          any third party. The RMP shall not further assign, or transfer any of
          its rights or obligations under this Agreement to any third party.
        </p>
        <p className="ml-5 py-5">
          v. The collection, storage and processing of the personal data of the
          Data Principal shall only be undertaken by the RMP for purposes
          specified herein this Agreement and in respect of which the Data
          Principal has provided its due consent. This said authorization by the
          Data Principal shall remain valid except in the case where the Data
          Principal communicates its willingness to withdraw its consent for the
          collection, storage and processing of the personal data for the
          purposes specified.
        </p>
        <p>
          5.2 The RMP agrees not to undertake any of the activities stated
          below:{" "}
        </p>
        <p className="ml-5 py-5">
          i. use any robot, spider, scraper or other automated means to access
          the services of DOCAID;
        </p>
        <p className="ml-5">
          ii. decompile, reverse engineer or disassemble any software or other
          products or processes accessible through DOC-AID;{" "}
        </p>
        <p className="ml-5 py-5">
          iii. insert any code or product or manipulate the data available on
          DOC-AID in any way;
        </p>
        <p className="ml-5 pb-6">
          iv. use any data mining, data gathering or extraction method to gain
          any data available on DOC-AID in an unauthorized or unlawful manner;{" "}
        </p>
        <p>
          5.3 RMP agrees that adherence to the undertakings, as referred herein
          above in this clause, is crucial and any breach towards adherence of
          the same by RMP shall be termed as a material breach. The Firm
          reserves all rights to take necessary legal actions against the RMP
          for failure in compliance to this clause which is without prejudice to
          any other rights which may be available to Firm in this Agreement.
          This clause shall subsist the termination of this Agreement and RMP
          agrees to indemnify the Firm, without any limitation, for any losses
          or damages, which may be faced by Firm due to failure of RMP to adhere
          the terms under this clause
        </p>
        <p className="font-bold underline py-5">6. OBLIGATIONS OF DOC-AID </p>
        <p className="">
          6.1 DOC-AID shall collect, store and process the personal data of the
          Data Principal only for the specified purposes regarding which its due
          consent has been provided to the RMP.
        </p>
        <p className="py-5">
          6.2 DOC-AID shall not modify, transfer, publish or use the personal
          data of the Data Principal, publically or to any unauthorized third
          party, for any commercial or non-commercial purposes in any form or in
          any means uploaded by the RMP under the terms and conditions of the
          Agreement.{" "}
        </p>
        <p>
          6.3 DOC-AID shall maintain the data security with robust measures, to
          protect the personal sensitive data of the Data Principal from any
          unauthorized access by any third party breaches, and cyber threats,
          and shall be in compliance with the Reasonable Security Practices and
          Procedures issued under the Health Data Management Policy of the
          National Digital Health Mission.{" "}
        </p>
        <p className="py-5">
          6.4 DOC-AID shall maintain the secrecy and security of the personal
          data of the Data Principal stored on its database, in accordance with
          best industry practices and applicable laws, ensuring Accountability,
          Transparency, Privacy by Design, Choice and Consent-Driven Sharing,
          Purpose Limitation, Collection, Use, and Storage Limitation, as well
          as implementing Reasonable Security Practices and Procedures, as
          stated under clause 6.3 of this Agreemen
        </p>
        <p className="font-bold underline pb-5">7. CONFIDENTIALITY</p>
        <p>
          <span className="font-bold">7.1 Confidential Information</span>shall
          mean all the confidential and proprietary information in tangible or
          intangible form, of the Data Principal or the RMP, including without
          limitation the medical records, medical history, laboratory test
          reports, biometric information, ABHA ID, contact details or any other
          personal sensitive data of the Data Principal; and the login
          credentials, financial information like bank account details, credit
          or debit card details, UPI details, any account verification details,
          registration number, or any other personal data of the RMP, which is
          disclosed to or otherwise provided on DOC-AID by the RMP, but does not
          include information that is generally known to the public, or
          generally known to the Parties at the time of such disclosure, or
          subsequently received by the Parties in good faith from a third party
          having the prior right to make such disclosure.
        </p>
        <p className="py-5">
          7.2 The Firm acknowledges that by the reason of engaging its services
          through DOC-AID by the RMP the Firm will have access to information
          which relates to the personal sensitive data of the Data Principal and
          the RMP, which is not generally known to the general public.
        </p>
        <p>
          7.3 The Firm acknowledges that the Confidential Information provided
          on DOC-AID shall be the respective property of the Data Principal or
          the RMP, and shall not be either directly or indirectly at any time
          made known, divulged, revealed, furnished, made available or used
          without the written consent of the Data Principal or the RMP
        </p>
        <p className="py-5">
          7.4 The RMP and the Data Principal are well versed with the terms of
          this Agreement and any Confidential Information shall remain their
          sole property.
        </p>
        <p>
          7.5 The RMP agrees and understands that during the course of the
          present Agreement, the RMP would be made aware of the business model
          of DOC-AID and shall gain access to its services, and hence, shall not
          disseminate any information publically which may be confidential for
          the Firm and may cause loss to its business of the Firm.
        </p>
        <p className="py-5">
          7.6 The Parties agree and understand that these obligations of
          confidentiality shall survive in perpetuity from the termination of
          this Agreement, whether or not the Confidential Information is
          returned to the other Party.{" "}
        </p>
        <p>7.7 Exceptions to obligation:-</p>
        <p className="py-5 ml-5">
          The provisions of this Clause shall not be applicable to the
          disclosure of such confidential information in case either Party is
          required to disclose such information to the courts of any competent
          jurisdiction, or to any government regulatory agency or financial
          authority, provided that the disclosing Party shall
        </p>
        <p className="ml-7">
          i. inform the other Party as soon as is reasonably practicable; and
        </p>
        <p className="py-5 ml-7">
          ii. at the other Party's request seek to persuade the court, agency or
          authority to have the information treated in a confidential manner,
          where this is possible under the court, agency or authority's
          procedures.
        </p>
        <p className="font-bold py-5 underline">8. TERMS AND MODE OF PAYMENT</p>
        <p>
          8.1 The RMP shall pay the sum of INR 3000/- (Three thousand rupee
          only) to DOC-AID as a one-time onboarding fee for availing the
          Services of DOC-AID
        </p>
        <p className="py-5">
          8.2 The RMP shall pay the sum of INR 1500/- (Fifteen Hundred rupees
          Only) to DOC-AID as a monthly subscription fee in order to avail the
          services provided by the Firm on a monthly basis, along with the
          oneboarding fee.
        </p>
        <p>
          8.3 The RMP shall pay an additional sum of INR 500/- (five hundred
          rupees only) everytime the RMP adds a clinic location on DOC-AID, in
          order to expand his/her services.{" "}
        </p>
        <p className="py-5">
          8.4 The RMP shall also bear such additional charges like taxes and
          transaction charges, along with the Subscription Fee, as may be
          applicable on the Account of the RMP on DOC-AID.
        </p>
        <p>
          8.5 The RMP shall pay the due Onboarding fee along with the
          Subscription Fee in advance before availing the services offered by
          DOC-AID and thereafter on a monthly basis, against which a receipt
          shall be sent to the registered email of the RMP by DOC-AID.{" "}
        </p>
        <p className="py-5">
          8.6 The Subscription Fee shall fall due at the end of each payment
          cycle of 30 days and the payment of such Subscription Fee shall be
          made within the upcoming 7 days from the day when such payment falls
          due
        </p>
        <p>
          8.7 The RMP shall select one mode of payment of the Subscription Fee
          amongst the various modes of payment provided on DOC-AID for the
          payment of such fee.
        </p>
        <p className="py-5">
          8.8 In case of non-payment of the Subscription Fee by the RMP within 7
          days after such payment falls due, the Account of the RMP shall be
          suspended and the RMP shall not be able to avail any services provided
          by DOC-AID, or access any data stored on the Account of the RMP.
        </p>
        <p>
          8.9 Further, the Firm may change the subscription plans and the price
          of the service provided on DOCAID, from time to time, after giving a
          prior notice of 30 days regarding any price changes or changes to the
          subscription plan of the RMP will become effective.
        </p>
        <p className="font-bold underline py-5">9. TERM AND TERMINATION</p>
        <p>
          9.1 This Agreement shall be valid until terminated by either Party
          after giving a prior written notice to the other Party.{" "}
        </p>
        <p className="py-5">
          9.2 Either Party may terminate this Agreement, without cause, upon
          giving a prior written notice of 30 days to the other Party.{" "}
        </p>
        <p>9.3 Termination with cause</p>
        <p className="ml-5">
          The Firm mayterminate this Agreement at any time upon giving a Show
          Cause Notice having immediate effect, in case of occurrence of any of
          the events mentioned below:{" "}
        </p>
        <p className="ml-7 py-5">
          i. The RMP causes any breach of the terms of this Agreement which may
          cause irreparable loss to the Firm; or
        </p>
        <p className="ml-7">
          ii. The RMP conducts any act of misrepresentation, fraud, professional
          misconduct or breach of privacy, or conducts wilful negligence in
          undertaking its obligations under this Agreement, or any other
          unlawful activities; or
        </p>
        <p className="ml-7 py-5">
          iii. The RMP breaches the confidentiality and privacy clauses of the
          present Agreement against the Firm or the Data Principal.{" "}
        </p>
        <p>9.4 Consequence of Termination</p>
        <p className="ml-5 py-5">
          i. The Firm shall terminate the subscription of the Account of the RMP
          and block any access by the RMP of DOC-AID, in case the Agreement is
          terminated due to cause.{" "}
        </p>
        <p className="ml-5 ">
          ii. The Firm may undertake any legal actions as has been stated under
          Clause 12, if deems necessary, in case of the occurrence of any event
          stated under aforesaid clause 9.3. The Firm in such cases has the
          right to be indemnified for the losses incurred and recover the cost
          of legal action taken by it from the RMP
        </p>
        <p className="ml-5 py-5">
          iii. The Firm shall in case of termination of this Agreement return
          all data to the RMP or erase the personal data, as the case may be,
          from DOC-AID, only except where Applicable Laws require the retainment
          of such data.{" "}
        </p>
        <p className="font-bold underline ">
          10. INTELLECTUAL PROPERTY AND OWNERSHIP
        </p>
        <p className="ml-5 pt-5">
          The RMP recognizes and agree that all copyright, registered trademarks
          and other intellectual property rights on all materials or contents
          provided as part of DOC-AID shall belong to the Firm. The User is
          permitted to use the Intellectual Property of the Firm through
          DOC-AID, only in manner expressly authorized. No use of these may be
          made without the prior written authorization of the Firm. All the
          names, trade names, service names, tag lines, or logotype
          distinguished in form, text, or otherwise from surrounding text (e.g.,
          all capital letters), (collectively, “Marks”) are trademarks owned by
          the Firm, unless otherwise noted. The Marks on DOC-AID are variously
          protected by the laws of India. Use of any of these Marks may not be
          made without the prior written consent of the Firm, except for the
          sole purpose of identifying the services of DOC-AID. The RMP
          acknowledges and agrees that the material and content shown at DOC-AID
          is made available only for the personal non-commercial use by the RMP
          and that the RMP may download such material and content only for the
          purpose of using DOC-AID. The RMP further acknowledges that any other
          use of the material and content of DOC-AID is strictly prohibited and
          the RMP agrees not to (and agree not to assist or facilitate any third
          party to) copy, reproduce, transmit, publish, display, distribute,
          commercially exploit or create derivative works of such material and
          content.
        </p>
        <p className="font-bold underline py-5">11. LIMITED LIABILITY CLAUSE</p>
        <p className="ml-5">
          It is agreed and understood by the RMP that to the extent permitted by
          applicable law, the Firm or its affiliates shall not be liable to the
          RMP or Data Principal for any special, indirect, incidental,
          consequential, punitive, reliance, or exemplary damages arising out of
          or relating to this Agreement or the use of the RMP of DOC-AID. The
          Firm shall not be liable for any modification or changes done to the
          data uploaded, stored, or processed by DOC-AID. The Application does
          not have the right to modify or alter any data stored on it. It is the
          obligation of the RMP to ensure that its Account is protected by a
          strong password and to maintain the confidentiality of the Login
          Credentials of its Account. There shall be no liability of DOC AID or
          the Firm for any breach of confidentiality of such or the Applicable
          Law, occurred due to the failure or negligence of the RMP to keep such
          information confidential.{" "}
        </p>
        <p className="py-5 font-bold underline">
          {" "}
          12. DISPUTE RESOLUTION AND GOVERNING LAW{" "}
        </p>
        <p className="ml-5">
          {" "}
          Any dispute, controversy or claim arising out of or relating to this
          Agreement or other document or the validity, interpretation, breach or
          termination thereof (‘Dispute'), including claims seeking redress or
          asserting rights under applicable law, shall, be resolved and finally
          settled in accordance with the provisions of the Arbitration and
          Conciliation Act, 1996 as may be amended from time to time or its
          re-enactment (the "Arbitration Act"). The arbitral tribunal shall be
          composed of one arbitrator to be appointed by both the Parties jointly
          and such Arbitration shall take place in Patna, Bihar
        </p>
        <p className="py-5 ml-5">
          This Agreement shall be governed by and construed in accordance with
          the laws of India, and the competent courts in Patna shall have
          jurisdiction in relation thereto.
        </p>
        <p className="font-bold underline py-5">13. INDEMNITY </p>
        <p className="ml-5">
          If any Party has incurred any damages, loss or expenses caused by
          breach of any of the other Parties’ own representations and warranties
          or breach of its own obligations under this Agreement (including
          reasonable legal expenses; the “Damages”), the party in breach shall
          respectively compensate or indemnify the other party for such Damages
          to the extent that it is legally responsible for causing such Damages.
        </p>
        <p className="font-bold underline py-5">14. NO PARTNERSHIP</p>
        <p className="ml-5">
          The Parties to this agreement are not in partnership with each other
          and there is no relationship of principal and agent between them. None
          of the Parties shall act or describe itself as the agent of the other
          party, nor shall it make or represent that it has authority to make or
          represent that it has the authority to make any commitment on other
          Party’s behalf{" "}
        </p>
        <p className="font-bold underline py-5">15. Waiver</p>
        <p className="ml-5">
          No relaxation, forbearance, delay or indulgence by either Party in
          enforcing any of the terms and conditions of this Agreement or the
          granting of time by either Party to the other shall prejudice, affect
          or restrict the rights and powers of that Party, nor shall any waiver
          by either Party of any breach of this Agreement operate as a waiver of
          or in relation to any subsequent or any continuing breach of it.{" "}
        </p>
        <p className="py-5 font-bold underline">16. VARIATION</p>
        <p className="ml-5">
          his Agreement may only be amended by a document in writing duly signed
          both the Parties
        </p>
        <p className="py-5 font-bold underline">17. ENTIRE AGREEMENT</p>
        <p className="ml-5">
          This Agreement and any present and further Schedules and Annexures
          referred to in it, constitute the whole agreement between the Parties
          and supersedes all previous arrangements, understandings and
          agreements between them, whether oral or written, relating to their
          subject matter
        </p>
        <p className="py-5 font-bold underline">18. ASSIGNMENT </p>
        <p className="ml-5">
          Either Party shall not assign, transfer any rights or obligations,
          charge or encumber the whole or any part of this Agreement or its
          obligations hereunder except with the prior written approval of the
          other Party
        </p>
        <p className="py-5 font-bold underline">19. SEVERANCE</p>
        <p classname="ml-5">
          If any provision of this Agreement (or part of a provision) is found
          by any court or administrative body of competent jurisdiction to be
          invalid, unenforceable or illegal, the other provisions shall remain
          in force. If any invalid, unenforceable or illegal provision would be
          valid, enforceable or legal if some part of it were deleted or
          modified, the provision shall apply with whatever modification is
          necessary to give effect to the commercial intention of the Parties.{" "}
        </p>
        <p className="py-5 font-bold underline">20. SURVIVAL</p>
        <p className="ml-5">
          The obligations of the Parties contained in this Agreement herein
          shall survive the termination of this Agreement. In addition, the
          termination of this Agreement shall not affect any of the rights or
          obligations of either Party arising prior to or at the time of the
          termination of this Agreement or which may arise by any event causing
          the termination of this Agreement
        </p>
        <p className="py-5 font-bold underline">21. NOTICE</p>
        <p className="ml-5">
          Any notice provided for in this Agreement shall be in writing and
          shall be (i) first transmitted by facsimile transmission, and then
          confirmed by postage, prepaid registered post with acknowledgement due
          or by internationally recognised courier service or (ii) sent by
          postage, prepaid registered post with acknowledgement due or by
          internationally recognised courier service.
        </p>
        <p className="ml-5 py-5">The addresses for service of notice are</p>
        <p className="ml-7">(a) Reshita </p>
        <p className="ml-7 py-5">Address: Patna, Bihar</p>
        <p className="ml-7 pb-5">For the attention of: [●]</p>
        {/* rmp */}
        <p classname="ml-7 py-5 pb-5">(b) RMP {collectDoctorData.firstName} {collectDoctorData.lastName}</p>
       
        <p>
          21.1 All notices shall be deemed to have been validly given on (i) the
          Business Day immediately after the date of transmission with confirmed
          answer back, if transmitted by facsimile, or (ii) the Business Day
          immediately after the date of confirmation of transmission recorded on
          the sender’s computer in case of email transmission, or (iii) in case
          (i) and (ii) do not apply, the expiry of seven (7) Business Days after
          posting, if sent by post.
        </p>
        <p className="py-5">
          21.2 Either Party may, from time to time, change its address or
          representative for receipt of notices provided for in this Agreement
          by giving to all the other Party not less than ten (10) Business Days
          prior written notice
        </p>
       
        </div>

        <form onSubmit={handleSubmit} className="mt-8">
          <input
            type="checkbox"
            id="terms"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="terms" className="italic font-bold">
            I have read and understood the above agreement.
          </label>
          <div>
            <label
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-5"
              htmlFor="file_input"
            >
              Upload Signature
            </label>
            <input
              disabled={!isChecked}
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              aria-describedby="file_input_help"
              id="file_input"
              type="file"
            />
            <button
              type="submit"
              className={`mt-4 px-4 py-2 bg-gray-600 text-white rounded ${
                !isChecked ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={!isChecked}
            >
              Proceed to Pay & Print
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Term;
