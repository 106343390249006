// import apiServices from "../../Service/apiService";
export function getStartAndEndDateOfMonth() {
  // const token = sessionStorage.getItem("token");
  try {
    const currentDate = new Date();
    // extract current year and month
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    const startDateOfMonth = new Date(year, month - 1, 1);
    const endDateOfMonth = new Date(year, month - 1, lastDayOfMonth);
    return {
      startDateOfMonth: startDateOfMonth,
      endDateOfMonth: endDateOfMonth
    };
  } catch (error) {
    console.error("Error fetching in Amount Data...", error);
    throw error;
  }
}

export function formatDateToDDMMYY(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year

  return `20${year}-${month}-${day}`;
}
export function formatDateToDDMMYYEPRESCRPITON(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year

  return `${day}-${month}-20${year}`;
}

export function getWeekday(dateString)  {
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const date = new Date(dateString);
  return daysOfWeek[date.getDay()];
};

// Helper function to format time in AM/PM
export function formatTime (date) {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutesStr} ${ampm}`;
};

//ampm to 24hour time
export function convertTo24Hour(time12h){
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours.toString().padStart(2, '0')}:${minutes}`;
};

const currentDate = new Date();
export const formattedDate = formatDateToDDMMYY(currentDate);

const { startDateOfMonth, endDateOfMonth } = getStartAndEndDateOfMonth();
