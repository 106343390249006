import apiServices from "../../../Service/apiService";

export async function fetchPatientCount(startDate, endDate) {
  const token = sessionStorage.getItem("token");

  try {
    const response = await apiServices.get(
      "authentication",
      "/clinic/totalAppointOfDoctor",
      {
        Authorization: " Bearer " + token,
      },
      { startDate, endDate }
    );

    return response;
  } catch (error) {
    if (error.response.data.statusCode === 404) {
      console.error("error", error);
    }
  }
}
