export const useOnCropCancel=(toggleState,setState)=>{
    const onCropComplete = (croppedAreaPixels) => {
        toggleState("croppedArea", croppedAreaPixels, setState)
      };
    return {onCropComplete}
}
export const useHandleCancel=(toggleState,setState,imgAfterCrop, onCropCancel)=>{
    const handleCancel = () => {
        if (imgAfterCrop === "") {
          onCropCancel();
        } else {
          toggleState("imgAfterCrop", "", setState)
        }
      };
    return {handleCancel}
}
export const useHandleSave=(toggleState,setState,imgAfterCrop, croppedArea, onCropDone,onCropCancel,image)=>{
    const handleSave = () => {
        if (imgAfterCrop !== "") {
          onCropDone(croppedArea);
          onCropCancel();
        } else {
          onCropDones(croppedArea,toggleState,setState,image);
        }
      }
    return {handleSave}
}

export const onCropDones = (imgCroppedArea,toggleState,setState,image) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );
      const dataURL = canvasEle.toDataURL("image/jpeg");
      toggleState("imgAfterCrop", dataURL, setState)
    };
  };