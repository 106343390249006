import { renderOpeningDays } from "./clinic_function/main_clinic_function";

const ClinicCard = ({ clinic, onEdit, onDelete, onCheckAppointments }) => {
    return (
      <div className="max-w-md bg-white border-gray-100 border-2 rounded-xl overflow-hidden shadow-lg mt-4">
        <div className="px-4 pb-12">
          <div className="py-2 my-1">
            <div className="text-gray-700 flex justify-between items-center">
              <h3
                className="font-bold text-xl mb-1 montserrat"
                style={{ color: "rgb(251,146,31)" }}
              >
                {clinic.clinicName}
              </h3>
              <span
                className="text-black cursor-pointer"
                onClick={() => onDelete(clinic.id)}
              >
                <svg
                  className="w-6 h-6 text-red-500 fill-current"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9 2a1 1 0 011 1v1h6a1 1 0 110 2h-1v11a2 2 0 01-2 2H7a2 2 0 01-2-2V6H4a1 1 0 110-2h6V3a1 1 0 011-1zM7 6v11h6V6H7zm2-3v1h2V3H9z" />
                </svg>
              </span>
            </div>
            <div className="flex items-center">
              <svg
                className="h-5 w-5 text-gray-600 mr-1"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M5.64 16.36a9 9 0 1 1 12.72 0l-5.65 5.66a1 1 0 0 1-1.42 0l-5.65-5.66zm11.31-1.41a7 7 0 1 0-9.9 0L12 19.9l4.95-4.95zM12 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
              </svg>
              <h3 className="font-semibold text-sm">{clinic.location}</h3>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold">Visiting New Patient Fees</p>
              <span>₹ {clinic.clinicNewFees}</span>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold">Visiting Old Patient Fees</p>
              <span>₹ {clinic.clinicOldFees}</span>
            </div>
            <div>
              <p>Compounder: {clinic.incharge}</p>
              <p>Phone No: +91 {clinic.clinicContact}</p>
            </div>
            <div className="flex justify-between">
              <p>
                Time: {clinic.startTime.substring(0, 5)} -{" "}
                {clinic.endTime.substring(0, 5)}
              </p>
              <div>{clinic.days ? renderOpeningDays(clinic.days) : "No opening days"}</div>
            </div>
          </div>
          <div className="flex gap-2">
            <button
              className="rounded-full bg-gray-200 px-4 py-2 font-semibold"
              onClick={() => onEdit(clinic)}
            >
              Edit
            </button>
            <button
              className="flex-1 rounded-full bg-teal-600 text-white px-12 py-2 font-bold"
              onClick={() => onCheckAppointments(clinic.id)}
            >
              Check Appointments
            </button>
          </div>
        </div>
      </div>
    );
  }


export default ClinicCard;