import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import apiServices from "../../Service/apiService";
const LandingCreationPage = () => {
  const [getCredential, setGetCredentials] = useState([]);
  useEffect(() => {
    const getCredentialToken = async () => {
      try {
        const response = await apiServices.post(
          "abdmAuthentication",
          "/gateway/v0.5/sessions",
          {
            "Content-Type": "application/json",
          },
          null,
          {}
        );
        if (response) {
          setGetCredentials(response);
        }
      } catch (error) {
        console.error("error is coming from api", error);
      }
    };
    getCredentialToken();
  }, []);

  console.log("getToken....", getCredential);

  return (
    <>
      <div class="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
        <div class="text-center p-10">
          <h1 class="font-bold text-4xl mb-4">Create ABHA Number</h1>
          <h2 class="text-3xl">
            Please choose one of the below options to start with the creation of
            your ABHA
          </h2>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2  sm:grid-cols-2 gap-10  lg:gap-2">
          <div class="max-w-xs mx-auto p-5 border  rounded text-center text-gray-500 transition-transform duration-300 hover:shadow-md border-500">
            <Link to="/register/aadhaar">
              <img
                class="w-auto h-auto rounded-lg mx-auto"
                src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/aadhar.png"
                alt="Aadhar Card"
              />
            </Link>
            <div class="text-sm mt-5">
              <p class="mt-2 text-md font-semibold text-blue-600">
                Create your ABHA number using
                <br className="text-red-500" />
                Aadhar
              </p>
            </div>
            <div class="flex mt-4 justify-center"></div>
          </div>
          <div class="max-w-xs mx-auto p-5 border  rounded text-center text-gray-500 transition-transform duration-300 hover:shadow-md border-500">
            <Link to="/register/aadhaar">
              <img
                class="w-auto h-auto rounded-lg mx-auto"
                src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/aadhar.png"
                alt="Aadhar Card"
              />
            </Link>
            <div class="text-sm mt-5">
              <p class="mt-2 text-md font-semibold text-blue-600">
                Create your ABHA number using
                <br className="text-red-500" />
                Aadhar
              </p>
            </div>
            <div class="flex mt-4 justify-center"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingCreationPage;
