import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../../component/../App.css";
import useData from "../../redux/customHook/useData";


const MonthCalendar = () => {
  const { data } = useData();
  let events = []
  if (data) {
    events = data.docIntr
  }

const [currentMonth, setCurrentMonth] = useState(new Date(2024, 11, 1)); // December 2024

const handlePrevMonth = () => {
  setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() - 1)));
};

const handleNextMonth = () => {
  setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() + 1)));
};
const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
const firstDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();

const generateCalendarDays = () => {
  const days = [];
  for (let i = 0; i < firstDay; i++) {
    days.push(null); // Empty slots for alignment
  }
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i));
  }
  return days;
};

const days = generateCalendarDays();
const isEventDay = (date) => {
  const normalizedDate = new Date(date.toDateString()); // Normalize input date
  return events.some((event) => {
    const startDate = new Date(event.stDate);
    const endDate = new Date(event.endDate);

    // Normalize event dates to remove time
    const normalizedStartDate = new Date(startDate.toDateString());
    const normalizedEndDate = new Date(endDate.toDateString());

    return normalizedStartDate <= normalizedDate && normalizedDate <= normalizedEndDate;
  });
};

  return (
    <div className="border border-1 border-gray-300 rounded-xl">
     <CalendarHeader
        currentMonth={currentMonth}
        onPrevMonth={handlePrevMonth}
        onNextMonth={handleNextMonth}
      />
      <div className="grid grid-cols-7 gap-1 p-4">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div key={day} className="text-center font-medium text-gray-600">
            {day}
          </div>
        ))}
        {days.map((day, index) => (
          <div
            key={index}
            className={`h-10 flex text-sm justify-center items-center rounded-full ${
              day
                ? isEventDay(day)
                  ? 'bg-teal-500 text-white'
                  : 'bg-gray-100'
                : ''
            }`}
          >
            {day ? day.getDate() : ''}
          </div>
        ))}
      </div>
    </div>
  );
};

const CalendarHeader = ({ currentMonth, onPrevMonth, onNextMonth }) => {
  const monthYear = currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' });

  return (
    <div className="flex justify-between items-center rounded-xl bg-gray-300 text-black mt-2 mx-2 px-4 py-2">
      <button
    type="button"
    onClick={onPrevMonth}
    className="w-8 h-8 flex items-center justify-center rounded-full border-2 border-gray-900 text-black hover:bg-gray-200"
  >
     <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
    </svg>
  </button>
      <div className="text-lg font-semibold">{monthYear}</div>
      <button
    type="button"
    onClick={onNextMonth}
    className="w-8 h-8 flex items-center justify-center rounded-full border-2 border-gray-900 text-black hover:bg-gray-200"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
    </svg>
  </button>
    </div>
  );
};

export default MonthCalendar;
