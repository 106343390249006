import { createSlice } from "@reduxjs/toolkit";
const dataSlice = createSlice({
  name: "data",
  initialState: {
    loading: false,
    data: null,
    error: null,
    isDataFetched: false, //Add flag to indicate data availablity
  },
  reducers: {
    fetchDataStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchDataSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
      state.isDataFetched = true; // Set flag to true when data is fetched
    },
    fetchDataFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addClinicSucess(state, action) {
      state.data.clinicDtos.push(action.payload); // Assuming payload contains data of the newly added clinic
    },
    updateClinicSuccess(state, action) {
      // find the index of the clinic with the matching id
      const clinicIndex = state.data.clinicDtos.findIndex(
        (clinic) => clinic.id === action.payload.id
      );
      // if clinic exists update its details
      if (clinicIndex !== -1) {
        //  Create a new array with the updated clinic
        state.data.clinicDtos = [
          ...state.data.clinicDtos.slice(0, clinicIndex),
          action.payload, //updated clinic
          ...state.data.clinicDtos.slice(clinicIndex + 1), //element after the updated clinic
        ];
      }
      console.log(state.data.clinicDtos)
    },
    deleteClinicSuccess(state, action) {
      // find the index of the clinic with the matching id
      console.log("id",action.payload);
      const clinicIndex = state.data.clinicDtos.findIndex(
        (clinic) => clinic.id === action.payload
      );
      // if clinic exists update its details
      if (clinicIndex !== -1) {
        //  Create a new array with the updated clinic
        state.data.clinicDtos = [
          ...state.data.clinicDtos.slice(0, clinicIndex),
          ...state.data.clinicDtos.slice(clinicIndex + 1), //element after the updated clinic
        ];
      }
      console.log(state.data.clinicDtos)
    },
    addSchedule(state, action) {
      state.data.docIntr.push(action.payload); // Assuming payload contains data of the newly added clinic
    },
    updateScheduleSuccess(state, action) {
      // find the index of the schedule with the matching id
      const ScheduleIndex = state.data.docIntr.findIndex(
        (docIntr) => docIntr.id === action.payload.id
      );
      // if schedule exists update its details

      if (ScheduleIndex !== -1) {
        //  Create a new array with the updated schedule
        state.data.docIntr = [
          ...state.data.docIntr.slice(0, ScheduleIndex),
          action.payload, //updated schedule
          ...state.data.docIntr.slice(ScheduleIndex + 1), //element after the updated schedule
        ];
      }
    },
    deleteSchedule(state, action) {
    
      // Find the index of the schedule with the matching id
      const scheduleIndex = state.data.docIntr.findIndex(
        (docIntr) => docIntr.id === action.payload.id
      );
    
      // If schedule exists, delete its details
      if (scheduleIndex !== -1) {
        // Create a new array without the deleted schedule
        state.data.docIntr = [
          ...state.data.docIntr.slice(0, scheduleIndex),
          ...state.data.docIntr.slice(scheduleIndex + 1),
        ];
      }
    }
    
  },
});
export const {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFailure,
  addClinicSucess,
  addSchedule,
  updateClinicSuccess,
  deleteClinicSuccess,
  updateScheduleSuccess,
  deleteSchedule
} = dataSlice.actions;
export default dataSlice.reducer;
