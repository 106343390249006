import React, { useEffect, useState } from "react";
import useData from "../../redux/customHook/useData";
import apiServices from "../../../Service/apiService";

const CalendarInput = ({ selectedDate, setSelectedDate }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [highlightAmounts, setHighlightAmounts] = useState("");
  const { data } = useData();

  useEffect(() => {
    if (data?.id) {
      handleClinicModal();
    }
  }, []);

  const handleClinicModal = async () => {
    const year = currentMonth.getFullYear();
    const firstDayOfMonth = new Date(year - 1, 0, 1)
      .toISOString()
      .split("T")[0];
    const lastDayOfMonth = new Date(year + 1, 12, 0)
      .toISOString()
      .split("T")[0];
    try {
      const response = await apiServices.get(
        "authentication",
        "/clinic/appointmentsCountByDate",
        null,
        {
          doctorId: data.id,
          startDate: firstDayOfMonth, // i want first day of that month
          endDate: lastDayOfMonth, // i want last day of that month
        },
        null
      );
      if (response.statusCode === 200) {
        setHighlightAmounts(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getDayOfWeek = (year, month, day) => {
    return new Date(year, month, day).getDay();
  };

  const getCalendarDays = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfWeek = getDayOfWeek(year, month, 1);
    const calendarDays = [];

    for (let i = 0; i < firstDayOfWeek; i++) {
      calendarDays.push(null);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      calendarDays.push(day);
    }
    return calendarDays;
  };

  const handleDateClick = (day) => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const date = new Date(year, month, day); // Create a new Date object
    setSelectedDate(date);
    setShowCalendar(false);
  };

  const handlePrevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)
    );
  };

  const renderCalendar = () => {
    if (!showCalendar) return null;

    const calendarDays = getCalendarDays();

    return (
      <div
        id="login-popup"
        tabIndex="-1"
        className="overflow-x-hidden fixed top-1 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="relative w-full max-w-md h-full md:h-auto">
      <div className="absolute mt-2 bg-white border border-gray-300 rounded-md shadow-lg p-4 w-full mx-2">
        <div className="flex justify-between">
          <button
            onClick={handlePrevMonth}
            className="text-gray-600 hover:text-gray-800"
          >
            Prev
          </button>
          <h2 className="font-bold text-lg">
            {months[currentMonth.getMonth()]} {currentMonth.getFullYear()}
          </h2>
          <button
            onClick={handleNextMonth}
            className="text-gray-600 hover:text-gray-800"
          >
            Next
          </button>
        </div>
        <div className="grid grid-cols-7 text-center mt-2">
          {daysOfWeek.map((day, index) => (
            <div key={index} className="text-lg font-semibold text-gray-700">
              {day}
            </div>
          ))}
          {calendarDays.map((day, index) => (
            <div key={index} className="grid grid-rows-2">
              <div
                className={`p-1 cursor-pointer text-sm ${
                  selectedDate && day === selectedDate.getDate()
                    ? "bg-blue-500 rounded-full text-white"
                    : ""
                }`}
                onClick={() => handleDateClick(day)}
              >
                {day}
              </div>
              <div className="text-xs text-teal-500">
                {day !== null &&
                  highlightAmounts[
                    `${currentMonth.getFullYear()}-${(
                      currentMonth.getMonth() + 1
                    )
                      .toString()
                      .padStart(2, "0")}-${day.toString().padStart(2, "0")}`
                  ]}
              </div>
            </div>
          ))}
        </div>
      </div></div></div>
    );
  };

  return (
    <div className="relative mx-auto max-w-md w-full">
      <div class="col-span-2 sm:col-span-1">
        <label
          for="card-number"
          class="block text-sm font-medium text-gray-700 mb-2"
        >
          Schedule Date <span className="text-red-600">*</span>
        </label>
        <input
          class="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
          id="service"
          name="appointmentDate"
          placeholder="Choose a date..."
          onClick={() => setShowCalendar(true)}
          value={selectedDate ? selectedDate.toLocaleDateString() : ""}
        />
      </div>
      {renderCalendar()}
    </div>
  );
};

export default CalendarInput;
