
import {
  fetchMonthFailure,
  fetchMonthStart,
  fetchMonthSuccess,
  fetchVisitFailure,
  fetchVisitStart,
  fetchVisitSuccess,
} from "./patientSlice";
import { fetchPatientCount } from "../../api/fetchPatientVisit";

// Thunk action for fetching clinic patient counts
export const fetchClinicPatientCounts = (startDate, endDate) => async (dispatch, getState) => {
  const { patientVisit } = getState().patient;

  if (!patientVisit) {
    dispatch(fetchVisitStart());
  }

  try {
    const response = await fetchPatientCount(startDate, endDate);

    if (response.statusCode === 200) {
      dispatch(fetchVisitSuccess(response.data));
    } else {
      dispatch(fetchVisitFailure(response.message || "Unknown error occurred"));
    }
  } catch (error) {
    console.error("API Error:", error.message); // Log the API error
    dispatch(fetchVisitFailure(error.message));
  }
};

// Thunk action for fetching monthly patient counts
export const fetchMonthPatientCounts = (startDate, endDate) => async (dispatch, getState) => {
  const { monthVisit } = getState().patient;

  if (!monthVisit) {
    dispatch(fetchMonthStart());
  }

  try {
    const response = await fetchPatientCount(startDate, endDate);

    if (response.statusCode === 200) {
      dispatch(fetchMonthSuccess(response.data));
    } else {
      dispatch(fetchMonthFailure(response.message || "Unknown error occurred"));
    }
  } catch (error) {
    console.error("API Error:", error.message); // Log the API error
    dispatch(fetchMonthFailure(error.message));
  }
};
