import React, { useEffect, useState } from "react";
import NavbarWrapper from "../../../../widgets/navbarWrapper";
import { useParams, useLocation } from "react-router-dom";
import Axios from "axios";
import UploadPrescription from "./uploadPrescription";
import Spinner from "../../loading/spinner";

const PriscriptionDocument = () => {
  const { id } = useParams();
  const location = useLocation();
  const { imagePrescription } = location.state || {};

  const [state, setState] = useState({
    loadingSpinner: false,
    selectedPrescription: null,
    showUpload: false
  });

  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [showPriscriptions, setShowPriscriptions] = useState([]);
 
  console.log("pat", showPriscriptions);
  useEffect(() => {
    if (imagePrescription) {
      setShowPriscriptions((prevState) => [...prevState, imagePrescription]);
    }
  }, [imagePrescription]);
  console.log("after", showPriscriptions);
  useEffect(() => {
    const getProfilePhoto = async () => {
      toggleState("loadingSpinner", true);
      try {
        const baseUrl = process.env.REACT_APP_REGISTRATION_BASE_URL;
        const response = await Axios(`${baseUrl}/prescriptionUpload/getImage`, {
          params: {
            contact: id,
          },
        });

        if (response && response.data.statusCode === 200) {
          setShowPriscriptions(response.data.data);
        }
      } catch (error) {
        if (error.response.data.statusCode === 404) {
          setShowPriscriptions([]);
        }
      } finally {
        toggleState("loadingSpinner", false);
      }
    };
    getProfilePhoto();
  }, [id]);

  const updatePrescription = (newPrescription) => {
    setShowPriscriptions([...showPriscriptions, newPrescription]);
  };

  const handleShowUpload = () => {
    toggleState("showUpload", true);
  };

  const handleRemovePrescription = () => {
    toggleState("selectedPrescription", null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        state.selectedPrescription &&
        event.target.nodeName !== "IMG" &&
        event.target.className !== "max-h-full max-w-full"
      ) {
        toggleState("selectedPrescription", null);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [state.selectedPrescription]);
  function isValidBase64Image(base64String) {
    // Create a new Image element and set its src to the base64 string
    const img = new Image();
    img.src = `data:image/jpeg;base64,${base64String}`; // Assuming JPEG format
    // Return true if the width and height of the image are greater than 0
    return img.width > 0 && img.height > 0;
  }
  return (
    <>
      <NavbarWrapper showNavbar={true} />
      <div className="flex flex-wrap items-center pt-0 md:pt-24 justify-between max-w-screen-xl px-4 py-2.5 mx-auto">
        <div className="flex items-center lg:order-2">
          <button
            onClick={handleShowUpload}
            className="text-white font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 focus:outline-none dark:focus:ring-purple-800 dark:hover:text-black"
            style={{ backgroundColor: "rgb(251,146,31)" }}
          >
            Add Prescription
          </button>
        </div>
        <div
          className="items-center justify-between w-full lg:flex lg:w-auto lg:order-1"
          id="mobile-menu-2"
        ></div>
      </div>

      <div className="flex flex-wrap justify-center">
        <div className="w-1/3 p-4">
          {imagePrescription && <img src={imagePrescription} alt="ekek" />}
        </div>
        {showPriscriptions.length > 0 ? (
          showPriscriptions
            ?.slice()
            .reverse()
            .map((prescription, index) => (
              <div key={index} className="w-1/3 p-4">
                {isValidBase64Image(showPriscriptions[index]) ? (
                  <img
                    src={`data:image/jpeg;base64,${prescription}`}
                    alt={`Prescription ${index + 1}`}
                    style={{ maxWidth: "100%", cursor: "pointer" }}
                    onClick={() => toggleState("selectedPrescription",prescription)}
                  />
                ) : (
                  <div className="flex justify-center items-center h-screen overflow-hidden">
                    <iframe
                      src={`data:application/pdf;base64,${prescription}#toolbar=0&navpanes=0&scrollbar=0`}
                      type="application/pdf"
                      frameBorder="0"
                      style={{
                        width: "100vh",
                        height: "100vh",
                        cursor: "pointer",
                      }}
                      onClick={() => toggleState("selectedPrescription", prescription)}
                      title="sjsj"
                    ></iframe>
                  </div>
                )}
              </div>
            ))
        ) : (
          <div className="text-center my-8 text-gray-500 montserrat">
            No prescriptions found.
          </div>
        )}
      </div>

      {state.selectedPrescription && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-80 z-50">
          {isValidBase64Image(state.selectedPrescription) ? (
            <img
              src={`data:image/jpeg;base64,${state.selectedPrescription}`}
              alt="Selected Prescription"
              className="max-h-full max-w-full"
              onClick={handleRemovePrescription}
            />
          ) : (
            <embed
              src={`data:application/pdf;base64,${state.selectedPrescription}#toolbar=0&navpanes=0&scrollbar=0`}
              type="application/pdf"
              style={{ width: "100vh", height: "100vh", cursor: "pointer" }}
              onClick={handleRemovePrescription}
            />
          )}
        </div>
      )}
      {state.showUpload && !state.selectedPrescription && (
        <UploadPrescription
          reset={() => toggleState("showUpload", false)}
          getNewPrescription={updatePrescription}
        />
      )}
      {state.loadingSpinner && <Spinner />}
    </>
  );
};

export default PriscriptionDocument;
