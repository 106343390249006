import React, { lazy, Suspense } from "react";
import Spinner from "../component/page/loading/spinner"
// lazy load the Navbar component;
const Navbar = lazy(() => import("./navbar"));
const NavbarWrapper = ({ children, showNavbar }) => {
  return (
    <Suspense fallback={<div><Spinner/></div>}>
      {showNavbar && <Navbar />}
      {children}
    </Suspense>
  );
};
export default NavbarWrapper;
