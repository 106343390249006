import React, { useState, useEffect } from "react";
import useData from "../../redux/customHook/useData";
import SubscriptionModal from "./subscriptionModal";
import { formatDateToDDMMYY } from "../../specialMethod/getBetweenDate";
import Spinner from "../loading/spinner";
import Invoice from "./invoice";
import { usePaymentHandler } from "./account_function/subscription_function";
import { renderIf, toggleState } from "../../specialMethod/toggleState";

const Subscription = () => {
  const { data } = useData();
  const [state, setState] = useState({
    loadinSpinner: true,
    payment: false,
    invoice: false,
    history: "",
    invoiceDetails: ""
  });

  // Doctor Details information
  useEffect(() => {
    if (data) {
      handlePayment();
    }
  }, [data]);
  const { handlePayment } = usePaymentHandler(data?.id, toggleState, setState);

  const handleCheckoutFunction = () => {
    toggleState("payment", true, setState);
  };

  const showInvoice = (hist) => {
    toggleState("invoice", true, setState);
    toggleState("invoiceDetails", hist, setState);
  };

  return (
    <>
      <div className="flex flex-wrap items-center justify-between  max-w-screen-xl  mx-auto">
        <div className="flex flex-col  ">
          {/* first card */}
          <div className="flex flex-col p-6 transition-all duration-500 bg-white border border-indigo-100 rounded-lg shadow hover:shadow-xl lg:p-5 lg:flex-row lg:space-y-0 lg:space-x-6">
            <div className="flex-1">
              <h5 className="mb-1 text-lg font-medium lg:text-1xl">
                Choose Subscription{" "}
              </h5>
              <p className="mb-3 text-sm font-bold text-gray-600">
                Your monthly Subscription amount is Rs. 1500/month
              </p>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={handleCheckoutFunction}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* table section */}
        <div className="overflow-x-auto">
          <table className="w-full divide-y border-2 rounded-lg border-gray-400 divide-gray-200">
            <thead className="border-b-2 border-gray-400">
              <tr>
                {["Start Date", "End Date", "COST", "Status", "Invoice"].map((header) => (
                  <th
                    key={header}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            {state.history && (
              <tbody className="bg-white divide-y divide-gray-200">
                {state.history.map((hist) => (
                  <tr key={hist.subscriptionStartDate} className="border rounded-full">
                    {[
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {hist.subscriptionStartDate}
                          </div>
                        </div>
                      </div>,
                      <div className="text-sm text-gray-900">{hist.subscriptionEndDate}</div>,
                      <span className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        ₹ {hist.amount}
                      </span>,
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${hist.subscriptionEndDate < formatDateToDDMMYY(new Date())
                          ? "bg-red-100 text-red-800"
                          : "bg-green-100 text-green-800"
                          }`}
                      >
                        {hist.subscriptionEndDate < formatDateToDDMMYY(new Date())
                          ? "expired"
                          : "Active"}
                      </span>,
                      <button
                        type="button"
                        onClick={() => showInvoice(hist)}
                        className="text-red-800 underline"
                      >
                        View Invoice
                      </button>
                    ].map((content, index) => (
                      <td key={index} className="px-6 py-4 whitespace-nowrap">
                        {content}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
      {renderIf(state.payment, (
        <SubscriptionModal
          value={() => toggleState("payment", false, setState)}
          invoiceDetails={state.invoiceDetails}
          email={data?.email}
        />
      ))}
      {renderIf(state.loadinSpinner, <Spinner />)}
      {renderIf(state.invoice, (
        <Invoice
          reset={() => toggleState("invoice", false, setState)}
          invoiceDetails={state.invoiceDetails}
          email={data?.email}
        />
      ))}
    </>
  );
};

export default Subscription;
