import React, { useEffect, useState } from "react";
import NavbarWrapper from "../../../widgets/navbarWrapper";
import ScheduleCalendar from "./calendarSchedule";
import useData from "../../redux/customHook/useData";
import Spinner from "../loading/spinner";
import useProfilePhoto from "../../redux/customHook/useProfileImage";

const Dashboard = () => {
  const [state, setState] = useState({
    loadingSpinner: true,
    collectDoctorData: [],
    profileImage: "",
  });

  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const { data } = useData();
  const { profilePhotoURL } = useProfilePhoto();

  useEffect(() => {
    if (data) {
      toggleState("collectDoctorData", data || []);
      toggleState("loadingSpinner", false);
    }
  }, [data]);
  useEffect(() => {
    if (profilePhotoURL) {
      toggleState("profileImage", profilePhotoURL);
    }
  }, [profilePhotoURL]);

  return (
    <>
      <NavbarWrapper showNavbar={true} />

      <div className="bg-white border-gray-200 py-4 dark:bg-white">
        {/* doctor name and profile photo */}
        <div className="flex flex-wrap md:mt-20 mt-0 justify-between max-w-screen-xl px-4 mx-auto">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10 mr-8">
              <img
                className="rounded-full object-cover w-full h-full max-w-[128px] max-h-[64px] md:w-[64px] md:h-[64px] "
                src={state.profileImage}
                alt="Profile"
              />
            </div>
            <div className="ml-4">
              <div className="montserrat font-semibold text-gray-900 pt-4">
                Welcome
              </div>
              <div className="text-2xl font-bold text-gray-900 montserrat">
                {state.collectDoctorData.firstName}{" "}
                {state.collectDoctorData.lastName}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* calendar schedule and card section */}
      <div className="bg-white border-gray-200 dark:bg-white">
        <div className="bg-white border-gray-200 dark:bg-white flex flex-wrap items-start justify-between max-w-screen-xl px-4 mx-auto">
          <div className="w-full lg:w-full">
            <ScheduleCalendar />
          </div>
        </div>
      </div>
      {state.loadingSpinner && <Spinner />}
    </>
  );
};

export default Dashboard;
