import apiServices from "../../../../Service/apiService";
import { ALL_SUBSCRIPTION_PAYMENT_HISTORY } from "../account_api/api";

export const usePaymentHandler = (doctorId, toggleState,setState) => {
  const handlePayment = async () => {
    toggleState("loadinSpinner", true  , setState);
    const token = sessionStorage.getItem("token");
    try {
      const response = await apiServices.get(
        "authentication",
        ALL_SUBSCRIPTION_PAYMENT_HISTORY,
        {
          Authorization: " Bearer " + token,
        },
        {
          doctorId,
        },
        null
      );
      if (response) {
        toggleState("history", response.data , setState);
      }
    } catch (error) {
      console.error("Error fetching payment data:", error);
    } finally {
      toggleState("loadinSpinner", false , setState);
    }
  };

  return { handlePayment };
};

