import apiServices from "../../../Service/apiService";

export async function fetchClinicIncome(startDate, endDate) {
  const token = sessionStorage.getItem("token");

  try {
    const response = await apiServices.get(
      "authentication",
      "/home/doctorTotalIncome",
      {
        Authorization: " Bearer " + token,
      },
      { startDate, endDate }
    );
    console.log(response.data)
    return response.data;
  } catch (error) {
    if (error.response.data.statusCode === 404) {
      console.error("error", error);
    }
  }
}
