const initialState = {
  prescriptionPhotoURLs: {}, // Stores { clinicId: imageURL }
  isLoading: false,
  error: null,
};

const PrescriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PRESCRIPTION_PHOTO_REQUEST':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'FETCH_PRESCRIPTION_PHOTO_SUCCESS':
      return {
        ...state,
        prescriptionPhotoURLs: {
          ...state.prescriptionPhotoURLs,
          [action.payload.clinicId]: action.payload.imageURL,
        },
        isLoading: false,
      };
    case 'FETCH_PRESCRIPTION_PHOTO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default PrescriptionReducer;
