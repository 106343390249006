import React, { useEffect, useState } from "react";
import useClinicIncomes from "../../redux/customHook/useClinicIncome";
import usePatientCount from "../../redux/customHook/usePatientCount";
import TodayReport from "./report/todayReport";
import MonthalyReport from "./report/monthalyReport";
import Spinner from "../loading/spinner";
import StatsCard from "./patientCount";
import IncomeStats from "./incomeStats";
import useData from "../../redux/customHook/useData";
import CustomReport from "./customReport";
import { toggleState } from "../../specialMethod/toggleState";
import { usehandleClinicModal, usehandleDayClinicModal } from "./account_function/graph_function";

const PatientTally = () => {
  const [state, setState] = useState({
    loadinSpinner: false,
    tabType: "income",
    dayTabType: "day7Report",
    result: "",
    customReport: false,
    clinic: ""
  });
  const { clinicIncomes, anotherIncome } = useClinicIncomes();
  const { patientVisit, monthVisit } = usePatientCount();
  const { data } = useData();
  useEffect(() => {
    if (data && data?.clinicDtos) {
      toggleState("clinic", data.clinicDtos, setState);
    }
  }, [data]);
  useEffect(() => {
    toggleState("result", [], setState);
    if (state.dayTabType == "YearReport") {
      handleClinicModal();
    } else {
      handleDayClinicModal();
    }
  }, [state.clinic, state.dayTabType])

  const { handleDayClinicModal } = usehandleDayClinicModal(state.clinic, toggleState, setState);
  const { handleClinicModal } = usehandleClinicModal(state.clinic, toggleState, setState);

  useEffect(() => {
    // Check if all data is loaded
    if (
      clinicIncomes &&
      anotherIncome &&
      patientVisit !== null &&
      monthVisit !== null
    ) {
      toggleState("loadinSpinner", false, setState);
    }
  }, [clinicIncomes, anotherIncome, patientVisit, monthVisit]);
  return (
    <>
      <section className="text-gray-600 body-font">
        <div className="container max-w-6xl px-5 py-10 mx-auto">
          <div className="flex flex-wrap -mx-2">
            <div className="px-2 pl-10 w-full md:w-1/4">
              <StatsCard title="Today's Visits" value={patientVisit || 0} />
            </div>
            {clinicIncomes && (
              <div className="pl-10 px-2 w-full md:w-3/4">
                <IncomeStats incomeData={clinicIncomes} />
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="container max-w-6xl px-5 pb-10 mx-auto">
          {anotherIncome && (
            <div className="flex flex-wrap -mx-2">
              <div className="px-2 pl-10 w-full md:w-1/4">
                <StatsCard
                  title="Past 30 Day's Visits"
                  value={monthVisit || 0}
                />
              </div>
              <div className="pl-10 px-2 w-full md:w-3/4">
                <IncomeStats incomeData={anotherIncome} />
              </div>
            </div>
          )}
        </div>
      </section>
      <div className="flex flex-wrap max-w-screen-xl mx-auto">
        <div className="flex flex-col border rounded-lg w-screen h-1/2 px-10 py-8 text-gray-700">
          {/* Row for Centered "1 day" and "30 day" buttons and Right-aligned buttons */}
          <div className="flex justify-between items-center mb-4 w-full">
            {/* Centered "1 day" and "30 day" buttons */}
            <div className="flex justify-center w-full space-x-4">
              <button
                type="button"
                onClick={() => {
                  toggleState("dayTabType", "day7Report", setState);
                }}
                className={`py-2.5 md:pl-60  pl-0 px-5 mb-2 ${state.dayTabType == "day7Report" ? "underline text-red-600" : "text-red-800"} text-sm font-medium  focus:outline-none focus:z-10`}
              >
                7 day
              </button>

              <button
                type="button"
                onClick={() => {
                  toggleState("dayTabType", "YearReport", setState);
                }}
                className={`py-2.5 px-5 pr-5 mb-2  ${state.dayTabType == "YearReport" ? "underline text-red-800" : "text-red-600"} text-sm font-medium `}
              >
                1 Year
              </button>
            </div>

            {/* Right-aligned "Total Income" and "Patient Visits" buttons */}
            <div className="flex md:ml-auto items-center flex-shrink-0 space-x-4">
              <button
                type="button"
                onClick={() => {
                  toggleState("tabType", "income", setState);
                }}
                className={`py-2 px-2 mb-2 text-sm font-medium rounded-lg ${state.tabType === "income"
                  ? "text-white border-red-700 bg-red-700"
                  : "text-black bg-gray-200 border-gray-400"
                  } focus:outline-none border focus:z-10`}
              >
                Total Income
              </button>

              <button
                type="button"
                onClick={() => {
                  toggleState("tabType", "visit", setState);
                }}
                className={`py-2 px-2 mb-2 text-sm font-medium rounded-lg ${state.tabType === "visit"
                  ? "text-white border-red-700 bg-red-700"
                  : "text-black bg-gray-200 border-gray-400"
                  } focus:outline-none border focus:z-10`}
              >
                Patient Visits
              </button>
            </div>
          </div>

          {/* Conditional rendering for reports */}
          {state.tabType === "income" ? (
            <TodayReport result={state.result} />
          ) : (
            <MonthalyReport result={state.result} />
          )}
          {/* Custom Report Button */}
          <div className="flex justify-end mt-4">
            <p
              className="py-2.5 px-5 cursor-pointer mb-10 rounded-md text-sm font-medium text-white bg-red-800 focus:outline-none focus:z-10"
              onClick={() => {
                toggleState("customReport", true, setState);
              }}
            >
              Custom Report
            </p>
          </div>
        </div>
      </div>
      {state.loadinSpinner && <Spinner />}
      {state.customReport && < CustomReport reset={() => { toggleState("customReport", false, setState); }} />}
    </>
  );
};

export default PatientTally;
