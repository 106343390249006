import React, { useState , useRef, useEffect} from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { specializationOption } from "../../specialMethod/prescriptionSpecialization";


const PracticeForm = ({ formData, onPrev, onNext }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  
  const initialValues = {
    doctors: formData.doctors.map((doctor) => ({ ...doctor })),
    specialization: formData.specialization.map((spec) => spec),
    achievements: formData.achievements.map((ach) => ach),
    experience: formData.experience || "",
    licenceNumber: formData.licenceNumber || "",
    research_journal: formData.research_journal.map((journal) => journal),
    citations: formData.citations.map((citation) => citation),
  };
  const validationSchema = Yup.object().shape({
    doctors: Yup.array().of(
      Yup.object().shape({
        degree: Yup.string().required("Degree is required"),
        college: Yup.string().required("College is required"),
        year: Yup.string().required("Year is required"),
      })
    ),
    specialization: Yup.array().of(
      Yup.string()
    ),
    achievements: Yup.array().of(
      Yup.string()
    ),
    experience: Yup.number(),
    licenceNumber: Yup.string(),
    research_journal: Yup.array().of( 
      Yup.string()
    ),
    citations: Yup.array().of(Yup.string()),
  });

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async (values) => {
      formik.setValues(values);
      onNext(values);
    },
  });
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    formik.handleChange(e);
    if (inputValue) {
      const filtered = specializationOption.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredOptions(filtered);
      setShowOptions(true);
    } else {
      setFilteredOptions([]);
      setShowOptions(false);
    }
  };
  const handleOptionClick = (optionValue, index) => {
    const newSpecialization = [...formik.values.specialization]; // Create a copy of the array
    newSpecialization[index] = optionValue; // Update the value at the specified index
    formik.setFieldValue('specialization', newSpecialization); // Update the formik field value
    setShowOptions(false); // Hide the dropdown options
    
  };
  console.log(formik.values.specialization[0])
  const addField = () => {
    formik.setValues({
      ...formik.values,
      doctors: [
        ...formik.values.doctors,
        { degree: "", college: "", year: "" },
      ],
    });
  };
  // Remove field of doctors input section
  const removeField = (index) => {
    const updatedDoctors = formik.values.doctors.filter((_, i) => i !== index);
    formik.setValues({ ...formik.values, doctors: updatedDoctors });
  };
  // Define a reusable function to add a new section
  const addSection = (fieldName, formik) => {
    const fieldValue = formik.values[fieldName];
    const newFieldValue = [...fieldValue, ""];
    formik.setFieldValue(fieldName, newFieldValue);
  };

  // Define a reusable function to remove a section
  const removeSection = (fieldName, index, formik) => {
    const newFieldValue = formik.values[fieldName].filter(
      (_, idx) => idx !== index
    );
    formik.setFieldValue(fieldName, newFieldValue);
  };
  return (
    <>
      <div className="p-2">
        {/* register form for doctor personal details */}
        <div className="h-full bg-white dark:bg-white">
          <div className="mx-auto">
            <div className="flex justify-center md:px-6 pb-6">
              <div className="w-full lg:w-7/12 bg-white dark:bg-white p-5 rounded-lg lg:rounded-l-none">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                  className="px-8 pt-6 pb-8 mb-4 bg-white dark:bg-gray-600 rounded"
                  style={{ backgroundColor: "rgb(202,255,255)" }}
                >
                  {formik.values.doctors.map((doctor, index) => (
                    <div key={index} className="flex sm:flex-row flex-col mb-2">
                      <div className="flex flex-col flex-grow md:mr-4 mb-2">
                        <input
                          type="text"
                          name={`doctors[${index}].degree`}
                          value={doctor.degree}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Degree"
                        />
                        {formik.touched.doctors &&
                          formik.errors.doctors &&
                          formik.errors.doctors[index] &&
                          formik.errors.doctors[index].degree && (
                            <p style={{ color: "red" }}>
                              {formik.errors.doctors[index].degree}
                            </p>
                          )}
                      </div>
                      <div className="flex flex-col flex-grow md:mr-4 mb-2">
                        <input
                          type="text"
                          name={`doctors[${index}].college`}
                          value={doctor.college}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="College"
                        />
                        {formik.touched.doctors &&
                          formik.errors.doctors &&
                          formik.errors.doctors[index] &&
                          formik.errors.doctors[index].college && (
                            <p style={{ color: "red" }}>
                              {formik.errors.doctors[index].college}
                            </p>
                          )}
                      </div>
                      <div className="flex flex-col flex-grow md:mr-4">
                        <input
                          type="text"
                          name={`doctors[${index}].year`}
                          value={doctor.year}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Year"
                        />
                        {formik.touched.doctors &&
                          formik.errors.doctors &&
                          formik.errors.doctors[index] &&
                          formik.errors.doctors[index].year && (
                            <p style={{ color: "red" }}>
                              {formik.errors.doctors[index].year}
                            </p>
                          )}
                      </div>
                      <div className="flex items-center">
                        {formik.values.doctors.length > 1 && (
                          <button
                            type="button"
                            className=" text-black px-2 py-1 rounded-md focus:outline-none focus:shadow-outline"
                            onClick={() => removeField(index)}
                          >
                            X
                          </button>
                        )}
                      </div>
                    </div>
                  ))}

                  {/* add more section for college and degree section */}
                  <div className="flex justify-end">
                    <div className="mb-10 md:mr-2 text-center">
                      <button
                        className="w-full px-1 py-1 font-bold text-gray-500 bg-blue-500 rounded-lg dark:bg-white dark:text-gray-500 focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={() => addField("doctors")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="grid gap-8  mb-6 md:grid-cols-2">
                    {/* Specialization */}
                    <div>
                      {formik.values.specialization.map(
                        (specialization, index) => (
                          <div
                            key={index}
                            className="flex flex-col md:flex-row mb-2"
                          >
                            <div className="flex flex-col flex-grow md:mr-4">
                              {/* specialization */}
                              {/* <input
                                type="text"
                                id={`specialization${index}`}
                                name={`specialization[${index}]`}
                                value={specialization}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Specialization"
                              /> */}
                               {/* <Select
           id={`specialization${index}`}
           name={`specialization[${index}]`}
          options={options}
          value={options.find(option => option.value === formik.values.specialization)}
          onChange={option => formik.setFieldValue(`specialization[${index}]`, option.value)}
          onBlur={formik.handleBlur}
        /> */}
         <input
           id={`specialization${index}`}
           name={`specialization[${index}]`}
          type="text"
          onChange={handleInputChange}
          onBlur={formik.handleBlur}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={formik.values.specialization}
          placeholder="Specialization"
        />
                              {/* Error message if any */}
                              {formik.touched.specialization &&
                                formik.errors.specialization &&
                                formik.errors.specialization[index] && (
                                  <p style={{ color: "red" }}>
                                    {formik.errors.specialization[index]}
                                  </p>
                                )}
                                {showOptions && (
                                 <ul   className="bg-gray-50 border cursor-pointer border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500">
          {filteredOptions.map(option => (
            <li className="bg-gray-50 border cursor-pointer border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" key={option.value} onClick={() => handleOptionClick(option.value,index)}>
              {option.label}
            </li>
          ))}
        </ul>)}
                            </div>
                        
                          </div>
                        )
                      )}
                      <div className="flex justify-end">
                        <div className="mb-4  md:mr-2 sm text-center">
                          <button
                            className="w-full px-1 py-1  font-bold text-gray-500 bg-blue-500 rounded-lg dark:bg-white dark:text-gray-500 focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={() => addSection("specialization", formik)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Achievment */}
                    <div>
                      {formik.values.achievements.map((achievements, index) => (
                        <div
                          key={index}
                          className="flex flex-col md:mr-4 md:flex-row mb-2"
                        >
                          <div className="flex flex-col flex-grow ">
                            <input
                              type="text"
                              id={`achievements${index}`}
                              name={`achievements[${index}]`}
                              value={achievements}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="bg-gray-50 border w-full  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Achievement"
                            />
                            {formik.touched.achievements &&
                              formik.errors.achievements &&
                              formik.errors.achievements[index] && (
                                <p style={{ color: "red" }}>
                                  {formik.errors.achievements[index]}
                                </p>
                              )}
                          </div>
                          <div className="flex items-center">
                            {/* Minus button */}
                            {index > 0 && (
                              <button
                                className="text-black px-2 rounded-md focus:outline-none focus:shadow-outline"
                                onClick={() =>
                                  removeSection("achievements", index, formik)
                                }
                              >
                                X
                              </button>
                            )}
                            {/* Plus button */}
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-end">
                        <div className="mb-4 md:mr-2 text-center">
                          <button
                            className="w-full px-1 py-1 font-bold text-gray-500 bg-blue-500 rounded-lg dark:bg-white dark:text-gray-500 focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={() => addSection("achievements", formik)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* experience */}
                    <div>
                      <input
                        type="number"
                        id="phone"
                        name="experience"
                        value={formik.values.experience}
                        onChange={formik.handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Experience (In Year)"
                      />
                      {formik.touched.experience && formik.errors.experience ? (
                        <p style={{ color: "red" }}>
                          {formik.errors.experience}
                        </p>
                      ) : null}
                    </div>
                    {/* Licence Number */}
                    <div>
                      <input
                        type="text"
                        id="phone"
                        name="licenceNumber"
                        value={formik.values.licenceNumber}
                        onChange={formik.handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Licence Number"
                      />
                      {formik.touched.licenceNumber &&
                      formik.errors.licenceNumber ? (
                        <p style={{ color: "red" }}>
                          {formik.errors.licenceNumber}
                        </p>
                      ) : null}
                    </div>
                    {/* Research Journal */}
                    <div>
                      {formik.values.research_journal.map(
                        (research_journal, index) => (
                          <div
                            className="flex flex-col md:flex-row mb-2"
                            key={index}
                          >
                            <div className="flex flex-col flex-grow ">
                              <input
                                type="text"
                                id={`research_journal${index}`}
                                name={`research_journal[${index}]`}
                                value={research_journal}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Research journal"
                              />
                              {formik.touched.research_journal &&
                                formik.errors.research_journal &&
                                formik.errors.research_journal[index] && (
                                  <p style={{ color: "red" }}>
                                    {formik.errors.research_journal[index]}
                                  </p>
                                )}
                            </div>
                            <div className="flex items-center">
                              {index > 0 && (
                                <button
                                  className="text-black px-2 rounded-md focus:outline-none focus:shadow-outline"
                                  onClick={() =>
                                    removeSection(
                                      "research_journal",
                                      index,
                                      formik
                                    )
                                  }
                                >
                                  X
                                </button>
                              )}
                            </div>
                          </div>
                        )
                      )}
                      {/* Add More button for achievements */}
                      <div className="flex justify-end">
                        <div className="mb-4 text-center">
                          <button
                            className=" ml-64   px-1 py-1 font-bold text-gray-500 bg-blue-500 rounded-lg dark:bg-white dark:text-gray-500 focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={() => {
                              addSection("research_journal", formik);
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* citations */}
                    <div>
                      {formik.values.citations.map((citations, index) => (
                        <div
                          className="flex flex-col md:flex-row mb-2"
                          key={index}
                        >
                          <div className="flex flex-col flex-grow">
                            <input
                              type="text"
                              id={`citations${index}`}
                              name={`citations[${index}]`}
                              value={citations}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Citation"
                            />
                            {formik.touched.citations &&
                              formik.errors.citations &&
                              formik.errors.citations[index] && (
                                <p style={{ color: "red" }}>
                                  {formik.errors.citations[index]}
                                </p>
                              )}
                          </div>
                          <div className="flex items-center">
                            {/* Minus button */}
                            {index > 0 && (
                              <button
                                className="text-black px-2 rounded-md focus:outline-none focus:shadow-outline"
                                onClick={() =>
                                  removeSection("citations", index, formik)
                                }
                              >
                                X
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                      {/* Add More button for achievements */}
                     
                      <div className="flex justify-end">
                        <div className="mb-4 text-center">
                          <button
                            className=" ml-64  px-1 py-1 font-bold text-gray-500 bg-blue-500 rounded-lg dark:bg-white dark:text-gray-500 focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={() => {
                              addSection("citations", formik);
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <div className="mb-4 mr-2 text-center">
                      <button
                        className="w-full px-4 py-2 font-bold text-gray-500 bg-blue-500 rounded-full  dark:bg-white dark:text-gray-500  focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={onPrev}
                      >
                        Previous
                      </button>
                    </div>
                    <div className="mb-4 text-center">
                      <button
                        className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 dark:bg-blue-700 dark:text-white dark:hover:bg-blue-900 focus:outline-none focus:shadow-outline"
                        type="Submit"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PracticeForm;
