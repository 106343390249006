import React, { useState, useEffect, useRef } from "react";
import InvoiceSection from "./invoiceSection";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import apiServices from "../../../Service/apiService";
import docaid from "../../../assets/doc-aid.png";
import { useNavigate } from "react-router-dom";
import { usePaymentHandler } from "./account_function/subscription_function";
import { toggleState } from "../../specialMethod/toggleState";

const InvoiceSendOnboarding = ({ reset, formData,doctorId }) => {
  const [amount, setAmount] = useState(0);
  const [gst, setGst] = useState(0);
  const invoiceRef = useRef(null);
  const navigate = useNavigate();

  const [state, setState] = useState({
    loadinSpinner: true,
    history: [],
    invoiceDetails: null,
  });

  const { handlePayment } = usePaymentHandler(doctorId, toggleState, setState);

  // Fetch payment history when data is available
  useEffect(() => {
    handlePayment();
  }, []);
  

  // Set invoice details when history is updated
  useEffect(() => {
    if (state.history.length > -1) {
      toggleState(
        "invoiceDetails",
        state.history[state.history.length - 1],
        setState
      );
    }
  }, [state.history]);

  // Calculate Amount and GST when invoiceDetails are available
  useEffect(() => {
    if (state.invoiceDetails?.amount) {
      const calculatedAmount = (state.invoiceDetails.amount * 100) / 118;
      setAmount(calculatedAmount.toFixed(2)); // Two decimal places
      setGst(((state.invoiceDetails.amount - calculatedAmount) / 2).toFixed(2));
    }
  }, [state.invoiceDetails]);

  // Send PDF only when invoiceDetails are ready
  useEffect(() => {
    if (state.invoiceDetails) {
      handleSendPDF();
    }
  }, [state.invoiceDetails]);

  // Helper function to fetch image as Base64
  const fetchImageAsBase64 = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error(`Failed to fetch image: ${url}`, error);
      return null;
    }
  };

  // Generate and Send PDF
  const handleSendPDF = async () => {
    if (!invoiceRef.current) return;

    // Hide elements with "hidden-in-pdf" class
    const elementsToHide = document.querySelectorAll(".hidden-in-pdf");
    elementsToHide.forEach((el) => (el.style.display = "none"));

    try {
      // Fetch images as Base64
      const docAidBase64 = await fetchImageAsBase64(docaid);

      // Replace img src dynamically
      document.querySelector("#docAidImg").src = docAidBase64 || docaid;

      // Capture the invoice as an image
      const canvas = await html2canvas(invoiceRef.current, { scale: 1.5 });
      const imgData = canvas.toDataURL("image/jpeg", 0.7);

      // Create PDF
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

      // Convert PDF to Blob and send via API
      const pdfBlob = pdf.output("blob");
      const formDatas = new FormData();
      formDatas.append("file", pdfBlob, "invoice.pdf");

      const response = await apiServices.post(
        "authentication",
        "/email/sendInvoice",
        null,
        { recipientEmail: formData?.email },
        formDatas
      );

      const response2 = await apiServices.post(
        "authentication",
        "/email/sendInvoice",
        null,
        { recipientEmail: "enquiry@doc-aid.in" },
        formDatas
      );

      if (response.ok && response2.ok) {
        navigate("/");
        console.log("PDF sent successfully!");
      } else {
        console.error("Failed to send PDF");
      }
    } catch (error) {
      console.error("Error generating or sending PDF:", error);
    } finally {
      // Restore visibility of hidden elements
      elementsToHide.forEach((el) => (el.style.display = ""));
      navigate("/");
    }
  };

  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="bg-black/50 fixed inset-0 z-50 flex items-center justify-center"
    >
      <div className="relative p-4 w-full max-w-4xl h-full md:h-[99%]">
        <div className="relative bg-white rounded-lg shadow w-full h-[99%] my-2 max-h-screen overflow-y-auto">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5"
            onClick={reset}
            aria-label="Close popup"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="#c6c7c7"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          <div className="p-5" ref={invoiceRef}>
            <footer className="text-gray-600 body-font">
              <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
                <img
                  id="docAidImg"
                  alt="Company Logo"
                  className="w-[120px] xl:w-[170px] lg:w-auto mb-0"
                  src={docaid}
                />
              </div>
            </footer>

            <section className="text-gray-600 body-font">
              <div className="container mx-auto">
                <div className="flex flex-col sm:flex-row items-start mx-auto">
                  <div className="flex-grow text-md font-medium title-font text-gray-900">
                    <div className="px-3 py-2">
                      <div className="text-gray-900 font-bold">From</div>
                      <div className="text-lg font-bold">M/s Reshita</div>
                      <div>Flat no. 33 Mangaldeep Apartment</div>
                      <div>Patliputra Colony, Patna, 800013</div>
                      <div className="font-bold">GST No. - 10KGMPK1521A1Z2</div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 border-0 py-2 px-8 rounded text-lg mt-10 sm:mt-0">
                    <div className="text-gray-900 font-bold">Bill to</div>
                    <div>{`${formData?.firstName} ${formData?.lastName}`}</div>
                    <div>{formData?.email}</div>
                    <div>{formData?.contact}</div>
                  </div>
                </div>
              </div>
            </section>

            <div className="px-5">
              <div className="pt-6">
                <span className="font-bold">Payment Id: </span>
                {state.invoiceDetails?.paymentId}
              </div>
              <div className="py-1">
                <span className="font-bold">Order Id: </span>
                {state.invoiceDetails?.orderId}
              </div>
              <div className="py-1">
                <span className="font-bold">Invoice No: </span>
                RE/24-25/{state.invoiceDetails?.id + 2}
              </div>
              <div>
                <span className="font-bold">Payment Date: </span>
                {state.invoiceDetails?.paymentDate}
              </div>

              <div className="text-xl font-bold text-red-400 mt-4">
                Your Account Summary
              </div>
              <div className="text-md text-black font-semibold mt-2">
                Subscription Charges
              </div>
              <div className="bg-gray-100 my-5">
                <InvoiceSection label="Previous Balance" value="0.00" />
                <InvoiceSection label="Amount" value={amount} />
                <InvoiceSection label="9% SGST" value={gst} />
                <InvoiceSection label="9% CGST" value={gst} />
                <InvoiceSection label="Total Amount" value={state.invoiceDetails?.amount?.toFixed(2)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceSendOnboarding;
