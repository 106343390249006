import React from 'react'

const IncomeStats = ({ incomeData }) => (
    <div className="border-gray-300 rounded-xl border max-w-full py-4 px-4 flex flex-wrap relative overflow-hidden">
      <div className="absolute top-0 left-0 h-full bg-red-400" style={{ width: '5%' }}></div>
      <div className="relative z-10 p-4 pl-10 sm:w-1/3 w-full">
        <h2 className="title-font font-medium sm:text-4xl text-4xl text-red-400"><span>₹ {incomeData.totalAmount}</span></h2>
        <p className="leading-relaxed sm:text-xl">Total Income</p>
      </div>
      <div className="border-l-2 border-gray-400 solid h-[80px]"></div>
      <div className="p-4 sm:w-1/4 w-full">
        <h2 className="title-font font-medium sm:text-4xl text-4xl text-red-400"><span>₹ {incomeData.upiAmount}</span></h2>
        <p className="leading-relaxed sm:text-xl">UPI</p>
      </div>
      <div className="border-l-2 border-gray-400 solid h-[80px]"></div>
      <div className="p-4 sm:w-1/3 w-full">
        <h2 className="title-font font-medium sm:text-4xl text-4xl text-red-400"><span>₹ {incomeData.cashAmount}</span></h2>
        <p className="leading-relaxed sm:text-xl">Cash</p>
      </div>
    </div>
  );

export default IncomeStats