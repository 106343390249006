import React from "react";
import eyeimage from "../../../../assets/eyeimage.png";
import eye1 from "../../../../assets/eye1.png";

const EyePrescriptionSection = ({ onDataChange }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onDataChange({ [name]: value });
  };
  return (
    <>
      <div className="w-1/3 ">
        <div className="border-2 border-gray-300  px-3 py-1 rounded-xl mr-1 mb-2">
          <p className="text-xl text-black py-2"> Examination Details</p>
          <div class="flex flex-wrap -m-2">
            <div class="p-2 w-1/2">
              <div class="relative">
                <label
                  for="name"
                  class="leading-7 text-md font-semibold text-gray-600"
                >
                  Ocular Motility
                </label>
              </div>
            </div>
            <div class="p-2 w-1/2">
              <div class="relative">
                <input
                  type="radio"
                  name="ocularMotility"
                  value="Normal"
                  onChange={handleInputChange}
                />
                <label for="email" class="ml-4 leading-7 text-sm text-gray-600">
                  Normal
                </label>
              </div>
              <div class="relative">
                <input
                  type="radio"
                  name="ocularMotility"
                  value="Abnormal"
                  onChange={handleInputChange}
                />
                <label for="email" class="ml-4 leading-7 text-sm text-gray-600">
                  Abnomral
                </label>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -m-2">
            <div class="p-2 w-1/2">
              <div class="relative">
                <label
                  for="name"
                  class="leading-7 text-md font-semibold text-gray-600"
                >
                  Pupil Dilation
                </label>
              </div>
            </div>
            <div class="p-2 w-1/2">
              <div class="relative">
                <input
                  type="radio"
                  name="pupilDilation"
                  value="Un-Dilated"
                  onChange={handleInputChange}
                />
                <label for="email" class="ml-4 leading-7 text-sm text-gray-600">
                  Un-Dilated
                </label>
              </div>
              <div class="relative">
                <input
                  type="radio"
                  name="pupilDilation"
                  value="Dilated"
                  onChange={handleInputChange}
                />
                <label for="email" class="ml-4 leading-7 text-sm text-gray-600">
                  Dilated
                </label>
              </div>
            </div>
          </div>
          <p className="text-xl text-black py-2 my-2">Vascular Acuity (VA)</p>
          <div class="flex flex-wrap -m-2">
            <div class="p-2 w-1/3">
              <div class="relative">
                <label
                  for="name"
                  class="leading-7 text-md font-semibold text-gray-700"
                >
                  Uncorrected
                </label>
                <div className="mr-2 my-2 text-gray-500">OD</div>
                <input
                  className="my-2 px-2 py-1 border-2 border-gray-300 rounded-md mb-2 w-full"
                  name="uncorrectedOD"
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Reading"
                />
                <div className="mr-2 my-2 text-gray-500">OS</div>
                <input
                  className="my-2 px-2 py-1 border-2 border-gray-300 rounded-md mb-2 w-full"
                  name="uncorrectedOS"
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Reading"
                />
              </div>
            </div>
            <div class="p-2 w-1/3">
              <div class="relative">
                <label
                  for="name"
                  class="leading-7 text-md font-semibold text-gray-700"
                >
                  Current
                </label>
                <div className="mr-2 my-2 text-gray-500">OD</div>
                <input
                  className="my-2 px-2 py-1 border-2 border-gray-300 rounded-md mb-2 w-full"
                  type="text"
                  name="currentOD"
                  onChange={handleInputChange}
                  placeholder="Reading"
                />
                <div className="mr-2 my-2 text-gray-500">OS</div>
                <input
                  className="my-2 px-2 py-1 border-2 border-gray-300 rounded-md mb-2 w-full"
                  type="text"
                  name="currentOS"
                  onChange={handleInputChange}
                  placeholder="Reading"
                />
              </div>
            </div>
            <div class="p-2 w-1/3">
              <div class="relative">
                <label
                  for="name"
                  class="leading-7 text-md font-semibold text-gray-700"
                >
                  Corrected
                </label>
                <div className="mr-2 my-2 text-gray-500">OD</div>
                <input
                  onChange={handleInputChange}
                  name="correctedOD"
                  className="my-2 px-2 py-1 border-2 border-gray-300 rounded-md mb-2 w-full"
                  type="text"
                  placeholder="Reading"
                />
                <div className="mr-2 my-2 text-gray-500">OS</div>
                <input
                  name="correctedOS"
                  onChange={handleInputChange}
                  className="my-2 px-2 py-1 border-2 border-gray-300 rounded-md mb-2 w-full"
                  type="text"
                  placeholder="Reading"
                />
              </div>
            </div>
          </div>
          <p className="text-xl text-black py-2 my-2">Glass Prescription</p>
          <div class=" w-full mx-auto overflow-auto">
            <table class="table-auto w-full text-left whitespace-no-wrap">
              <thead>
                <tr>
                  <th class="py-3 title-font font-medium text-gray-900 text-sm bg-gray-100 w-10"></th>
                  <th class="py-3 title-font font-medium text-gray-900 text-sm bg-gray-100 w-16">
                    SPH
                  </th>
                  <th class="py-3 title-font font-medium text-gray-900 text-sm bg-gray-100 w-16">
                    CYL
                  </th>
                  <th class="py-3 title-font font-medium text-gray-900 text-sm bg-gray-100 w-16">
                    AXIS
                  </th>
                  <th class="py-3 title-font font-medium text-gray-900 text-sm bg-gray-100 w-16">
                    VIS
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="px-2 py-3 w-10">L/E</td>
                  <td class="py-3 w-16">
                    <input
                      type="text"
                      className="w-full"
                      name="leftEyeSPH"
                      placeholder="+0.00"
                      onChange={handleInputChange}
                    />
                  </td>
                  <td class="py-3 w-16">
                    <input
                      type="text"
                      className="w-full"
                      name="leftEyeCYL"
                      placeholder="+0.00"
                      onChange={handleInputChange}
                    />
                  </td>
                  <td class="py-3 w-16">
                    <input
                      type="text"
                      className="w-full"
                      name="leftEyeAXIS"
                      placeholder="+0.00"
                      onChange={handleInputChange}
                    />
                  </td>
                  <td class="py-3 w-16">
                    <input
                      type="text"
                      className="w-full"
                      name="leftEyeVIS"
                      placeholder="+0.00"
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td class="border-t-2 border-gray-200 px-2 py-3 w-10">R/E</td>
                  <td class="border-t-2 border-gray-200 py-3 w-16">
                    <input
                      type="text"
                      className="w-full"
                      placeholder="+0.00"
                      onChange={handleInputChange}
                      name="rightEyeSPH"
                    />
                  </td>
                  <td class="border-t-2 border-gray-200 py-3 w-16">
                    <input
                      type="text"
                      className="w-full"
                      placeholder="+0.00"
                      onChange={handleInputChange}
                      name="rightEyeCYL"
                    />
                  </td>
                  <td class="border-t-2 border-gray-200 py-3 w-16">
                    <input
                      type="text"
                      className="w-full"
                      placeholder="+0.00"
                      onChange={handleInputChange}
                      name="rightEyeAXIS"
                    />
                  </td>
                  <td class="border-t-2 border-gray-200 py-3 w-16">
                    <input
                      type="text"
                      className="w-full"
                      placeholder="+0.00"
                      onChange={handleInputChange}
                      name="rightEyeVIS"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="border-t-2 border-gray-200 px-2 py-3 w-10">ADD</td>
                  <td class="border-t-2 border-gray-200 py-3 w-16">
                    <input
                      type="text"
                      className="w-full"
                      onChange={handleInputChange}
                      name="allEyeSPH"
                    />
                  </td>
                  <td class="border-t-2 border-gray-200 py-3 w-16">
                    <input
                      type="text"
                      className="w-full"
                      onChange={handleInputChange}
                      placeholder="+0.00"
                      name="allEyeCYL"
                    />
                  </td>
                  <td class="border-t-2 border-gray-200 py-3 w-16">
                    <input
                      type="text"
                      className="w-full"
                      onChange={handleInputChange}
                      name="allEyeAXIS"
                    />
                  </td>
                  <td class="border-t-2 border-gray-200 py-3 w-16">
                    <input
                      type="text"
                      className="w-full"
                      onChange={handleInputChange}
                      name="allEyeVIS"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-xl text-black py-2 my-2"> Intraocular Pressure</p>
          <div class="flex flex-wrap -m-2">
            <div class="p-2 w-1/3">
              <div class="relative">
                <label for="name" class="leading-7 text-sm text-gray-600 mt-2">
                  RIGHT EYE (OD)
                </label>
              </div>
            </div>
            <div class="p-2 w-1/3">
              <div class="relative">
                <input
                  type="text"
                  placeholder="Reading"
                  name="intraocularPressureOD"
                  onChange={handleInputChange}
                  class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700  px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div class="p-2 w-1/3">
              <div class="relative">
                <label for="name" class="leading-7 text-sm text-gray-600 mt-2">
                  MM OF HG
                </label>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -m-2">
            <div class="p-2 w-1/3">
              <div class="relative">
                <label for="name" class="leading-7 text-sm text-gray-600 mt-2">
                  LEFT EYE (OD)
                </label>
              </div>
            </div>
            <div class="p-2 w-1/3">
              <div class="relative">
                <input
                  type="text"
                  placeholder="Reading"
                  name="intraocularPressureOS"
                  onChange={handleInputChange}
                  class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700  px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div class="p-2 w-1/3">
              <div class="relative">
                <label for="name" class="leading-7 text-sm text-gray-600 mt-2">
                  MM OF HG
                </label>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -m-2">
            <div class="p-2 w-1/3">
              <div class="relative">
                <label for="name" class="leading-7 text-sm text-gray-600 mt-2">
                  Time:
                </label>
              </div>
            </div>
            <div class="p-2 w-2/3">
              <div class="relative">
                <input
                  type="text"
                  placeholder="Time"
                  name="time"
                  onChange={handleInputChange}
                  class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700  px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
          </div>

          <p className="text-xl text-black py-2 my-2"> Corneal Topography</p>
          <div class="flex flex-wrap -m-2">
            <div class="p-2 w-1/2">
              <div class="relative">
                <img src={eye1} class="w-24 h-auto inline-block mt-2" />
                <p className="text-gray-600">RIGHT EYE (OD)</p>
              </div>
            </div>
            <div class="p-2 w-1/2">
              <div class="relative">
                <img src={eye1} class="w-24 h-auto inline-block mt-2" />
                <p className="text-gray-600">LEFT EYE (OD)</p>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -m-2 my-2">
            <div class="p-2 w-1/3">
              <div class="relative">
                <label for="name" class="leading-7 text-sm text-gray-600 mt-2">
                  NOTES:
                </label>
              </div>
            </div>
            <div class="p-2 w-2/3">
              <div class="relative">
                <input
                  type="text"
                  placeholder="enter note here"
                  name="notes"
                  onChange={handleInputChange}
                  class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700  px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
          </div>
          <p className="text-xl text-black py-2"> Fundus</p>

          <img src={eyeimage} class="w-full h-auto inline-block my-2" />
        </div>
      </div>
    </>
  );
};

export default EyePrescriptionSection;
