import React from 'react';
import eyeimage from "../../../../assets/eyeimage.png";
import eye1 from "../../../../assets/eye1.png";

// Reusable table row component
const TableRow = ({ label, values }) => (
  <tr>
    <td className="px-2 py-1 w-10">{label}</td>
    {values.map((value, index) => (
      <td key={index} className="py-1 w-16">{value}</td>
    ))}
  </tr>
);

const EyePrescriptionDownload = ({ eyeData }) => {
  const renderVascularAcuity = (label, value) => (
    value && (
      <div className="p-1 w-1/3">
        <label className="text-md font-semibold text-gray-700">{label}</label>
        <p className="text-sm text-gray-600">{value}</p>
      </div>
    )
  );

  const renderEyePrescriptionTable = () => {
    const rows = [
      { label: 'L/E', values: [eyeData.leftEyeSPH, eyeData.leftEyeCYL, eyeData.leftEyeAXIS, eyeData.leftEyeVIS] },
      { label: 'R/E', values: [eyeData.rightEyeSPH, eyeData.rightEyeCYL, eyeData.rightEyeAXIS, eyeData.rightEyeVIS] },
      { label: 'All', values: [eyeData.allEyeSPH, eyeData.allEyeCYL, eyeData.allEyeAXIS, eyeData.allEyeVIS] }
    ];

    return rows.some(row => row.values.some(val => val)) && (
      <table className="w-full text-left whitespace-no-wrap">
        <thead>
          <tr>
            <th className="py-1 font-medium text-gray-900 text-sm bg-gray-100 w-10"></th>
            <th className="py-1 font-medium text-gray-900 text-sm bg-gray-100 w-16">SPH</th>
            <th className="py-1 font-medium text-gray-900 text-sm bg-gray-100 w-16">CYL</th>
            <th className="py-1 font-medium text-gray-900 text-sm bg-gray-100 w-16">AXIS</th>
            <th className="py-1 font-medium text-gray-900 text-sm bg-gray-100 w-16">VIS</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => row.values.some(val => val) && <TableRow key={index} label={row.label} values={row.values} />)}
        </tbody>
      </table>
    );
  };

  return (
    <div className="w-1/3 mx-auto">
      <div className="border-2 border-gray-300 px-2 py-1 rounded-xl mb-2">
        {/* Examination Details */}
        {(eyeData.ocularMotility || eyeData.pupilDilation) && (
          <>
            <p className="text-lg font-semibold text-black py-1">Examination Details</p>
            {eyeData.ocularMotility && (
              <div className="flex flex-wrap">
                <div className="p-1 w-1/2">
                  <label className="text-md font-semibold text-gray-600">Ocular Motility</label>
                </div>
                <div className="p-1 w-1/2">
                  <p className="text-sm text-gray-600">{eyeData.ocularMotility}</p>
                </div>
              </div>
            )}
            {eyeData.pupilDilation && (
              <div className="flex flex-wrap">
                <div className="p-1 w-1/2">
                  <label className="text-md font-semibold text-gray-600">Pupil Dilation</label>
                </div>
                <div className="p-1 w-1/2">
                  <p className="text-sm text-gray-600">{eyeData.pupilDilation}</p>
                </div>
              </div>
            )}
          </>
        )}

        {/* Vascular Acuity (VA) */}
        {(eyeData.uncorrectedOD || eyeData.uncorrectedOS || eyeData.currentOD || eyeData.currentOS || eyeData.correctedOD || eyeData.correctedOS) && (
          <>
            <p className="text-lg font-semibold text-black py-1">Vascular Acuity (VA)</p>
            <div className="flex flex-wrap">
              {renderVascularAcuity('Uncorr. OD', eyeData.uncorrectedOD)}
              {renderVascularAcuity('Uncorr. OS', eyeData.uncorrectedOS)}
              {renderVascularAcuity('Current OD', eyeData.currentOD)}
              {renderVascularAcuity('Current OS', eyeData.currentOS)}
              {renderVascularAcuity('Corrected OD', eyeData.correctedOD)}
              {renderVascularAcuity('Corrected OS', eyeData.correctedOS)}
            </div>
          </>
        )}

        {/* Glass Prescription */}
        {renderEyePrescriptionTable()}

        {/* Intraocular Pressure */}
        {(eyeData.intraocularPressureOD || eyeData.intraocularPressureOS || eyeData.time) && (
          <>
            <p className="text-lg font-semibold text-black py-1">Intraocular Pressure</p>
            <div className="flex flex-wrap">
              {eyeData.intraocularPressureOD && (
                <div className="p-1 w-1/3">
                  <label className="text-sm text-gray-600">Right Eye (OD)</label>
                  <p className="text-sm text-gray-600">{eyeData.intraocularPressureOD}</p>
                </div>
              )}
              {eyeData.intraocularPressureOS && (
                <div className="p-1 w-1/3">
                  <label className="text-sm text-gray-600">Left Eye (OS)</label>
                  <p className="text-sm text-gray-600">{eyeData.intraocularPressureOS}</p>
                </div>
              )}
              {eyeData.time && (
                <div className="p-1 w-1/3">
                  <label className="text-sm text-gray-600">Time</label>
                  <p className="text-sm text-gray-600">{eyeData.time}</p>
                </div>
              )}
            </div>
          </>
        )}

        {/* Corneal Topography */}
        <p className="text-lg font-semibold text-black py-1">Corneal Topography</p>
        <div className="flex flex-wrap">
          <div className="p-1 w-1/2">
            <img src={eye1} className="w-20 h-auto" alt="Corneal Topography OD" />
            <p className="text-gray-600 text-sm">Right Eye (OD)</p>
          </div>
          <div className="p-1 w-1/2">
            <img src={eye1} className="w-20 h-auto" alt="Corneal Topography OS" />
            <p className="text-gray-600 text-sm">Left Eye (OS)</p>
          </div>
        </div>

        {/* Notes */}
        {eyeData.notes && (
          <div className="flex flex-wrap my-1">
            <div className="p-1 w-1/3">
              <label className="text-sm text-gray-600">Notes</label>
            </div>
            <div className="p-1 w-2/3">
              <p className="text-sm text-gray-600">{eyeData.notes}</p>
            </div>
          </div>
        )}

        {/* Fundus Image */}
        <p className="text-lg font-semibold text-black py-1">Fundus</p>
        <img src={eyeimage} className="w-full h-auto my-1" alt="Fundus Image" />
      </div>
    </div>
  );
};

export default EyePrescriptionDownload;
