import React, { useState } from "react";
import Header from "../header";
const OTPAuth = () => {
  const [otpNumber, setOtpNumber] = useState(["", "", "", "", "", ""]);
  const handleInputEvent = (event) => {
    setOtpNumber(event.target.value);
  };
  return (
    <>
      <Header />
      <div className="mx-auto max-w-6xl border-2 border-gray-200 p-4 mt-2">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-800 mx-4 items-center">
          Confirm OTP
        </h2>

        <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-800 mx-4 mb-2 items-center">
          OTP Sent to Aadhar mobile number ending with ******1400
        </h2>

        <div className="flex  mb-2">
          {otpNumber.map((digit, index) => (
            <div key={index} className=" flex flex-col items-center">
              <input
                type="text"
                id={`aadhar-digit-${index}`}
                value={digit}
                maxLength={1}
                onChange={handleInputEvent}
                className="w-4 h-10 border border-gray-600 rounded-md px-5 text-black ml-4"
                placeholder="****"
              />
            </div>
          ))}
        </div>

        <div class="flex item center">
          <p className="text-md font-medium text-gray-900 dark:text-gray-800 mx-4 items-center">
            Didn't receive OTP ?
          </p>

          <button className="text-md font-medium text-gray-900 dark:text-gray-800 mx-4 mb-2 items-center">
            Resend OTP
          </button>
          <p className="text-md font-medium text-gray-900 dark:text-gray-800 mx-4 items-center">
            00:45 remaining
          </p>
        </div>
        <div class="flex-col">
          <p className="text-md font-medium text-gray-900 dark:text-gray-800 mx-4 items-center">
            Mobile Number<span className="text-red-500 ml-1 ">*</span>
          </p>
          <div class="max-w-xl mx-auto p-6">
            <div class="flex items-center mt-1">
              <p class="h-10 px-4 text-sm bg-gray-200 border border-l-0 border-gray-200 rounded-l-lg shadow-sm text-black hover:bg-blue-400 hover:border-blue-400 focus:outline-none">
                +91
              </p>
              <input
                type="email"
                id="input-9"
                class="w-full h-10 px-3 text-sm text-gray-700 border border-r-1 border-blue-500 focus:outline-none rounded shadow-sm"
                placeholder="Mobile Number"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-end  mt-3">
          <button class="px-6 py-2 bg-gray-200  text-black text-sm font-medium rounded-md">
            Cancel
          </button>

          <button
            class="px-6 py-2 ml-2 text-white text-sm font-medium rounded-md"
            style={{ backgroundColor: "rgb(214, 96, 37)" }}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default OTPAuth;
