import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FetchPrescriptionPhoto } from '../api/fetchPrescriptionPhoto';
import useData from './useData';
import { toggleState } from '../../specialMethod/toggleState';

const usePrescriptionPhoto = () => {
  const [state, setState] = useState({
    loadingSpinner: true
  });
  const dispatch = useDispatch();
  const prescriptionPhotoURLs = useSelector((state) => state.prescription.prescriptionPhotoURLs);
  const isLoading = useSelector((state) => state.prescription.isLoading);
  const error = useSelector((state) => state.prescription.error);
  
  const { data } = useData();

  useEffect(() => {
    if (data) {
      toggleState("loadingSpinner", false,setState);
    }
  }, [data]);
  console.log("Hook - prescriptionPhotoURLs:", prescriptionPhotoURLs);

  useEffect(() => {
    if (data && data.clinicDtos && Array.isArray(data.clinicDtos)) {
      console.log(data.clinicDtos)
      data.clinicDtos.forEach(clinic => {
        dispatch(FetchPrescriptionPhoto(clinic.id));
      });
    }
  }, [dispatch, data]);

  return { prescriptionPhotoURLs, isLoading, error, loadingSpinner: state.loadingSpinner  };
};

export default usePrescriptionPhoto;
