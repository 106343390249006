// clinicIncomeSlice.js
import { createSlice, current } from "@reduxjs/toolkit";
// import { fetchClinicIncome } from "../../api/fetchClinicIncome";

const clinicIncomeSlice = createSlice({
  name: "clinicIncome",
  initialState: {
    clinicIncomes: {
      totalAmount: 0,
      cashAmount: 0,
      upiAmount: 0,
      fetchedOnce: false,
    },
    anotherData: {
      totalAmount: 0,
      cashAmount: 0,
      upiAmount: 0,
      fetchedOnce: false,
    },
    loading: false,
    anotherLoading: false,
    error: null,
    anotherError: null,
    clinicFetched: false,
    anotherFetched: false,
  },
  reducers: {
    fetchClinicIncomesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchClinicIncomesSuccess(state, action) {
      state.loading = false;
      state.clinicIncomes = { ...action.payload, fetchedOnce: true }; ;
      state.error = null;
      state.clinicFetched = true;
    },
    fetchClinicIncomesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchAnotherDataStart(state) {
      state.anotherLoading = true;
      state.anotherError = null;
    },
    fetchAnotherDataSuccess(state, action) {
      state.anotherLoading = false;
      state.anotherData = { ...action.payload, fetchedOnce: true };
      state.anotherError = null;
      state.anotherFetched = true;
    },
    fetchAnotherDataFailure(state, action) {
      state.anotherLoading = false;
      state.anotherError = action.payload;
    },
    addClinicAmount(state, action) {
      const { type, amount } = action.payload;

      if (type === "cash") {
        state.clinicIncomes.cashAmount += parseInt(amount);
        state.anotherData.cashAmount +=parseInt(amount);
      } else if (type === "upi") {
        state.clinicIncomes.upiAmount +=parseInt(amount);
        state.anotherData.upiAmount +=parseInt(amount);
      }
      state.clinicIncomes.totalAmount +=parseInt(amount);
      state.anotherData.totalAmount +=parseInt(amount);
    },
    subtractClinicAmount(state, action) {
      const { type, amount } = action.payload;

      if (type === "cash") {
        state.clinicIncomes.cashAmount -= parseInt(amount);
        state.anotherData.cashAmount -=parseInt(amount);
      } else if (type === "upi") {
        state.clinicIncomes.upiAmount -=parseInt(amount);
        state.anotherData.upiAmount -=parseInt(amount);
      }
      state.clinicIncomes.totalAmount -=parseInt(amount);
      state.anotherData.totalAmount -=parseInt(amount);
    },
  },
});

export const {
  fetchClinicIncomesStart,
  fetchClinicIncomesSuccess,
  fetchClinicIncomesFailure,
  fetchAnotherDataStart,
  fetchAnotherDataSuccess,
  fetchAnotherDataFailure,
  subtractClinicAmount,
  addClinicAmount,
} = clinicIncomeSlice.actions;

export const selectClinicIncomes = (state) => state.clinicIncome.clinicIncomes;
export const selectClinicIncomesLoading = (state) => state.clinicIncome.loading;
export const selectClinicIncomesError = (state) => state.clinicIncome.error;
export const selectAnotherData = (state) => state.clinicIncome.anotherData;
export const selectAnotherLoading = (state) =>
  state.clinicIncome.anotherLoading;
export const selectAnotherError = (state) => state.clinicIncome.anotherError;

export default clinicIncomeSlice.reducer;
