import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MonthlyReport = ({ result }) => {
  const [monthlyClinicData, setMonthlyClinicData] = useState([]);
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    if (result && Array.isArray(result) && result.length > 0) {
      let months = []
      if(result[0].APRIL){
        months = [
          "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
          "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
        ];
      }else{
        months = [
          "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY","SATURDAY", "SUNDAY" 
        ];
      }

      // Organize data clinic-wise for each month
      const organizedData = months.map((month) => {
        return result.map((clinicData) => {
          return clinicData[month]?.totalAppointments || 0; // Use optional chaining to prevent undefined errors
        });
      });

      setMonthlyClinicData(organizedData);
    }
  }, [result]);

  useEffect(() => {
    if (monthlyClinicData.length > 0) {
      // Prepare data for chart
      let chartLabels = [];
      if(monthlyClinicData.length ==7){
        chartLabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      }else{
        chartLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      } 
      const maxClinics = 8; // Adjust based on your clinic count
      const datasets = result.map((clinicData, clinicIndex) => {
        const greenValue = Math.floor(255 - (clinicIndex / (maxClinics - 1)) * 255);
      
        return {
          label: `Clinic ${clinicData.clinicLocation}`,
          backgroundColor: `rgba(255, ${greenValue}, 0, 0.6)`,
          borderColor: `rgba(255, ${greenValue}, 0, 1)`,
          borderWidth: 1,
          hoverBackgroundColor: `rgba(255, ${greenValue}, 0, 0.8)`,
          hoverBorderColor: `rgba(255, ${greenValue}, 0, 1)`,
          data: monthlyClinicData.map((month) => month[clinicIndex]),
        };
      });   
      

      setChartData({
        labels: chartLabels,
        datasets: datasets,
      });
    }
  }, [monthlyClinicData, result]);

  return (
    <div className="flex flex-col items-center w-full max-w-screen-xl p-6 pb-6 mt-1 bg-white rounded-lg shadow-xl sm:p-8">
      {chartData && chartData.datasets && chartData.datasets.length > 0 ? (
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom',
              },
              title: {
                display: true,
                text: 'Clinic Monthly Report',
              },
            },
          }}
        />
      ) : (
        <p>Loading data</p>
      )}
    </div>
  );
};

export default MonthlyReport;
