import { useDispatch } from "react-redux";
import {  updateScheduleSuccess } from "../../../redux/features/data/dataSlice";
import { toast } from "react-toastify";
import apiServices from "../../../../Service/apiService";
import { EDIT_SCHEDULE_API } from "../dashboard_api.js/api";

export const useHandleEditDoctorSchedule = (event, reset, toggleState, setState) => {
    const dispatch = useDispatch();
    const token = sessionStorage.getItem("token");
    const handleDoctorSchedule = async (values) => {
        toggleState("loadingSpinner", true,setState);
        try {
          const response = await apiServices.put(
            "authentication",
            EDIT_SCHEDULE_API,
            {
              Authorization: " Bearer " + token,
            },
            {
              doctorInterfaceId: event.id,
            },
            {
              purpose: values.purpose,
              clinicName: values.clinicName,
              startTime: values.startTime,
              endTime: values.endTime,
              stDate: values.stDate,
              endDate: values.endDate,
            }
          );
          if (response.statusCode === 200) {
            toggleState("loadingSpinner", false,setState);
            dispatch(updateScheduleSuccess(response.data));
            reset();
          }
        } catch (error) {
          console.error("error is coming ", error);
          if (error.response.data.statusCode === 404) {
            toast.error("Something Went Wrong", {
              position: "top-center",
            });
          }
        } finally {
          toggleState("loadingSpinner", false,setState);
        }
      };

    return { handleDoctorSchedule };
};