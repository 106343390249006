import { useDispatch } from "react-redux";
import apiServices from "../../../../Service/apiService";
import { deleteClinicSuccess } from "../../../redux/features/data/dataSlice";

export const useHandleClinicDelete = (reset) => {
  const dispatch = useDispatch();

  const handleClinicDelete = async (clinicId) => {
    try {
      const response = await apiServices.patch(
        "authentication",
        `/clinic/delete/${clinicId}`,
        null,
        null,
        null
      );

      if (response) {
        dispatch(deleteClinicSuccess(clinicId));
        reset();
      }
    } catch (error) {
      console.error("Error in deleting clinic: ", error);
    }
  };

  return { handleClinicDelete };
};
