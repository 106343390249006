import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAppointmentData } from "../features/allAppointment/appointmentThunk";
import {
  getAppointmentData,
  loadingAppointmentData,
  getAppointmentDataError,
} from "../features/allAppointment/appointmentSelector";
const useAppointmentData = (date) => {
  const dispatch = useDispatch();
  const appointmentdata = useSelector(getAppointmentData);
  const loading = useSelector(loadingAppointmentData);
  const error = useSelector(getAppointmentDataError);
  useEffect(() => {
    if (!appointmentdata) {
      dispatch(GetAppointmentData(date));
    }
  }, [appointmentdata, dispatch]);

  return { appointmentdata, loading, error };
};
export default useAppointmentData;