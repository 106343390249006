import React, { useState, useEffect } from "react";
import Spinner from "../loading/spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import apiServices from "../../../Service/apiService";
import useData from "../../redux/customHook/useData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addPatientCount } from "../../redux/features/patientVisit/patientSlice";
import { useDispatch } from "react-redux";
import {
  convertTo24Hour,
  formatDateToDDMMYY,
  formatTime,
  getWeekday,
} from "../../specialMethod/getBetweenDate";
import { addAppointmentSucess } from "../../redux/features/allAppointment/appointmentSlice";
import CalendarInput from "./calendarInput";

const NewPatientForm = ({ responseData, reset, patientDetails }) => {
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const getPatientDetails = patientDetails?.data?.[patientDetails?.data?.length - 1] || {};
  console.log(getPatientDetails);
  const [state, setState] = useState({
    loadingSpinner: false,
    selectClinic: [],
    filteredClinics: [],
    timeSlots: [],
  });
  const [response, setResponse] = useState({ data: {} });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const formik = useFormik({
    initialValues: {
      name: getPatientDetails.name || "",
      abhaNumber: getPatientDetails.abhaNumber || responseData.abhaNumber || "",
      age: getPatientDetails.age || "",
      contact: "" || responseData.contact,
      gender: getPatientDetails.gender || "",
      appointmentDate: formatDateToDDMMYY(new Date()),
      appointmentTime: "",
      clinicId: "", // Add clinicId field to form values
      clinicLocation: "",
      guardianName: getPatientDetails.guardianName || "",
      address : getPatientDetails.address || ""
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("name is required"),
      abhaNumber: Yup.string(),
      age: Yup.string().required("age is required"),
      contact: Yup.string().required("contact is required"),
      gender: Yup.string().required("Gender is required"),
      appointmentDate: Yup.string().required("appointment date is required"),
      appointmentTime: Yup.string().required("appointment time is required"),
      clinicId: Yup.string().required("Clinic is required"), // Add validation for clinicId
      clinicLocation: Yup.string().required("Clinic is required"),
    }),
    onSubmit: (values) => {
      getNewPatient(values);
    },
  });
  const { data } = useData();
  useEffect(() => {
    if (data) {
      toggleState("loadingSpinner", false);
      const activeClinics = data.clinicDtos.filter(clinic => clinic.clinicStatus === "Active");
      toggleState("selectClinic", activeClinics || []);
      toggleState("filteredClinics", activeClinics || []);
    }
  }, [data]);
  // handle New Patient....
  const getNewPatient = async (values) => {
    toggleState("loadingSpinner", true);
    try {
      const response = await apiServices.post(
        "authentication",
        "/appointment/book1",
        {
          Authorization: " Bearer " + token,
        },
        {
          clinicId: values.clinicId,
        },
        {
          name: values.name,
          abhaNumber: values.abhaNumber,
          age: values.age,
          contact: values.contact,
          gender: getPatientDetails.gender || values.gender,
          appointmentDate: values.appointmentDate,
          appointmentTime: convertTo24Hour(values.appointmentTime),
          clinicLocation: formik.values.clinicLocation,
          guardianName : values.guardianName,
          address : values.address
        }
      );
      if (response.statusCode === 200) {
        toast.info("Appoinment Booked Successfully", {
          position: "top-center",
        });
        // getWhatsappNotifcation(values)
        dispatch(addPatientCount());
        reset();    
        dispatch(addAppointmentSucess(response.data));
        return response.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      toggleState("loadingSpinner", false);
      reset();
    }
  };
  // const getWhatsappNotifcation = async (values) => {
  //   if (process.env.REACT_APP_WHATSAPP_ENABLED !== "true") {
  //     console.log("WhatsApp notifications are disabled in this environment.");
  //     return;
  //   }
  //   const clinica = data.clinicDtos.filter(clinic => clinic.id == values.clinicId);
  //   try {
  //     const response = await apiServices.post(
  //       "authentication",
  //       "/whatsapp/appointment_confirmation",
  //       null,
  //       null,
  //       {
  //         patientName: values.name,
  //         patientContact: values.contact,
  //         drName: data?.firstName + " "+  data?.lastName,
  //         date: values.appointmentDate,
  //         time: values.appointmentTime,
  //         clinicAddress: formik.values.clinicLocation,
  //         compounderName: clinica[0].incharge,
  //         clinicContact: clinica[0].clinicContact,
  //       }
  //     );
  //     if (response) {
  //       console.log("success whatsap",response)
  //       return response.data;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } 
  // };
  useEffect(() => {
    const { clinicLocation } = formik.values;

    if (clinicLocation) {
      const filteredClin = state.selectClinic.filter(
        (clinic) => clinic.location === clinicLocation
      );
      filteredClin.forEach((clinic) => {
        console.log(`Start Time: ${clinic.startTime}`);
        console.log(`End Time: ${clinic.endTime}`);
      });
      if (filteredClin.length > 0) {
        const { startTime, endTime } = filteredClin[0];
        console.log(`Start Time: ${startTime}`);
        console.log(`End Time: ${endTime}`);

        // Generate time slots within the useEffect
        const slots = [];
        const start = new Date(`1970-01-01T${startTime}`);
        const end = new Date(`1970-01-01T${endTime}`);

        while (start <= end) {
          const time = formatTime(start);
          slots.push(time);
          start.setMinutes(start.getMinutes() + 5);
        }

        toggleState("timeSlots", slots);
      }
    }
  }, [formik.values.clinicLocation]);

  useEffect(() => {
    formik.setFieldValue("appointmentDate", formatDateToDDMMYY(selectedDate));
  }, [selectedDate]);
  useEffect(() => {
    const { appointmentDate } = formik.values;
    if (appointmentDate && state.selectClinic.length > 0) {
      const calculatedWeekday = getWeekday(appointmentDate).toUpperCase();
      const filteredClini = state.selectClinic.filter((clinic) =>
        clinic.days.includes(calculatedWeekday)
      );
      toggleState("filteredClinics", filteredClini);
      console.log(filteredClini);
    }
  }, [formik.values.appointmentDate, state.selectClinic]);

  useEffect(() => {
    formik.setFieldValue("appointmentDate", formatDateToDDMMYY(selectedDate));
    handleClinicModal();
  }, [selectedDate]);
  const handleClinicModal = async () => {
    try {
      const response = await apiServices.get(
        "authentication",
        "/clinic/totalAppointOfEachClinicOfDoctor",
        {
          Authorization: "Bearer " + token,
        },
        {
          localDate: formatDateToDDMMYY(selectedDate),
        },
        null
      );
      if (response.statusCode === 200) {
        setResponse(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (state.filteredClinics.length === 1) {
      formik.setFieldValue("clinicId", state.filteredClinics[0].id);
      formik.setFieldValue("clinicLocation", state.filteredClinics[0].location);
    }
  }, [state.filteredClinics, formik]);

  useEffect(() => {
    if (state.filteredClinics.length === 1) {
      formik.setFieldValue("clinicId", state.filteredClinics[0].id);
      formik.setFieldValue("clinicLocation", state.filteredClinics[0].location);
    }
  }, [state.filteredClinics, formik]);
  const handleClinicChange = (e) => {
    const clinicId = e.target.value;
    const clinicLocation =
      e.target.selectedOptions[0].getAttribute("data-location");

    formik.setValues({
      ...formik.values,
      clinicId: clinicId,
      clinicLocation: clinicLocation,
    });
  };

  return (
    <>
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-1 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
              onClick={reset}
            >
              <svg        
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  cliprule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>
            <div className="px-5 pt-5 pb-3">

              <div className="text-center mb-4">
                <p className="mb-2 text-2xl font-semibold leading-5 text-slate-900">
                  Book Appoinment
                </p>
              </div>
              {/* form */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <div className="grid grid-cols-2 gap-6 mt-1">
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      id="card-number"
                      placeholder="Full Name"
                      className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="text-red-400">{formik.errors.name}</div>
                    )}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="expiration-date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      ABHA Number <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      name="abhaNumber"
                      value={
                        responseData.abhaNumber || formik.values.abhaNumber
                      }
                      id="expiration-date"
                      placeholder="ABHA NUMBER"
                      className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.abhaNumber && formik.errors.abhaNumber ? (
                      <p style={{ color: "red" }}>{formik.errors.abhaNumber}</p>
                    ) : null}
                  </div>
                  </div>
                  <div className="grid grid-cols-2 gap-6 mt-1">
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="cvv"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Age <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      name="age"
                      value={formik.values.age}
                      onChange={formik.handleChange}
                      id="cvv"
                      placeholder="Age"
                      className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.age && formik.errors.age ? (
                      <p style={{ color: "red" }}>{formik.errors.age}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-holder"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Phone Number <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="number"
                      name="contact"
                      value={responseData.contact || formik.values.contact}
                      onChange={formik.handleChange}
                      id="card-holder"
                      placeholder="Mobile Number"
                      className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.contact && formik.errors.contact ? (
                      <p style={{ color: "red" }}>{formik.errors.contact}</p>
                    ) : null}
                  </div>
                  </div>
                  <div className="grid grid-cols-2 gap-6 mt-1">
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-number"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Gender <span className="text-red-600">*</span>
                    </label>
                    {getPatientDetails.gender ? (
                      <input
                        className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                        id="service"
                        name="gender"
                        value={getPatientDetails.gender}
                      />
                    ) : (
                      <select
                        className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                        id="service"
                        name="gender"
                        onChange={formik.handleChange}
                      >
                        <option value="">Select Gender</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                        <option value="OTHER">Other</option>
                      </select>
                    )}
                    {formik.touched.gender && formik.errors.gender ? (
                      <p style={{ color: "red" }}>{formik.errors.gender}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-holder"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Guardian Name
                    </label>
                    <input
                      type="name"
                      name="guardianName"
                      value={getPatientDetails.guardianName || formik.values.guardianName}
                      onChange={formik.handleChange}
                      id="card-holder"
                      placeholder="Guardian Name"
                      className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.guardianName && formik.errors.guardianName ? (
                      <p style={{ color: "red" }}>{formik.errors.guardianName}</p>
                    ) : null}
                  </div>
                  </div>
                  <div className="grid grid-cols-2 gap-6 mt-1">
                 
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-holder"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Address 
                    </label>
                    <input
                      type="name"
                      name="address"
                      value={getPatientDetails.address || formik.values.address}
                      onChange={formik.handleChange}
                      id="card-holder"
                      placeholder="Address"
                      className="w-full py-2 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <p style={{ color: "red" }}>{formik.errors.address}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1"><CalendarInput
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                /></div>
                </div>
                

                <div class="grid grid-cols-2 gap-6 mt-1">
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="clinic"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Select Clinic <span className="text-red-600">*</span>
                    </label>
                    {state.filteredClinics.length === 1 ? (
                      <div className="py-2 px-4 text-black border border-gray-400 rounded-lg">
                        {state.filteredClinics[0].location} -{" "}
                        {response.data[state.filteredClinics[0].id]}
                        <input
                          type="hidden"
                          name="clinicId"
                          value={formik.values.clinicId}
                          onChange={(e) => handleClinicChange(e)}
                        />
                      </div>
                    ) : (
                      <select
                        id="clinic"
                        name="clinicId"
                        value={formik.values.clinicId}
                        onChange={(e) => handleClinicChange(e)}
                        className="w-full py-2 px-4 text-black border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                      >
                        <option value="">Select Clinic</option>
                        {state.filteredClinics.map((clinic) => (
                          <option
                            key={clinic.id}
                            value={clinic.id}
                            data-location={clinic.location}
                          >
                            {clinic.location.substring(0, 12)} -{" "}
                            {response.data[clinic.id]}
                          </option>
                        ))}
                      </select>
                    )}

                    {formik.touched.clinicId && formik.errors.clinicId ? (
                      <p style={{ color: "red" }}>{formik.errors.clinicId}</p>
                    ) : null}
                    {state.filteredClinics.length === 0 && (
                      <p style={{ color: "red" }}>No Clincs</p>
                    )}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="timeSlot"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Schedule TIme <span className="text-red-600">*</span>
                    </label>

                    <select
                      id="timeSlot"
                      name="appointmentTime"
                      onChange={formik.handleChange}
                      value={formik.values.appointmentTime}
                      className="w-full py-2 px-4 text-black border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    >
                      <option value="">Select Timings</option>
                      {state.timeSlots.map((slot, index) => (
                        <option key={index} value={slot}>
                          {slot}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    className="w-full bg-green-500 hover:bg-blue-600 text-white font-medium py-2 rounded-lg focus:outline-none"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {state.loadingSpinner && <Spinner />}
    </>
  );
};

export default NewPatientForm;
