import React, { useState, useEffect } from "react";
import apiServices from "../../../../Service/apiService";
import { useParams } from "react-router-dom";
import Spinner from "../../loading/spinner";

const UploadPrescription = ({ reset, getNewPriscription }) => {
  const { id } = useParams();
  const [state, setState] = useState({
    loadingSpinner: false,
    imageData: null,
    selectedFile:null
  });

  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  

  useEffect(() => {
    if (state.selectedFile) {
      const typeMatch = state.selectedFile.match(/^data:(image\/\w+);base64,/);
      if (typeMatch) {
        const imageType = typeMatch[1].split("/")[1];
       
        // Convert base64 to Blob
        const byteCharacters = atob(state.selectedFile.split(",")[1]);
        const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteArray], { type: `image/${imageType}` });

        // Create a file from the Blob
        const filename = `image.${imageType}`;
        
        const file = new File([blob], filename, { type: `image/${imageType}` });

        // Prepare image data for upload
        const formData = new FormData();
        formData.append("contact", id);
        formData.append("image", file);
        toggleState("imageData", formData);
      }else{
        const typeMatch = state.selectedFile.match(/^data:(application\/\w+);base64,/);
        if (typeMatch) {
          const pdfType = typeMatch[1].split("/")[1];
          const byteCharacters = atob(state.selectedFile.split(",")[1]);
          
          const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteArray], { type: `application/${pdfType}` });
        const filename = `application.${pdfType}`;
        
        const file = new File([blob], filename, { type: `application/${pdfType}` });
        const formData = new FormData();
        formData.append("contact", id);
        formData.append("image", file);
        toggleState("imageData", formData);
        }
      }
    }
  }, [state.selectedFile, id]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        toggleState("selectedFile", base64String); // Display the selected image
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const uploadPrescription = async () => {
    toggleState("loadingSpinner", true);
    try {
      if (!state.selectedFile || !state.imageData) {
        throw new Error("Please select a file.");
      }

      const response = await apiServices.post(
        "authentication",
        "/prescriptionUpload",
        null,
        null,
        state.imageData
      );

      if (response) {
        getNewPriscription(response);
        reset();
      }
    } catch (error) {
      console.error("Error uploading prescription:", error);
    } finally {
      toggleState("loadingSpinner", false);
      reset()
    }
  };

  // Rest of the component code...

  return (
    <>
      <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex">
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              onClick={reset}
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>

            <div className="p-5">
              <div className="text-center">
                <p className="mb-3 text-xl font-semibold leading-5 text-slate-900">
                  Upload Prescription
                </p>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  uploadPrescription();
                }}
              >
                <div className="mt-7 flex flex-col gap-2">
                  <div className="flex-1 items-center max-w-screen-sm mx-auto mb-3 space-y-4 sm:flex sm:space-y-0">
                    <div className="relative w-full">
                      <div className="items-center justify-center max-w-xl mx-auto">
                        <div className="flex items-center pb-4 space-x-4">
                          {state.selectedFile && (
                            <div className="flex-shrink-0">
                              <img
                                className="h-28 w-28 object-cover rounded-full"
                                src={state.selectedFile}
                                alt="Current profile"
                              />
                            </div>
                          )}
                          <input
                            type="file"
                            accept=".jpg,.jpeg,.png,.pdf"
                            onChange={handleImageChange}
                            id="file-upload"
                          />

                          <span className="selected-file-name">
                            {state.selectedFile && state.selectedFile.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex justify-center">
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {state.loadingSpinner && <Spinner />}
    </>
  );
};

export default UploadPrescription;
