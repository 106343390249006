import { useDispatch } from "react-redux";
import apiServices from "../../../../Service/apiService";
import { updateClinicSuccess } from "../../../redux/features/data/dataSlice";
import { handleImageUpload } from "./addClinic_function";
import { IMAGE_DELETE_API } from "../clinic_api/api";

export const useEditClinic = (setState, toggleState, reset,image ,id) => {
    const dispatch = useDispatch();
    const handleEditFunction = async (values) => {
        try {
          toggleState("loadingSpinner", true, setState);
          const response = await apiServices.put(
            "authentication",
            `/clinic/update/${id}`,
            null,
            null,
            {
              clinicName: values.clinicName,
              location: values.location,
              startTime: values.startTime,
              endTime: values.endTime,
              incharge: values.incharge,
              clinicContact: values.clinicContact,
              clinicNewFees: values.clinicNewFees,
              clinicOldFees: values.clinicOldFees,
              days: values.days,
            }
          );
          if (response.statusCode === 200) {
            dispatch(updateClinicSuccess(response.data));
            if (image) {
              handleImageUpload(id);
            }
            reset();
          }
        } catch (error) {
          console.error("error", error.response);
        } finally {
          toggleState("loadingSpinner", false, setState);
        }
      };
    return { handleEditFunction }
}

export const onDeletePrescriptionImg = (setState, toggleState, id) => {
    const deletePrescriptionImg = async () => {
        toggleState("loadingSpinner", true, setState);
        try {
          const response = await apiServices.delete(
            "authentication",
            IMAGE_DELETE_API,
            null,
            {
              clinicId: id
            },
            null
          );
          if (response) {
            toggleState("loadingSpinner", false, setState);
          }
        } catch (error) {
          console.error("error in deleting ", error);
        } finally {
          toggleState("loadingSpinner", false, setState);
        }
      }
    return { deletePrescriptionImg }
}