import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Spinner from "../../component/page/loading/spinner";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiServices from "../../Service/apiService";
const RegenrateOTP = () => {
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const varifyEmail = location.state?.getEmail || "";
  const getEmail = varifyEmail;

  useEffect(() => {}, [varifyEmail]);

  const regenrateOtp = useFormik({
    initialValues: {
      otp: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      otp: Yup.number().required("Enter your otp"),
    }),
    onSubmit: async (values) => {
      await regenrateOtpFunction(values);
    },
  });

  const regenrateOtpFunction = async (values) => {
    setLoadingSpinner(true);
    try {
      const response = await apiServices.put(
        "otpValidation",
        "/regenerate-otp",
        null,
        {
          email: varifyEmail,
          otp: values.otp,
        },
        null
      );

      if (response.statusCode === 200) {
        navigate("/validateOTP", { state: { getEmail } });
      } else if (response.data.statusCode === 401) {
        toast.error("Invalid Otp", {
          position: "top-center",
        });
      }
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        toast.error("Invalid OTP", {
          position: "top-center",
        });
      }
    } finally {
      setLoadingSpinner(false);
    }
  };
  return (
    <>
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center justify-between">
        <div className="max-w-screen-xl m-0 sm:m-10 md:shadow sm:rounded-lg flex justify-center flex-1 ">
          <div className="lg:flex-1 md:bg-white text-center flex flex-col justify-center items-center">
            {/* Doc-Aid Logo */}
            <div className="w-full flex justify-center items-center">
              <div className="lg:w-3/4 mb-24 md:mb-0">
                <img alt="img" className="w-[350px] xl:w-full lg:w-auto mb-24 md:mb-0" src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/doc-aid.png" />
              </div>
            </div>
            {/* for mobile screen User Interface */}
            <div
              className="absolute bottom-0  lg:hidden w-full py-12" 
              style={{
                backgroundColor: "rgb(237, 231, 242)",
                borderTopLeftRadius: "40px",
                borderTopRightRadius: "40px",
              }}
            >
              <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-16 flex justify-center items-center">
                {/* input box */}
                <div className="w-full mt-4rem">
                  <div className="my-1 border-b text-center">
                    <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2"></div>
                  </div>
                  <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    regenrateOtp.handleSubmit();
                  }}
                >
                  <div className="mx-auto max-w-xs">
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-500 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                      type="number"
                      name="otp"
                      value={regenrateOtp.values.otp}
                      onChange={regenrateOtp.handleChange}
                      placeholder="Enter Old Otp"
                    />
                    {regenrateOtp.touched.otp && regenrateOtp.errors.otp ? (
                      <p className="text-left" style={{ color: "red" }}>{regenrateOtp.errors.otp}</p>
                    ) : null}

                    <button
                      type="submit"
                      className="mt-5 tracking-wide font-semibold bg-green-400 text-white-500 w-full py-4 rounded-lg hover:bg-green-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                      style={{ backgroundColor: "rgb(7, 160, 165)" }}
                    >
                      <span className="ml-">Verify OTP</span>
                    </button>
                  </div>
                </form>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block lg:w-1/2 xl:w-5/12 p-6 sm:p-12" style={{ backgroundColor: "rgb(237, 231, 242)" }}>
            {/* input box */}
            <div className="mt-12 flex flex-col items-center">
              <div className="w-full flex-1 mt-8">
                <div className="my-12 border-b text-center">
                  <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-gray-800 transform translate-y-1/2"></div>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    regenrateOtp.handleSubmit();
                  }}
                >
                  <div className="mx-auto max-w-xs">
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-500 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                      type="number"
                      name="otp"
                      value={regenrateOtp.values.otp}
                      onChange={regenrateOtp.handleChange}
                      placeholder="Enter Old Otp"
                    />
                    {regenrateOtp.touched.otp && regenrateOtp.errors.otp ? (
                      <p style={{ color: "red" }}>{regenrateOtp.errors.otp}</p>
                    ) : null}

                    <button
                      type="submit"
                      className="mt-5 tracking-wide font-semibold bg-green-400 text-white-500 w-full py-4 rounded-lg hover:bg-green-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                      style={{ backgroundColor: "rgb(7, 160, 165)" }}
                    >
                      <span className="ml-">Verify OTP</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loadingSpinner && <Spinner />}
      <ToastContainer />
    </>
  );
};
export default RegenrateOTP;
