import React, { useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import apiServices from "../../Service/apiService";
import Spinner from "../page/loading/spinner";

const Login = () => {
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [showPassword, setSetShowPassword] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      email: Yup.string().required("please enter email"),
      password: Yup.string().required("please enter the password"),
    }),
    onSubmit: (values) => {
      loginDoctor(values);
    },
  });
  const handleShowPassword = () => {
    setSetShowPassword((prevShowPassword) => !prevShowPassword); 
  };
  const loginDoctor = async (values) => {
    try {
      setLoadingSpinner(true);
      const response = await apiServices.post(
        "authentication",
        "/authenticate",

        {
          "Content-Type": "application/json",
        },
        null,
        {
          email: values.email,
          password: values.password,
        }
      );

      if (response.statusCode === 200) {
        sessionStorage.setItem("token", response.data.jwt);
        navigate("/dashBoard");
      }
    } catch (error) {
      if (error.response.data.statusCode === 500) {
        toast.error(error.response.data.message, {
          position: "top-center",
        });
      }
    } finally {
      setLoadingSpinner(false);
    }
  };
  return (
    <>
  
      <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center justify-between">
        <div className="max-w-screen-xl m-0 sm:m-10 md:shadow sm:rounded-lg flex justify-center flex-1 ">
          <div className="lg:flex-1 md:bg-white text-center flex flex-col justify-center items-center">
            <div className="w-full flex justify-center items-center">
              <div className="lg:w-3/4 mb-24 md:mb-0">
                <img
                  alt="img"
                  className="w-[350px] xl:w-full lg:w-auto mb-24 md:mb-0"
                  src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/doc-aid.png"
                />
              </div>
            </div>
            <div
              className="absolute bottom-0  lg:hidden w-full pb-10"
              style={{
                backgroundColor: "rgb(237, 231, 242)",
                borderTopLeftRadius: "40px",
                borderTopRightRadius: "40px",
              }}
            >
              <div className="lg:w-1/2 xl:w-5/12 pt-6  sm:p-16 flex justify-center items-center">
           
                <div className="w-full mt-4rem">
                  <div className="my-1 border-b text-center">
                    <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2"></div>
                  </div>
              <LoginForm formik={formik}
                  showPassword={showPassword}
                  handleShowPassword={handleShowPassword}/>
                </div>
              </div>
            </div>
          </div>
          <div
            className="hidden lg:block lg:w-1/2 xl:w-5/12 p-6 pb-14 sm:p-12"
            style={{ backgroundColor: "rgb(237, 231, 242)" }}
          >
            
            <div className="mt-12 flex flex-col items-center ">
              <div className="w-full flex-1 ">
                <div className="my-12 border-b text-center">
                  <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-gray-800 transform translate-y-1/2"></div>
                </div>
               <LoginForm formik={formik}
                  showPassword={showPassword}
                  handleShowPassword={handleShowPassword}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {loadingSpinner && <Spinner />}
    </>
  );
};

export default Login;


const LoginForm =({formik, showPassword, handleShowPassword})=>{return (
  <form
  onSubmit={(e) => {
    e.preventDefault();
    formik.handleSubmit();
  }}
>
  <div className="mx-auto max-w-xs">
    <input
      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
      type="text"
      name="email"
      value={formik.values.email}
      onChange={formik.handleChange}
      placeholder="Email"
    />
    {formik.touched.email && formik.errors.email ? (
      <p style={{ color: "red" }}>{formik.errors.email}</p>
    ) : null}
    <div className="relative">
        <input
          className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
          type={showPassword ? "text" : "password"}
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder="Password"
        />
  

        <div className="absolute inset-y-0 right-0 top-6 flex items-center pr-3 ">
          <button type="button" onClick={handleShowPassword}>
            {showPassword ? (
              <BiShow className="h-6 w-8" />
            ) : (
              <BiHide className="h-6 w-8" />
            )}
          </button>
        </div>
      </div>
    {formik.touched.password && formik.errors.password ? (
      <p style={{ color: "red" }}>{formik.errors.password}</p>
    ) : null}
    <div className="flex justify-between">
      <label className="block text-gray-500 font-bold my-4" />

      <label className="block text-gray-500 font-bold my-4">
        <Link
          to="/forgetPassword"
          className="cursor-pointer tracking-tighter text-black border-b-1 border-gray-200 hover:border-gray-400"
        >
          <span>Forgot Password</span>
        </Link>
      </label>
    </div>
    <button
      type="Submit"
      className="mt-5 tracking-wide font-semibol text-white-500 w-full py-4 rounded-lg  transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
      style={{ backgroundColor: "rgb(7, 160, 165)" }}
    >
      <svg
        className="w-6 h-6 -ml-2"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
        <circle cx="8.5" cy="7" r="4" />
        <path d="M20 8v6M23 11h-6" />
      </svg>
      &nbsp;
      <span className="ml-">Log In</span>
    </button>
    <div className="pt-4 font-semibold text-center">Don't have an account <Link to="/signup" className="text-blue-500 cursor-pointer">Signup</Link></div>
  </div>
</form>
)}
