import {
  fetchAppointmentStart,
  fetchAppointmentSuccess,
  fetchAppointmentFailure,
} from "./appointmentSlice";
import { fetchAppointment } from "../../api/fetchAllAppointBookApi";

export const GetAppointmentData = (date) => async (dispatch, getState) => {
  const { isDataFetched } = getState().data;
  if (!isDataFetched) {
    dispatch(fetchAppointmentStart());
  }
  try {
    const data = await fetchAppointment(date);
    dispatch(fetchAppointmentSuccess(data));
    
  } catch (error) {
    dispatch(fetchAppointmentFailure(error.message));
  }
};