import ApiServices from "../../../Service/apiService";

export async function fetchDataApi() {
  const getToken = sessionStorage.getItem("token");
  try {
    const response = await ApiServices.get(
      "authentication",
      "/home/get",
      {
        Authorization: " Bearer " + getToken,
      },
      null
    );
    if (response.statusCode === 200) {
      
      return response.data;
    }
  } catch (error) {
    if (error.response.data.statusCode === 404) {
      console.log("eror", error);
    }
  }
}
