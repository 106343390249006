import React, { useState, useEffect, useRef } from "react";
import Header from "./header";
import DoctorPersonalDetails from "./personalDetailsForm";
import DoctorPracticeForm from "./doctorPracticeForm";
import SucessPopup from "./successPage";
import Spinner from "../../page/loading/spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { load } from "@cashfreepayments/cashfree-js";
import apiServices from "../../../Service/apiService";
import Term from "../../page/term&condition/term";
import OnBoardingPayment from "./onBoardingPayment";

const RegisterDoctor = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [showNewComponent, setShowNewComponent] = useState(false);
  const [paymentOption, setPaymentOption] = useState(false)
  const [doctorId, setDoctorId] = useState("")
  const cashfreeRef = useRef();
  const location = useLocation();
  const varifyEmail = location.state?.getEmail || "";
  useEffect(() => {
    // Logic related to varifyEmail if needed
  }, [varifyEmail]);

  const [formData, setFormData] = useState({
    imageUpload: null,
    firstName: "Dr.",
    lastName: "",
    contact: "",
    email: varifyEmail || "",
    password: "",
    confirm_Password: "",
    doctors: [{ degree: "", college: "", year: "" }],
    specialization: [""],
    achievements: [""],
    experience: "",
    licenceNumber: "",
    research_journal: [""],
    citations: [""],
  });
  const nextPage = (values) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    setCurrentPage(currentPage + 1);
  };

  const previousPage = (values) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    setCurrentPage(currentPage - 1);
  };
  const doctorStringInformation = formData.doctors.map(
    (doctor) => `${doctor.degree} ${doctor.college} ${doctor.year}`
  );

  const handleSubmit = async () => {
    try {
      setLoadingSpinner(true);
      const response = await apiServices.post(
        "authentication",
        "/home/register",
        null,
        null,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          degree: doctorStringInformation,
          specialization: formData.specialization,
          experience: formData.experience,
          research_journal: formData.research_journal,
          citations: formData.citations,
          achievements: formData.achievements,
          licenceNumber: formData.licenceNumber,
          contact: formData.contact,
          email: varifyEmail || formData.email,
          password: formData.password,
        }
      );

      if (response.statusCode === 201) {
        const getDoctorId = response.data.id;
        setDoctorId(getDoctorId)
        await handleImageUpload(getDoctorId);
        setShowNewComponent(true);
       }
    } catch (error) {
      if (error.response.data.statusCode === 500) {
        toast.error(error.response.data.message, {
          position: "top-center",
        });
      }
    } finally {
      setLoadingSpinner(false);
    }
  };
  const handleNewComponentSubmit = async () => {
    // Make the API call for the new component
    setPaymentOption(true);
    setShowNewComponent(false);
  };
  // image Upload

  const handleImageUpload = async (doctorId) => {
    try {
      const base64String = formData.imageUpload;
       
      // Extract image type from base64 string
      const typeMatch = base64String.match(/^data:(image\/\w+);base64,/);
      if (!typeMatch) {
        throw new Error("Invalid base64 string");
      }
      const imageType = typeMatch[1].split("/")[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64String.split(",")[1]);
      const byteArray = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteArray], { type: `image/${imageType}` });

      // Create a file from the Blob
      const filename = `image.${imageType}`;
      const file = new File([blob], filename, { type: `image/${imageType}` });

      // Prepare image data for upload
      const imageData = new FormData();
      imageData.append("image", file);
      
      const response = await apiServices.post(
        "authentication",
        "/image",
        null,
        {
          did: doctorId,
        },
        imageData
      );
      if (response) {
        return response;
      }
    } catch (error) {
      console.error("Error is coming in api", error);
    }
  };

  // payment Gateway

  useEffect(() => {
    const initializeSDK = async () => {
      try {
        const cashfree = await load({
          mode: "production",
        });
        cashfreeRef.current = cashfree;
      } catch (error) {
        console.error("Error is coming from initialize payment ", error);
      }
    };
    initializeSDK();
  }, []);





  return (
    <> {showNewComponent ? (
        <Term  onSubmit={handleNewComponentSubmit} formData={formData} doctorId={doctorId} />
      ) : (
     <div>
   
      <Header currentPage={currentPage} />
      {currentPage === 1 && (
        <DoctorPersonalDetails
          onNext={nextPage}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      ;
      {currentPage === 2 && (
        <DoctorPracticeForm
          onNext={nextPage}
          onPrev={previousPage}
          formData={formData}
        />
      )}
      {currentPage === 3 && (
        <>
          <SucessPopup
            onSubmit={handleSubmit}
            onPrev={previousPage}
            formData={formData}
            imageData={formData.imageUpload}
          />
          {loadingSpinner && <Spinner />}
        </>
      )}
      <ToastContainer /></div>   )}
      {paymentOption && <OnBoardingPayment value={()=>{setPaymentOption(false)}} doctorId={doctorId} formData={formData}/>}
    </>
  );
};
export default RegisterDoctor;
