import React from "react";
import { useHandleClinicDelete } from "./clinic_function/deleteClinic_function";

const DeleteClinic = ({ reset, clinicId }) => {
  const { handleClinicDelete } = useHandleClinicDelete(reset);

  const onConfirmDelete = () => {
    handleClinicDelete(clinicId);
  };

  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="bg-black/50 fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden"
    >
      <div className="relative p-4 w-full max-w-md h-full md:h-auto">
        <div className="relative bg-white rounded-lg shadow">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto"
            onClick={reset}
            aria-label="Close popup"
          >
            <svg
              className="w-5 h-5"
              fill="#c6c7c7"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>

          <div className="p-5 text-center">
            <p className="mb-3 text-2xl font-semibold text-slate-900">
              Delete Clinic
            </p>
            <p className="mb-5 text-md font-semibold text-slate-900">
              Do you really want to delete this clinic?
            </p>

            <div className="grid grid-cols-2 gap-6">
              <button
                type="button"
                onClick={reset}
                className="w-full bg-green-500 text-white font-medium py-3 rounded-lg focus:outline-none"
              >
                No
              </button>
              <button
                type="button"
                onClick={onConfirmDelete}
                className="w-full bg-red-500 text-white font-medium py-3 rounded-lg focus:outline-none"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteClinic;
